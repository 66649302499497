/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "./simple-popup.component";
import * as i5 from "../../../popups/services/popup/popup.service";
var styles_SimplePopupComponent = [];
var RenderType_SimplePopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SimplePopupComponent, data: {} });
export { RenderType_SimplePopupComponent as RenderType_SimplePopupComponent };
export function View_SimplePopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "popup-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["container", 1]], null, 3, "div", [["class", "popup-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((($event.target == i0.ɵnov(_v, 1)) ? _co.close() : null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "popup"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(4, 442368, null, 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SimplePopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-simple-popup", [], [[2, "theme_dark", null]], null, null, View_SimplePopupComponent_0, RenderType_SimplePopupComponent)), i0.ɵdid(1, 245760, null, 0, i4.SimplePopupComponent, [i0.ElementRef, i5.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).dark; _ck(_v, 0, 0, currVal_0); }); }
var SimplePopupComponentNgFactory = i0.ɵccf("app-simple-popup", i4.SimplePopupComponent, View_SimplePopupComponent_Host_0, { data: "data", disableClose: "disableClose", closeWithoutConfirm: "closeWithoutConfirm", closeTitle: "closeTitle", closeDescription: "closeDescription", requestClose: "requestClose", orange: "orange", dark: "dark", theme: "theme" }, { closed: "closed" }, []);
export { SimplePopupComponentNgFactory as SimplePopupComponentNgFactory };
