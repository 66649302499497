import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { CustomizeService } from '@modules/customize';

@Component({
  selector: 'app-sidebar-submit',
  templateUrl: './sidebar-submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSubmitComponent implements OnInit, OnDestroy, OnChanges {
  @Input() saveEnabled = true;
  @Input() saveDisabled = false;
  @Input() saveLoading = false;
  @Input() saveLabel = 'Save';
  @Input() backEnabled = false;
  @Input() backLabel = 'Back';
  @Input() deleteEnabled = false;
  @Input() cancelEnabled = true;
  @Input() cancelDisabled = false;
  @Input() cancelLabel = 'Cancel';
  @Input() errors: string[];
  @Input() hidden = false;
  @Output() cancelClick = new EventEmitter<void>();
  @Output() deleteClick = new EventEmitter<void>();
  @Output() submitClick = new EventEmitter<void>();

  visible: boolean;

  constructor(private customizeService: CustomizeService) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.setContentfulRightBar(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.visible = this.saveEnabled || this.cancelEnabled || this.backEnabled || this.deleteEnabled;
    this.setContentfulRightBar(this.visible && !this.hidden);
  }

  setContentfulRightBar(value: boolean) {
    if (value) {
      this.customizeService.addContentfulRightBarHandler(this);
    } else {
      this.customizeService.removeContentfulRightBarHandler(this);
    }
  }
}
