var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FillSettingsControl } from '@modules/colors-components';
import { BorderSettingsControl, CornersControl, MarginControl } from '@modules/customize';
import { applyBooleanInput$, Input } from '@modules/fields';
import { MenuBlock, MenuBlockLayouts, MenuGeneratorService } from '@modules/menu';
import { MenuPrimary, MenuSecondary } from '@modules/menu-components';
import { FieldInputControl } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
import { CustomizeBarPagesEditMenuItemArray, getMenuItemControlChildren } from '../customize-bar-pages-edit/customize-bar-pages-edit.form';
import { CustomizeBarPagesEditImageControl } from '../customize-bar-pages-edit/image-control';
var MenuBlockControl = /** @class */ (function (_super) {
    __extends(MenuBlockControl, _super);
    function MenuBlockControl(injector, menuGeneratorService, state) {
        var _this = _super.call(this, {
            enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
            enabled_input_enabled: new FormControl(false),
            enabled_input: new FieldInputControl({ name: 'value' }),
            layout: new FormControl(isSet(state.layout) ? state.layout : ''),
            start_items: CustomizeBarPagesEditMenuItemArray.inject(injector, isSet(state.start_items) ? state.start_items : []),
            center_items: CustomizeBarPagesEditMenuItemArray.inject(injector, isSet(state.center_items) ? state.center_items : []),
            end_items: CustomizeBarPagesEditMenuItemArray.inject(injector, isSet(state.end_items) ? state.end_items : []),
            fill_settings: new FillSettingsControl(isSet(state.fill_settings) ? state.fill_settings : {}),
            border_settings: new BorderSettingsControl(isSet(state.border_settings) ? state.border_settings : {}),
            width: new FormControl(isSet(state.width) ? state.width : undefined),
            height: new FormControl(isSet(state.height) ? state.height : undefined),
            border_radius: new CornersControl(isSet(state.border_radius) ? state.border_radius : undefined),
            padding: new MarginControl(isSet(state.padding) ? state.padding : undefined)
        }) || this;
        _this.injector = injector;
        _this.menuGeneratorService = menuGeneratorService;
        return _this;
    }
    MenuBlockControl.inject = function (injector, state) {
        return Injector.create({
            providers: [
                {
                    provide: MenuBlockControl,
                    useFactory: function (menuGeneratorService) {
                        return new MenuBlockControl(injector, menuGeneratorService, state);
                    },
                    deps: [MenuGeneratorService]
                }
            ],
            parent: injector
        }).get(MenuBlockControl);
    };
    MenuBlockControl.prototype.deserialize = function (instance) {
        this.instance = instance;
        this.controls.enabled.patchValue(instance.enabled);
        this.controls.enabled_input_enabled.patchValue(instance.enabledInput && instance.enabledInput.isSet());
        this.controls.enabled_input.patchValue(instance.enabledInput ? instance.enabledInput.serialize() : {});
        this.controls.layout.patchValue(instance.layout);
        this.controls.start_items.deserialize(instance.startItems);
        this.controls.center_items.deserialize(instance.centerItems);
        this.controls.end_items.deserialize(instance.endItems);
        this.controls.fill_settings.deserialize(instance.fillSettings);
        this.controls.border_settings.deserialize(instance.borderSettings);
        this.controls.width.patchValue(isSet(instance.width) ? instance.width : undefined);
        this.controls.height.patchValue(isSet(instance.height) ? instance.height : undefined);
        this.controls.border_radius.deserialize(instance.borderRadius);
        this.controls.padding.deserialize(instance.padding);
        this.markAsPristine();
    };
    MenuBlockControl.prototype.getEnabled$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(controlValue(this.controls.enabled), controlValue(this.controls.enabled_input_enabled), controlValue(this.controls.enabled_input).pipe(map(function () { return _this.controls.enabled_input.serialize(); }))).pipe(switchMap(function (_a) {
            var enabled = _a[0], enabledInputEnabled = _a[1], enabledInput = _a[2];
            if (enabledInputEnabled && enabledInput) {
                return applyBooleanInput$(enabledInput, {
                    context: options.context
                });
            }
            else if (enabledInputEnabled && !enabledInput) {
                return of(true);
            }
            else {
                return of(enabled);
            }
        }));
    };
    MenuBlockControl.prototype.getWidthEnabled$ = function () {
        return controlValue(this.controls.layout).pipe(map(function (value) { return MenuBlockLayouts.isVertical(value); }));
    };
    MenuBlockControl.prototype.getHeightEnabled$ = function () {
        return controlValue(this.controls.layout).pipe(map(function (value) { return MenuBlockLayouts.isHorizontal(value); }));
    };
    MenuBlockControl.prototype.getDefaultWidth$ = function () {
        return controlValue(this.controls.layout).pipe(map(function (value) {
            if (MenuBlockLayouts.isPrimary(value)) {
                return MenuPrimary.defaultWidth();
            }
            else {
                return MenuSecondary.defaultWidth();
            }
        }));
    };
    MenuBlockControl.prototype.getDefaultHeight$ = function () {
        return controlValue(this.controls.layout).pipe(map(function (value) {
            if (MenuBlockLayouts.isPrimary(value)) {
                return MenuPrimary.defaultHeight();
            }
        }));
    };
    MenuBlockControl.prototype.serialize = function () {
        if (!this.instance) {
            this.instance = new MenuBlock();
            this.instance.generateUid();
        }
        var result = this.instance.clone();
        result.enabled = this.controls.enabled.value;
        result.enabledInput =
            this.controls.enabled_input_enabled.value && this.controls.enabled_input.value
                ? new Input().deserialize(this.controls.enabled_input.value)
                : undefined;
        result.layout = this.controls.layout.value;
        result.startItems = this.controls.start_items.serialize();
        result.centerItems = this.controls.center_items.serialize();
        result.endItems = this.controls.end_items.serialize();
        result.fillSettings = this.controls.fill_settings.serialize(false);
        result.borderSettings = this.controls.border_settings.serialize(false);
        result.width = this.controls.width.value;
        result.height = this.controls.height.value;
        result.borderRadius = this.controls.border_radius.serialize();
        result.padding = this.controls.padding.serialize();
        return result;
    };
    MenuBlockControl.prototype.getTotalItems = function () {
        return [this.controls.start_items, this.controls.center_items, this.controls.end_items].reduce(function (acc, item) { return acc + item.controls.length; }, 0);
    };
    MenuBlockControl.prototype.getTotalItems$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.start_items), controlValue(this.controls.center_items), controlValue(this.controls.end_items)).pipe(map(function () { return _this.getTotalItems(); }));
    };
    MenuBlockControl.prototype.getDefaultColor = function () {
        return MenuBlockLayouts.getDefaultColor(this.controls.layout.value);
    };
    MenuBlockControl.prototype.getAllItems = function () {
        return [this.controls.start_items, this.controls.center_items, this.controls.end_items].reduce(function (acc, item) {
            item.controls.forEach(function (control) {
                acc.push.apply(acc, getMenuItemControlChildren(control, true));
            });
            return acc;
        }, []);
    };
    MenuBlockControl.prototype.setLayout = function (layout) {
        this.controls.layout.patchValue(layout);
        this.getAllItems().forEach(function (control) {
            if (control instanceof CustomizeBarPagesEditImageControl) {
                control.cleanValues(layout);
            }
        });
    };
    MenuBlockControl.prototype.applyDefaultState = function () {
        var state = this.menuGeneratorService.getDefaultBlockState();
        if (state.startItems) {
            this.controls.start_items.deserialize(state.startItems);
        }
        if (state.centerItems) {
            this.controls.center_items.deserialize(state.centerItems);
        }
        if (state.endItems) {
            this.controls.end_items.deserialize(state.endItems);
        }
    };
    MenuBlockControl.prototype.isVisible = function () {
        return this.controls.enabled.value && this.controls.layout.value;
    };
    return MenuBlockControl;
}(FormGroup));
export { MenuBlockControl };
