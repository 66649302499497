import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AccordionItem } from '@modules/customize';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

import { TextStyleControl } from '../text-style-edit/text-style.control';

export class AccordionItemControl extends FormGroup {
  instance: AccordionItem;

  controls: {
    title: FieldInputControl;
    title_style: TextStyleControl;
    icon: FormControl;
    opened_input: FieldInputControl;
    visible_input: FieldInputControl;
    tooltip: FormControl;
  };

  constructor(private injector: Injector, value?: AccordionItem) {
    super({
      title: new FieldInputControl(value && value.titleInput ? value.titleInput.serialize() : { name: 'value' }),
      title_style: TextStyleControl.inject(injector, {
        global: 'collapseTitleTextStyle',
        colorAlphaEnabled: true
      }),
      icon: new FormControl(value && isSet(value.icon) ? value.icon : undefined),
      opened_input: new FieldInputControl({ name: 'value' }),
      visible_input: new FieldInputControl(
        value && value.visibleInput ? value.visibleInput.serialize() : { name: 'value' }
      ),
      tooltip: new FormControl(value && isSet(value.tooltip) ? value.tooltip : '')
    });
  }

  static inject(injector: Injector, value?: AccordionItem): AccordionItemControl {
    return new AccordionItemControl(injector, value);
  }

  deserialize(value: AccordionItem) {
    this.instance = value;

    this.controls.title.patchValue(value.titleInput ? value.titleInput.serialize() : {});
    this.controls.title_style.deserialize(value.titleStyle);
    this.controls.icon.patchValue(value.icon);
    this.controls.opened_input.patchValue(value.openedInput ? value.openedInput.serialize() : {});
    this.controls.visible_input.patchValue(value.visibleInput ? value.visibleInput.serialize() : {});
    this.controls.tooltip.patchValue(value.tooltip);
  }

  serialize(): AccordionItem {
    if (!this.instance) {
      this.instance = new AccordionItem();
      this.instance.generateUid();
    }

    const result = this.instance;

    result.titleInput = this.controls.title.value ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    result.titleStyle = this.controls.title_style.serialize();
    result.icon = this.controls.icon.value;

    if (this.controls.opened_input.value) {
      result.openedInput = new Input().deserialize(this.controls.opened_input.value);
    } else {
      result.openedInput = undefined;
    }

    if (this.controls.visible_input.value) {
      result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      result.visibleInput = undefined;
    }

    result.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;

    return result;
  }
}
