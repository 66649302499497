var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import * as moment from 'moment';
import { AdminMode } from '@modules/admin-mode';
import { versionLessThan } from '@modules/api';
import { User } from '@modules/users';
import { ascComparator, initials, isHttps, isHttpsUrl, isProductionLocation, isSet, objectsSortPredicate, stripEnd } from '@shared';
import { Domain } from '../../domain/domain';
import { hasEnvironmentModelPermission, hasEnvironmentPagePermission, hasEnvironmentPermission, hasGroupPermission } from '../utils/permissions';
import { Environment } from './environment';
import { JET_APP_RESOURCE, jetAppResource } from './jet-app.resource';
import { ProjectFeatures } from './project-features';
import { ProjectJob } from './project-job';
import { ProjectPermissions } from './project-permission';
import { ProjectSettings } from './project-settings';
import { ProjectToken } from './project-token';
import { Resource } from './resource';
import { ResourceDeploy } from './resource-deploy';
import { ResourceName } from './resource-name';
import { Subscription } from './subscription';
// export enum TypesProject {
//   company = 'company',
//   personal = 'personal'
// }
export var ProjectType;
(function (ProjectType) {
    ProjectType["InternalTool"] = "internal";
    ProjectType["CustomerPortal"] = "portal";
})(ProjectType || (ProjectType = {}));
export var ProjectDeployment;
(function (ProjectDeployment) {
    ProjectDeployment["Cloud"] = "cloud";
    ProjectDeployment["OnPremise"] = "on_premise";
})(ProjectDeployment || (ProjectDeployment = {}));
var Project = /** @class */ (function () {
    function Project() {
        this.logoFill = false;
        this.isOwner = false;
        this.jobs = [];
        this.tokens = [];
        this.company = false;
        this.environments = [];
        this.resources = [];
        this.trialEnded = false;
        this.features = new ProjectFeatures();
        this.params = {};
    }
    // public website: string;
    // public role: string;
    Project.prototype.deserialize = function (data) {
        this.uniqueName = data['unique_name'];
        this.name = data['name'];
        this.apiBaseUrl = data['api_base_url'];
        this.messagesUrl = data['messages_url'] || this.defaultMessagesUrl;
        this.logoColor = data['color'];
        this.logo = data['logo'];
        this.url = data['url'];
        this.defaultGroup = data['default_group'];
        this.dateAdd = data['date_add'] ? moment(data['date_add']) : undefined;
        this.parent = data['parent'];
        this.demo = data['demo'];
        this.isOwner = data['is_owner'];
        this.company = data['company'];
        // this.type = data['type'];
        // this.website = data['website'];
        // this.role = data['role'];
        this.initials = initials(this.name);
        if (data['owner']) {
            this.owner = new User().deserialize(data['owner']);
        }
        if (data['jobs']) {
            this.jobs = data['jobs']
                .map(function (item) { return new ProjectJob().deserialize(item); })
                .sort(function (lhs, rhs) { return lhs.ordering - rhs.ordering; });
        }
        if (data['tokens']) {
            this.tokens = data['tokens'].map(function (item) { return new ProjectToken().deserialize(item); });
        }
        if (data['default_environment']) {
            this.defaultEnvironment = new Environment().deserialize(data['default_environment']);
        }
        if (data['environments']) {
            this.environments = data['environments'].map(function (item) { return new Environment().deserialize(item); });
        }
        if (data['resources']) {
            this.resources = data['resources'].map(function (item) { return new Resource().deserialize(item); }).filter(function (item) { return item.typeItem; });
        }
        if (data['domain']) {
            if (typeof data['domain'] == 'string') {
                this.domain = new Domain().deserialize({ domain: data['domain'] });
            }
            else if (isPlainObject(data['domain'])) {
                this.domain = new Domain().deserialize(data['domain']);
            }
        }
        if (data['features']) {
            this.features = new ProjectFeatures().deserialize(data['features']);
        }
        if (data['project_settings']) {
            this.projectSettings = data['project_settings'].map(function (item) { return new ProjectSettings().deserialize(item); });
        }
        if (data['subscription']) {
            this.subscription = new Subscription().deserialize(data['subscription']);
        }
        if (data['params']) {
            this.params = data['params'];
            this.signUpLink = this.params['sign_up_link'];
            this.type = this.params['type'];
            this.deployment = this.params['deployment'];
            this.about = this.params['about'];
            this.logoFill = this.params['logo_fill'];
            if (this.params['trial_ended']) {
                this.trialEnded = true;
            }
            if (this.params['logo_fill'] != undefined) {
                this.logoFill = this.params['logo_fill'];
            }
        }
        return this;
    };
    Project.prototype.serialize = function (fields) {
        this.params = {};
        if (this.trialEnded) {
            this.params['trial_ended'] = this.trialEnded;
        }
        if (this.signUpLink) {
            this.params['sign_up_link'] = this.signUpLink;
        }
        if (this.type) {
            this.params['type'] = this.type;
        }
        if (this.deployment) {
            this.params['deployment'] = this.deployment;
        }
        if (this.about) {
            this.params['about'] = this.about;
        }
        if (this.defaultEnvironment) {
            this.params['default_environment'] = this.defaultEnvironment.uniqueName;
        }
        if (this.logoFill) {
            this.params['logo_fill'] = this.logoFill;
        }
        var data = __assign({ name: this.name, unique_name: this.uniqueName, api_base_url: this.apiBaseUrl, messages_url: this.messagesUrl, color: this.logoColor, url: this.url, subdomain: this.subdomain, default_group: this.defaultGroup }, (this.projectSettings && {
            project_settings: this.projectSettings.map(function (item) { return item.serialize(['name', 'value']); })
        }), (this.menuSettings && {
            menu_settings: {
                blocks: this.menuSettings.blocks.map(function (item) { return item.serialize(); })
            }
        }), { params: this.params });
        if (this.logoFile) {
            data['logo'] = this.logoFile;
        }
        else if (!this.logo) {
            data['logo'] = null;
        }
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(Project.prototype, "isHttps", {
        get: function () {
            var apiUrls = this.resources.map(function (item) { return item.params['url']; }).filter(function (item) { return item; });
            return apiUrls.every(function (item) { return isHttpsUrl(item); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "protocol", {
        get: function () {
            return this.isHttps && isProductionLocation() ? 'https' : 'http';
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.linkEnvironment = function (fixedEnvironmentName) {
        var defaultEnvironmentName = this.defaultEnvironment ? this.defaultEnvironment.uniqueName : undefined;
        var environmentName = fixedEnvironmentName || window['project_environment'] || defaultEnvironmentName;
        return this.environments.find(function (item) { return item.uniqueName == environmentName; });
    };
    Project.prototype.link = function (appLink, options) {
        if (appLink === void 0) { appLink = []; }
        if (options === void 0) { options = {}; }
        var environment = this.linkEnvironment(options.environmentName);
        var version = environment ? environment.version : undefined;
        if (version && versionLessThan(version, '2.1.0')) {
            options.mode = AdminMode.App;
        }
        var defaultMode = AdminMode.App;
        var mode = options.mode || window['mode'] || defaultMode;
        var prefix = version ? ["/v" + version, mode] : ["/" + mode];
        if (version && versionLessThan(version, '2.2.6')) {
            return prefix.concat([this.uniqueName], this.homeLink, appLink);
        }
        else {
            return prefix.concat([
                this.uniqueName
            ], (environment ? [environment.uniqueName] : []), this.homeLink, appLink);
        }
    };
    Project.prototype.linkWithProtocol = function (appLink, options) {
        if (appLink === void 0) { appLink = []; }
        if (options === void 0) { options = {}; }
        var link = this.link(appLink, options);
        var protocol = options.allowProtocolChange ? this.protocol : stripEnd(window.location.protocol, ':');
        if (isProductionLocation()) {
            if (this.domain && !this.isCurrentDomain) {
                return {
                    href: protocol + "://" + this.domain.actualDomain + link.join('/')
                };
            }
            else if (options.allowProtocolChange && this.isHttps && !isHttps()) {
                return {
                    href: "https://" + window.location.host + link.join('/')
                };
            }
            else if (options.allowProtocolChange && !this.isHttps && isHttps()) {
                return {
                    href: "http://" + window.location.host + link.join('/')
                };
            }
        }
        var environment = this.linkEnvironment(options.environmentName);
        var version = environment ? environment.version : undefined;
        if (window['version'] != version) {
            return {
                href: protocol + "://" + window.location.host + link.join('/')
            };
        }
        return {
            link: link
        };
    };
    Project.prototype.getHomeLink = function (options) {
        if (options === void 0) { options = {}; }
        if (!this.isCreated) {
            return this.createLink;
        }
        var environment = this.linkEnvironment(options.environmentName);
        if (!environment || !this.hasEnvironmentCustomizationPermission(environment)) {
            options.mode = AdminMode.App;
        }
        return this.link([], options);
    };
    Project.prototype.getHomeLinkWithProtocol = function (options) {
        if (options === void 0) { options = {}; }
        if (!this.isCreated) {
            return { link: this.createLink };
        }
        var environment = this.linkEnvironment(options.environmentName);
        var builderAllowed = environment && this.hasEnvironmentCustomizationPermission(environment);
        if (!builderAllowed) {
            options.mode = AdminMode.App;
        }
        var link = options.builderLink && builderAllowed ? options.builderLink : [];
        return this.linkWithProtocol(link, options);
    };
    Object.defineProperty(Project.prototype, "homeLink", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "createLink", {
        get: function () {
            return ['/projects', 'create', this.uniqueName];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "newPageLink", {
        get: function () {
            return ['new-page'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsBillingLink", {
        get: function () {
            return ['project', 'billing'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsBillingInfoLink", {
        get: function () {
            return ['project', 'billing', 'info'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsBillingPlansLink", {
        get: function () {
            return ['project', 'billing', 'plans'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "subscriptionExpiredLink", {
        get: function () {
            return ['project', 'subscription', 'expired'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsBillingActivitiesLink", {
        get: function () {
            return ['project', 'billing', 'activities'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsBillingDetailsLink", {
        get: function () {
            return ['project', 'billing', 'details'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsBillingHistoryLink", {
        get: function () {
            return ['project', 'billing', 'history'];
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.getSettingsBillingTransactionLink = function (provider, providerId) {
        return ['project', 'billing', 'transactions', provider, providerId];
    };
    Object.defineProperty(Project.prototype, "settingsUsersLink", {
        get: function () {
            return ['project', 'users'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsSSOLink", {
        get: function () {
            return ['project', 'sso'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsSSOCreateLink", {
        get: function () {
            return ['project', 'sso', 'create'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsTasksLink", {
        get: function () {
            return ['project', 'tasks'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsTasksCreateLink", {
        get: function () {
            return ['project', 'tasks', 'create'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsGroupsLink", {
        get: function () {
            return ['project', 'teams'];
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.settingsApiLink = function (tab) {
        var result = ['project', 'api'];
        if (tab) {
            result.push(tab);
        }
        return result;
    };
    Object.defineProperty(Project.prototype, "settingsLink", {
        get: function () {
            return ['project', 'settings'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "onPremiseLink", {
        get: function () {
            return ['project', 'on-premise'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsCollectionsLink", {
        get: function () {
            return ['project', 'models'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsCustomViewsLink", {
        get: function () {
            return ['project', 'custom_views'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsActionsLink", {
        get: function () {
            return ['project', 'actions'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsUserActivitiesLink", {
        get: function () {
            return ['user_activities'];
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.settingsCollaborationLink = function (tab) {
        var result = ['collaboration'];
        if (tab) {
            result.push(tab);
        }
        return result;
    };
    Project.prototype.settingsLayoutLink = function (path) {
        var paths = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            paths[_i - 1] = arguments[_i];
        }
        return ['layout', path].concat(paths);
    };
    Project.prototype.settingsSignUpLink = function (tab) {
        var result = ['signup'];
        if (tab) {
            result.push(tab);
        }
        return result;
    };
    Object.defineProperty(Project.prototype, "jetAppStorageLink", {
        get: function () {
            return ['resources', 'storage'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "logsLink", {
        get: function () {
            return ['resources', 'logs'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "automationsLink", {
        get: function () {
            return ['automations'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "automationRunsLink", {
        get: function () {
            return ['automations', 'runs'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsCollaborationMessagesLink", {
        get: function () {
            return ['collaboration', 'messages'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsCollaborationTasksLink", {
        get: function () {
            return ['collaboration', 'tasks'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "settingsResourcesLink", {
        get: function () {
            return ['resources'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "templatesLink", {
        get: function () {
            return ['templates'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "createTemplateLink", {
        get: function () {
            return ['create_template'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "setupGuideLink", {
        get: function () {
            return ['guide'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "createPageLink", {
        get: function () {
            return ['new_page'];
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.createPageTypeLink = function (type) {
        return ['new_page', type];
    };
    Project.prototype.hasEnvironmentModelPermission = function (environment, modelId, action) {
        return hasEnvironmentModelPermission(environment, modelId, action);
    };
    Project.prototype.hasEnvironmentPagePermission = function (environment, pageUid, action) {
        return hasEnvironmentPagePermission(environment, pageUid, action);
    };
    Project.prototype.hasProjectSettingsPermission = function () {
        return this.environments.some(function (item) { return hasEnvironmentPermission(item, ProjectPermissions.ProjectSettings); });
    };
    Project.prototype.hasProjectBillingPermission = function () {
        return this.environments.some(function (item) { return hasEnvironmentPermission(item, ProjectPermissions.ProjectBilling); });
    };
    Project.prototype.hasGroupCustomizationPermission = function (group) {
        return hasGroupPermission(group, ProjectPermissions.ProjectCustomization);
    };
    Project.prototype.hasEnvironmentPermissions = function (environment) {
        return !!environment.group;
    };
    Project.prototype.hasEnvironmentSettingsPermission = function (environment) {
        return hasEnvironmentPermission(environment, ProjectPermissions.ProjectAccess);
    };
    Project.prototype.hasEnvironmentAccessPermission = function (environment) {
        return hasEnvironmentPermission(environment, ProjectPermissions.ProjectAccess);
    };
    Project.prototype.hasEnvironmentCustomizationPermission = function (environment) {
        return hasEnvironmentPermission(environment, ProjectPermissions.ProjectCustomization);
    };
    Project.prototype.hasProjectPermissions = function () {
        return (this.hasProjectSettingsPermission() ||
            this.hasProjectBillingPermission() ||
            this.environments.some(function (item) { return hasEnvironmentPermission(item, ProjectPermissions.ProjectAccess); }));
    };
    Object.defineProperty(Project.prototype, "defaultMessagesUrl", {
        get: function () {
            return this.apiBaseUrl + "messages/";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "isCreated", {
        get: function () {
            // return this.tokens.find(item => item.activated) != undefined;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Project.prototype, "isCurrentDomain", {
        get: function () {
            return this.domain && this.domain.actualDomain == window.location.hostname;
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.findToken = function (token) {
        return this.tokens.find(function (item) { return item.token.replace('-', '').toLowerCase() == token.replace('-', '').toLowerCase(); });
    };
    Object.defineProperty(Project.prototype, "dateCreatedKey", {
        get: function () {
            return ['project_created', this.uniqueName].join('_');
        },
        enumerable: true,
        configurable: true
    });
    Project.prototype.getStorage = function (environmentName, resourceName, storageName, options) {
        if (options === void 0) { options = {}; }
        var storages = this.getStorages(environmentName);
        var storage = storages.find(function (item) { return item.resource.uniqueName == resourceName && item.storage.uniqueName == storageName; });
        if (storage) {
            return storage;
        }
        if (options.defaultFirst) {
            if (options.contextResource && options.contextResource.typeItem.name == ResourceName.Supabase) {
                var supabaseStorage = this.getStorages(environmentName).find(function (item) {
                    return item.resource.typeItem.name == ResourceName.SupabaseStorage;
                });
                if (supabaseStorage) {
                    return supabaseStorage;
                }
            }
            return storages[0];
        }
    };
    Project.prototype.getStorages = function (environmentName) {
        return this.getEnvironmentResources(environmentName)
            .filter(function (item) {
            return !(item.typeItem &&
                item.typeItem.name == ResourceName.PostgreSQL &&
                item.params['deploy'] == ResourceDeploy.Direct);
        })
            .reduce(function (acc, resource) {
            acc.push.apply(acc, resource.storages.map(function (storage) {
                return {
                    resource: resource,
                    storage: storage
                };
            }));
            return acc;
        }, [])
            .sort(function (lhs, rhs) {
            var isJetApp = function (item) {
                return item.resource.uniqueName == JET_APP_RESOURCE;
            };
            var isJetAppComparator = ascComparator(isJetApp(lhs), isJetApp(rhs));
            if (isJetAppComparator !== 0) {
                return isJetAppComparator * -1;
            }
            var getName = function (item) {
                return [String(item.resource.name).toLowerCase(), String(item.storage.name).toLowerCase()].join(' - ');
            };
            return ascComparator(getName(lhs), getName(rhs));
        });
    };
    Project.prototype.getEnvironmentResources = function (environmentName, options) {
        if (options === void 0) { options = {}; }
        if (!isSet(environmentName)) {
            return [];
        }
        return this.resources
            .filter(function (item) { return item.environment == environmentName || item.demo; })
            .filter(function (item) { return options.includeDeleted || !item.deleted; }).concat([
            jetAppResource
        ]);
    };
    Project.prototype.getEnvironmentResource = function (environmentName, resourceName, options) {
        if (options === void 0) { options = {}; }
        return this.getEnvironmentResources(environmentName, options).find(function (item) { return item.uniqueName == resourceName; });
    };
    Project.prototype.getEnvironmentsSorted = function () {
        var _this = this;
        return this.environments.sort(objectsSortPredicate(function (lhs, rhs) {
            var defaultEnvironmentName = _this.defaultEnvironment ? _this.defaultEnvironment.uniqueName : undefined;
            if (lhs.uniqueName == defaultEnvironmentName && rhs.uniqueName != defaultEnvironmentName) {
                return -1;
            }
            else if (lhs.uniqueName != defaultEnvironmentName && rhs.uniqueName == defaultEnvironmentName) {
                return 1;
            }
            else {
                return 0;
            }
        }, 'name'));
    };
    Project.prototype.isSubscriptionEnded = function () {
        return this.trialEnded || !this.subscription || !this.subscription.isActive;
    };
    Project.prototype.isSubscriptionPastDue = function () {
        return (this.subscription &&
            this.subscription.isActive &&
            this.subscription.dateEnd &&
            this.subscription.dateEnd.clone().add(5, 'minutes').isBefore(moment()));
    };
    Project.prototype.isWhiteLabel = function () {
        return this.domain && this.domain.whiteLabel;
    };
    Project.prototype.isDomain = function (domain) {
        return this.domain ? this.domain.actualDomain == domain.actualDomain : false;
    };
    return Project;
}());
export { Project };
