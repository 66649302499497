var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { applyElementWrapperStyles, getElementWrapperStyles } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { ElementWrapperStylesControl } from '../styles-element-wrapper-edit/element-wrapper-styles.control';
var CustomizeBarCardEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarCardEditForm, _super);
    function CustomizeBarCardEditForm() {
        return _super.call(this, {
            title: new FormControl(''),
            visible_input: new FieldInputControl({ name: 'value' }),
            load_invisible: new FormControl(false),
            element_styles: new ElementWrapperStylesControl()
        }) || this;
    }
    CustomizeBarCardEditForm.prototype.init = function (element) {
        this.element = element;
        this.controls.title.patchValue(element.name ? element.name : 'Container');
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});
        this.controls.load_invisible.patchValue(element.loadInvisible);
        var elementStyles = getElementWrapperStyles(element);
        this.controls.element_styles.deserialize(elementStyles);
    };
    CustomizeBarCardEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.title.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.loadInvisible = this.controls.load_invisible.value;
        var elementStyles = this.controls.element_styles.serialize();
        applyElementWrapperStyles(instance, elementStyles);
        return instance;
    };
    return CustomizeBarCardEditForm;
}(FormGroup));
export { CustomizeBarCardEditForm };
