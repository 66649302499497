import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { localize } from '@common/localize';
import {
  applyDateRangeElementStyles,
  DateRangeElementItem,
  getDateRangeElementStyles,
  MarginControl
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import {
  DateFormat,
  defaultDateFormat,
  defaultTimeFormat,
  Input,
  Input as FieldInput,
  momentDateFormats,
  momentTimeFormats,
  OutputFormat,
  TimeFormat
} from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid, controlValue, isSet } from '@shared';

import { DateRangeElementStylesControl } from '../styles-date-range-element-edit/date-range-element-styles.control';

export function validateActions(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const parent = control.parent as CustomizeBarDateRangeEditForm;

    if (!parent) {
      return of(null);
    }

    if (!control.value || !control.value.length) {
      return of(null);
    }

    return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
      map(result => {
        if (result.some(configured => !configured)) {
          return { required: true };
        }
      })
    );
  };
}

@Injectable()
export class CustomizeBarDateRangeEditForm extends FormGroup implements OnDestroy {
  element: DateRangeElementItem;

  controls: {
    name: FormControl;
    verbose_name: FormControl;
    label_additional: FormControl;
    from: FieldInputControl;
    to: FieldInputControl;
    time: FormControl;
    date_format: FormControl;
    date_format_custom: FormControl;
    time_format: FormControl;
    time_format_custom: FormControl;
    required: FormControl;
    from_placeholder: FormControl;
    to_placeholder: FormControl;
    reset_enabled: FormControl;
    disable_input: FieldInputControl;
    tooltip: FormControl;
    visible_input: FieldInputControl;
    on_change_actions: FormControl;

    element_styles: DateRangeElementStylesControl;
  };

  outputFormat = [
    { value: OutputFormat.ISO, name: 'ISO 8601' },
    { value: OutputFormat.ISODate, name: 'ISO 8601 date only' },
    { value: OutputFormat.Timestamp, name: 'Unix Timestamp' },
    { value: OutputFormat.TimestampMillisecond, name: 'Unix Millisecond Timestamp' },
    { value: OutputFormat.SerialNumber, name: 'Google Sheets' },
    { value: OutputFormat.SerialNumberDate, name: 'Google Sheets date only' },
    { value: OutputFormat.String, name: 'Formatted string' }
  ];

  dateFormatOptions = [
    { value: DateFormat.Local, name: `Local (${moment().format(momentDateFormats[DateFormat.Local])})` },
    { value: DateFormat.Friendly, name: `Friendly (${moment().format(momentDateFormats[DateFormat.Friendly])})` },
    { value: DateFormat.US, name: `US (${moment().format(momentDateFormats[DateFormat.US])})` },
    { value: DateFormat.European, name: `European (${moment().format(momentDateFormats[DateFormat.European])})` },
    { value: DateFormat.ISO, name: `ISO (${moment().format(momentDateFormats[DateFormat.ISO])})` },
    { value: DateFormat.Custom, name: 'Custom' }
  ];

  dateFormatEditOptions = this.dateFormatOptions.filter(item => item.value !== DateFormat.Friendly);

  timeFormatOptions = [
    {
      value: TimeFormat.HourMinute24,
      name: `24 hour (${moment().format(momentTimeFormats[TimeFormat.HourMinute24])})`
    },
    {
      value: TimeFormat.HourMinuteSecond24,
      name: `24 hour with seconds (${moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond24])})`
    },
    {
      value: TimeFormat.HourMinute12,
      name: `12 hour (${moment().format(momentTimeFormats[TimeFormat.HourMinute12])})`
    },
    {
      value: TimeFormat.HourMinuteSecond12,
      name: `12 hour with seconds (${moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond12])})`
    },
    {
      value: TimeFormat.Custom,
      name: 'Custom'
    }
  ];

  constructor(public elementConfigurationService: ElementConfigurationService, private injector: Injector) {
    super({
      name: new FormControl(''),
      verbose_name: new FormControl(''),
      label_additional: new FormControl(''),
      from: new FieldInputControl({ name: 'value' }),
      to: new FieldInputControl({ name: 'value' }),
      output_format: new FormControl(OutputFormat.ISO),
      time: new FormControl(true),
      date_format: new FormControl(defaultDateFormat),
      date_format_custom: new FormControl(momentDateFormats[DateFormat.US]),
      time_format: new FormControl(defaultTimeFormat),
      time_format_custom: new FormControl(momentTimeFormats[TimeFormat.HourMinuteSecond24]),
      required: new FormControl(false),
      from_placeholder: new FormControl(''),
      to_placeholder: new FormControl(''),
      reset_enabled: new FormControl(false),
      disable_input: new FieldInputControl({ name: 'value' }),
      tooltip: new FormControl(''),
      visible_input: new FieldInputControl({ name: 'value' }),
      margin: new MarginControl(),
      on_change_actions: new FormControl([], undefined, validateActions()),

      element_styles: new DateRangeElementStylesControl(injector)
    });
  }

  ngOnDestroy(): void {}

  init(element: DateRangeElementItem, firstInit = false) {
    this.element = element;

    const value = {
      name: element.name ? element.name : 'Date Range',
      verbose_name: element.verboseName,
      label_additional: element.labelAdditional,
      from: element.from ? element.from.serialize() : {},
      to: element.to ? element.to.serialize() : {},
      time: element.time,
      ...(isSet(element.dateFormat && momentDateFormats[element.dateFormat])
        ? { date_format: element.dateFormat }
        : { date_format: DateFormat.Custom, date_format_custom: element.dateFormat }),
      ...(isSet(element.timeFormat && momentTimeFormats[element.timeFormat])
        ? { time_format: element.timeFormat }
        : { time_format: DateFormat.Custom, time_format_custom: element.timeFormat }),
      required: element.required,
      reset_enabled: element.resetEnabled,
      from_placeholder: element.fromPlaceholder,
      to_placeholder: element.toPlaceholder,
      disable_input: element.disableInput ? element.disableInput.serialize() : {},
      tooltip: element.tooltip,
      visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
      on_change_actions: element.onChangeActions
    };

    this.patchValue(value, { emitEvent: false });

    const elementStyles = getDateRangeElementStyles(element);
    this.controls.element_styles.deserialize(elementStyles);

    if (!firstInit) {
      this.markAsDirty();
    }

    controlValue<boolean>(this.controls.required)
      .pipe(untilDestroyed(this))
      .subscribe(required => {
        const optionalLabel = `(${localize('optional')})`;

        if (!required && !isSet(this.controls.label_additional.value)) {
          this.controls.label_additional.patchValue(optionalLabel);
        } else if (required && this.controls.label_additional.value == optionalLabel) {
          this.controls.label_additional.patchValue('');
        }
      });
  }

  isConfigured(instance: DateRangeElementItem): boolean {
    return this.elementConfigurationService.isDateRangeConfigured(instance);
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    if (!controls.length) {
      return of(true);
    }

    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item))
      // debounceTime(60) TODO: Too long wait with debounceTime
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.on_change_actions]);
  }

  submit(): DateRangeElementItem {
    const instance = cloneDeep(this.element) as DateRangeElementItem;

    instance.name = this.controls.name.value;
    instance.verboseName = this.controls.verbose_name.value;
    instance.labelAdditional = this.controls.label_additional.value;
    instance.from = this.controls.from.value ? new FieldInput().deserialize(this.controls.from.value) : undefined;
    instance.to = this.controls.to.value ? new FieldInput().deserialize(this.controls.to.value) : undefined;
    instance.time = this.controls.time.value;
    instance.dateFormat =
      this.controls.date_format.value == DateFormat.Custom && isSet(this.controls.date_format_custom.value)
        ? this.controls.date_format_custom.value
        : this.controls.date_format.value;
    instance.timeFormat =
      this.controls.time_format.value == DateFormat.Custom && isSet(this.controls.time_format_custom.value)
        ? this.controls.time_format_custom.value
        : this.controls.time_format.value;
    instance.required = this.controls.required.value;
    instance.fromPlaceholder = this.controls.from_placeholder.value;
    instance.toPlaceholder = this.controls.to_placeholder.value;
    instance.resetEnabled = this.controls.reset_enabled.value;
    instance.disableInput = this.controls.disable_input.value
      ? new FieldInput().deserialize(this.controls.disable_input.value)
      : undefined;
    instance.tooltip = this.controls.tooltip.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.onChangeActions = this.controls.on_change_actions.value;

    const elementStyles = this.controls.element_styles.serialize();
    applyDateRangeElementStyles(instance, elementStyles);

    return instance;
  }
}
