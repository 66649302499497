import {
  ChangeDetectorRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import clone from 'lodash/clone';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { delayWhen, first, switchMap } from 'rxjs/operators';

import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { UniversalAnalyticsService } from '@modules/analytics';
import {
  LinkButton,
  ListLayoutSettings,
  ListViewSettings,
  ViewContext,
  ViewContextElement,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import {
  AggregateDisplayField,
  DisplayField,
  DisplayFieldType,
  FieldType,
  Input as FieldInput,
  LookupDisplayField,
  OptionsType,
  ParameterField
} from '@modules/fields';
import { FilterItem2, Segment, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import { ListItem, listItemEquals } from '@modules/list';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Model, ModelDescription, NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { RoutingService } from '@modules/routing';
import { ascComparator } from '@shared';

export interface ListState<T extends ListLayoutSettings = ListLayoutSettings> {
  settings?: T;
  dataSource?: ListModelDescriptionDataSource;
  dataSourceStaticData?: Object[];
  dataSourceParams?: Object;
  userParams?: Object;
  filters?: FilterItem2[];
  search?: string;
  sort?: Sort[];
  groupCollapse?: boolean;
  resource?: Resource;
  modelDescription?: ModelDescription;
  inputsLoading?: boolean;
  inputsNotSet?: boolean;
}

export function serializeDataSourceColumns(columns: DisplayField[]): Object[] {
  return columns
    .filter(item =>
      [DisplayFieldType.Base, DisplayFieldType.Computed, DisplayFieldType.Lookup, DisplayFieldType.Aggregate].includes(
        item.type
      )
    )
    .map(item => {
      return {
        name: item.name,
        ...(item.field == FieldType.RelatedModel && item.params
          ? {
              custom_primary_key: item.params['custom_primary_key'],
              custom_display_field: item.params['custom_display_field'],
              custom_display_field_input: item.params['custom_display_field_input']
            }
          : {}),
        ...([FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(item.field) &&
        item.params &&
        item.params['options_type'] == OptionsType.Query
          ? {
              value_field: item.params['value_field'],
              label_field: item.params['label_field'],
              label_field_input: item.params['label_field_input']
            }
          : {}),
        ...(item instanceof LookupDisplayField
          ? {
              path: item.path
            }
          : {}),
        ...(item instanceof AggregateDisplayField
          ? {
              path: item.path,
              func: item.func,
              column: item.column
            }
          : {})
      };
    })
    .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
}

export abstract class ListLayoutComponent<
  T extends ListLayoutSettings = ListLayoutSettings,
  S extends ListState<T> = ListState<T>
> implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() params: Object;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() hideParams: string[] = [];
  @Input() resource: Resource;
  @Input() pageSettings: ListViewSettings;
  @Input() settings: T;
  @Input() scrollable = false;
  @Input() moreLink: LinkButton;
  @Input() focus = false;
  @Input() createSegmentAllowed = false;
  @Input() layoutIndex: number;
  @Input() accentColor: string;
  @Input() theme = false;
  @Input() viewId: string;
  @Output() paramsChanged = new EventEmitter<Object>();
  @Output() settingsChanged = new EventEmitter<T>();
  @Output() layoutCustomize = new EventEmitter<number>();
  @Output() layoutAdd = new EventEmitter<void>();
  @Output() pageSettingsChanged = new EventEmitter<ListViewSettings>();
  @Output() segmentCreated = new EventEmitter<Segment>();

  firstVisible$ = new BehaviorSubject<boolean>(false);
  settings$ = new BehaviorSubject<T>(undefined);
  params$ = new BehaviorSubject<Object>(undefined);
  filters$ = new BehaviorSubject<FilterItem2[]>([]);
  search$ = new BehaviorSubject<string>(undefined);
  sort$ = new BehaviorSubject<Sort[]>([]);
  listState: S = {} as S;
  parameters: ParameterField[] = [];
  inputs: FieldInput[] = [];
  layout: ListLayoutType;

  constructor(
    protected injector: Injector,
    protected cd: ChangeDetectorRef,
    protected customizeBarContext: CustomizeBarContext,
    protected customizeBarService: CustomizeBarService,
    protected analyticsService: UniversalAnalyticsService,
    protected viewSettingsService: ViewSettingsService,
    protected viewSettingsStore: ViewSettingsStore,
    protected menuSettingsStore: MenuSettingsStore,
    protected modelDescriptionStore: ModelDescriptionStore,
    protected inputService: InputService,
    protected routing: RoutingService,
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected popupService: PopupService,
    protected popoverService: PopoverService
  ) {}

  ngOnInit(): void {
    this.trackChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings$.next(this.settings);
    }

    if (changes['params']) {
      this.params$.next(this.params);
    }
  }

  ngOnDestroy(): void {}

  abstract getListState(
    settings: T,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<S>;

  trackChanges() {
    this.firstVisible$
      .pipe(
        first(value => value),
        switchMap(() => combineLatest(this.settings$, this.params$, this.filters$, this.search$, this.sort$)),
        switchMap(([settings, params, filters, search, sort]) =>
          this.getListState(settings, params, filters, search, sort)
        ),
        untilDestroyed(this)
      )
      .subscribe(state => {
        const initialState = !keys(this.listState).length;

        this.onStateUpdated(state);
        this.listState = state;

        // TODO: Workaround for safari not triggering cd on firstVisible
        if (initialState) {
          this.cd.detectChanges();
        }
      });
  }

  abstract onStateUpdated(state: S);

  abstract fetch(state: S);

  getParameters(state: S): ParameterField[] {
    if (!state.dataSource || !state.dataSource.type) {
      return [];
    }

    const resource = this.currentEnvironmentStore.resources.find(
      item => item.uniqueName == state.dataSource.queryResource
    );
    return [
      ...state.dataSource.queryParameters,
      ...this.inputService.parametersFromModelGet(
        resource,
        state.dataSource.query,
        state.dataSource.columns,
        state.dataSource.type
      )
    ];
  }

  getInputs(state: S): FieldInput[] {
    if (!state.dataSource || !state.dataSource.type) {
      return [];
    }

    const resource = this.currentEnvironmentStore.resources.find(
      item => item.uniqueName == state.dataSource.queryResource
    );
    return [
      ...state.dataSource.queryInputs,
      ...this.inputService.inputsFromModelGet(
        resource,
        state.dataSource.query,
        state.dataSource.columns,
        state.dataSource.type
      )
    ];
  }

  getQueryModelDescription(dataSource: ListModelDescriptionDataSource) {
    if (
      !dataSource ||
      !dataSource.query ||
      dataSource.query.queryType != QueryType.Simple ||
      !dataSource.query.simpleQuery
    ) {
      return of(undefined);
    }

    const modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  reloadData() {
    this.fetch(this.listState);
  }

  resetFilters() {
    this.filters$.next([]);
    this.search$.next(undefined);
  }

  setPage(page: number): boolean {
    const currentPage = parseInt(this.params[PAGE_PARAM], 10) || 1;
    if (currentPage == page) {
      return false;
    }

    const params = clone(this.params);

    params[PAGE_PARAM] = page;
    delete params[NEXT_PAGE_SCROLL_PARAM];

    this.paramsChanged.emit(params);

    return true;
  }

  onPageSelected(page: number) {
    this.setPage(page);
  }

  onFiltersUpdated(filters: FilterItem2[]) {
    this.filters$.next(filters);
  }

  onSearchUpdated(search: string) {
    this.search$.next(search);
  }

  onSortUpdated(sort: Sort[]) {
    this.sort$.next(sort);
  }

  customizeModelDescription() {
    if (!this.listState.modelDescription) {
      return;
    }

    // this.customizeBarService
    //   .customizeModelDescription(this.customizeBarContext, this.listState.modelDescription)
    //   .pipe(untilDestroyed(this))
    //   .subscribe(e => {});
  }

  customizeViewSettings() {
    this.customizeBarService
      .customizePage({ context: this.customizeBarContext, viewSettings: this.pageSettings, viewContext: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ListViewSettings;

          this.pageSettingsChanged.emit(instance);
          this.cd.markForCheck();
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          this.viewSettingsService
            .delete(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              this.pageSettings
            )
            .pipe(
              delayWhen(() => this.viewSettingsStore.getFirst(true)),
              delayWhen(() => this.menuSettingsStore.getFirst(true)),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.routing.navigateApp(this.currentProjectStore.instance.homeLink);
            });
        }
      });
  }

  itemEquals(lhs: ListItem, rhs: ListItem) {
    return listItemEquals(lhs, rhs);
  }

  abstract getAnyModel(): Model;
}
