var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isArray from 'lodash/isArray';
import pickBy from 'lodash/pickBy';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { FieldOutput, Input, InputValueType, ParameterField } from '@modules/fields';
import { PER_PAGE_PARAM } from '@modules/models';
import { ActionQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ascComparator, generateAlphanumeric, isSet, sortUsingAfterItem, splitmax } from '@shared';
// TODO: Refactor import
import { BorderSettings } from '../../customize/data/border-settings';
import { FillSettings } from '../../customize/data/fill-settings';
import { IconSettings } from '../../customize/data/icon-settings';
import { Shadow } from '../../customize/data/shadow';
import { TextStyle } from '../../customize/data/text-style';
import { Workflow } from '../../workflow/data/workflow';
import { ActionType } from './action-type';
import { Confirmation } from './confirmation';
export function sortActionDescriptions(items) {
    if (items.length <= 1) {
        return items;
    }
    return sortUsingAfterItem({
        items: items,
        getAfterItem: function (item) { return item.orderAfter; },
        getItemId: function (item) { return item.name; },
        defaultSort: function (lhs, rhs) {
            return ascComparator(lhs.verboseName.toLowerCase(), rhs.verboseName.toLowerCase());
        }
    });
}
var ActionItem = /** @class */ (function () {
    function ActionItem() {
        this.inputs = [];
        this.protected = false;
        this.onSuccessNotification = false;
        this.onSuccessActions = [];
        this.onErrorNotification = false;
        this.onErrorActions = [];
    }
    ActionItem.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.name = data['name'];
        this.sharedActionDescription = data['shared_action_description'];
        if (data['verbose_name_input']) {
            this.verboseNameInput = new Input().deserialize(data['verbose_name_input']);
        }
        else if (data['verbose_name']) {
            // Backward compatibility
            this.verboseNameInput = new Input().deserializeFromStatic('value', data['verbose_name']);
        }
        if (data['action_description']) {
            this.actionDescription = new ActionDescription().deserialize(data['action_description']);
        }
        if (data['inputs']) {
            this.inputs = data['inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (this.actionDescription &&
            this.actionDescription.type === ActionType.ExternalLink &&
            !this.inputs.find(function (item) { return item.name === 'new_tab'; })) {
            var input = new Input();
            input.name = 'new_tab';
            input.valueType = InputValueType.StaticValue;
            input.staticValue = '1';
            this.inputs.push(input);
        }
        // Backward compatibility
        if (data['action_description'] &&
            data['action_description']['params'] &&
            data['action_description']['params']['export_action'] &&
            data['action_description']['params']['export_action']['model'] &&
            this.actionDescription &&
            this.actionDescription.type === ActionType.Export &&
            this.actionDescription.exportAction &&
            this.actionDescription.exportAction.dataSource &&
            this.actionDescription.exportAction.dataSource.type == DataSourceType.Query) {
            this.actionDescription.exportAction.dataSource.queryInputs = this.inputs.filter(function (item) { return ![PER_PAGE_PARAM].includes(item.name); });
            this.inputs = this.inputs.filter(function (item) { return [PER_PAGE_PARAM].includes(item.name); });
        }
        if (data['protected'] != undefined) {
            this.protected = data['protected'];
        }
        if (data['approve'] != undefined) {
            this.approve = new Approve().deserialize(data['approve']);
        }
        if (data['confirmation'] != undefined) {
            this.confirmation = new Confirmation().deserialize(data['confirmation']);
        }
        if (data['on_success_actions']) {
            this.onSuccessActions = data['on_success_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        if (data['on_error_actions']) {
            this.onErrorActions = data['on_error_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        var isQueryType = isSet(this.sharedActionDescription) ||
            (this.actionDescription && this.actionDescription.type == ActionType.Query);
        if (isSet(data['on_success_notification'])) {
            this.onSuccessNotification = data['on_success_notification'];
        }
        else {
            // Backward compatibility
            var notificationActionExists = !this.onSuccessActions.find(function (item) { return item.actionDescription && item.actionDescription.type == ActionType.ShowNotification; });
            this.onSuccessNotification = isQueryType && notificationActionExists;
        }
        if (isSet(data['on_success_notification'])) {
            this.onErrorNotification = data['on_error_notification'];
        }
        else {
            // Backward compatibility
            var notificationActionExists = !this.onErrorActions.find(function (item) { return item.actionDescription && item.actionDescription.type == ActionType.ShowNotification; });
            this.onErrorNotification = isQueryType && notificationActionExists;
        }
        return this;
    };
    ActionItem.prototype.serialize = function (fields) {
        var data = {
            uid: this.uid,
            name: this.name,
            verbose_name_input: this.verboseNameInput ? this.verboseNameInput.serialize() : null,
            shared_action_description: this.sharedActionDescription,
            action_description: this.actionDescription ? this.actionDescription.serialize() : undefined,
            inputs: this.inputs.map(function (item) { return item.serialize(); }),
            protected: this.protected,
            approve: this.approve ? this.approve.serialize() : undefined,
            confirmation: this.confirmation ? this.confirmation.serialize() : undefined,
            on_success_notification: this.onSuccessNotification,
            on_success_actions: this.onSuccessActions.map(function (item) { return item.serialize(); }),
            on_error_notification: this.onErrorNotification,
            on_error_actions: this.onErrorActions.map(function (item) { return item.serialize(); })
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ActionItem.prototype, "queryLink", {
        get: function () {
            return ['execute_action'];
        },
        enumerable: true,
        configurable: true
    });
    ActionItem.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(8, { letterFirst: true });
    };
    return ActionItem;
}());
export { ActionItem };
var Approve = /** @class */ (function () {
    function Approve() {
        this.taskInputs = [];
        this.onTaskCreateActions = [];
        this.onRejectActions = [];
    }
    Approve.prototype.deserialize = function (data) {
        this.taskQueue = data['task_queue'];
        this.taskName = new Input().deserialize(data['task_name']);
        this.taskAssignee = data['task_assignee'];
        this.taskCreateStatus = data['task_create_status'];
        this.taskApproveStatus = data['task_approve_status'];
        this.taskRejectStatus = data['task_reject_status'];
        if (data['task_inputs']) {
            this.taskInputs = data['task_inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (data['on_task_create_actions']) {
            this.onTaskCreateActions = data['on_task_create_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        if (data['on_reject_actions']) {
            this.onRejectActions = data['on_reject_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    Approve.prototype.serialize = function (fields) {
        var data = {
            task_queue: this.taskQueue,
            task_name: this.taskName ? this.taskName.serialize() : undefined,
            task_inputs: this.taskInputs.map(function (item) { return item.serialize(); }),
            task_assignee: this.taskAssignee,
            task_create_status: this.taskCreateStatus,
            task_approve_status: this.taskApproveStatus,
            task_reject_status: this.taskRejectStatus,
            on_task_create_actions: this.onTaskCreateActions.map(function (item) { return item.serialize(); }),
            on_reject_actions: this.onRejectActions.map(function (item) { return item.serialize(); })
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return Approve;
}());
export { Approve };
export var TintStyle;
(function (TintStyle) {
    TintStyle["Default"] = "default";
    TintStyle["Primary"] = "primary";
    TintStyle["Transparent"] = "transparent";
})(TintStyle || (TintStyle = {}));
var ViewSettingsAction = /** @class */ (function (_super) {
    __extends(ViewSettingsAction, _super);
    function ViewSettingsAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.visible = true;
        _this.pinned = false;
        _this.style = TintStyle.Primary;
        return _this;
    }
    ViewSettingsAction.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.icon = data['icon'];
        this.order = data['order'];
        if (data['style'] != undefined) {
            this.style = data['style'];
        }
        if (this.style == TintStyle.Default && data['color_text']) {
            // Backward compatibility
            this.tint = data['color_text'];
        }
        else if (this.style == TintStyle.Primary && data['color']) {
            // Backward compatibility
            this.tint = data['color'];
        }
        else if (this.style == TintStyle.Transparent && data['color_text']) {
            // Backward compatibility
            this.tint = data['color_text'];
        }
        else {
            this.tint = data['tint'];
        }
        if (data['visible'] != undefined) {
            this.visible = data['visible'];
        }
        if (data['pinned'] != undefined) {
            this.pinned = data['pinned'];
        }
        if (data['disabled_input']) {
            this.disabledInput = new Input().deserialize(data['disabled_input']);
        }
        if (data['visible_input']) {
            this.visibleInput = new Input().deserialize(data['visible_input']);
        }
        if (data['text_style']) {
            this.textStyle = new TextStyle().deserialize(data['text_style']);
        }
        else {
            this.textStyle = undefined;
        }
        if (data['icon_settings']) {
            this.iconSettings = new IconSettings().deserialize(data['icon_settings']);
        }
        else {
            this.iconSettings = undefined;
        }
        if (data['fill_settings']) {
            this.fillSettings = new FillSettings().deserialize(data['fill_settings']);
        }
        else {
            this.fillSettings = undefined;
        }
        if (data['border_settings']) {
            this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
        }
        else {
            this.borderSettings = undefined;
        }
        if (data['border_radius']) {
            this.borderRadius = data['border_radius'];
        }
        else {
            this.borderRadius = undefined;
        }
        if (data['shadow']) {
            this.shadow = new Shadow().deserialize(data['shadow']);
        }
        else {
            this.shadow = undefined;
        }
        this.padding = data['padding'];
        if (data['hover_text_style']) {
            this.hoverTextStyle = new TextStyle().deserialize(data['hover_text_style']);
        }
        else {
            this.hoverTextStyle = undefined;
        }
        if (data['hover_icon_settings']) {
            this.hoverIconSettings = new IconSettings().deserialize(data['hover_icon_settings']);
        }
        else {
            this.hoverIconSettings = undefined;
        }
        if (data['hover_fill_settings']) {
            this.hoverFillSettings = new FillSettings().deserialize(data['hover_fill_settings']);
        }
        else {
            this.hoverFillSettings = undefined;
        }
        if (data['hover_border_settings']) {
            this.hoverBorderSettings = new BorderSettings().deserialize(data['hover_border_settings']);
        }
        else {
            this.hoverBorderSettings = undefined;
        }
        if (data['hover_shadow']) {
            this.hoverShadow = new Shadow().deserialize(data['hover_shadow']);
        }
        else {
            this.hoverShadow = undefined;
        }
        if (data['active_text_style']) {
            this.activeTextStyle = new TextStyle().deserialize(data['active_text_style']);
        }
        else {
            this.activeTextStyle = undefined;
        }
        if (data['active_icon_settings']) {
            this.activeIconSettings = new IconSettings().deserialize(data['active_icon_settings']);
        }
        else {
            this.activeIconSettings = undefined;
        }
        if (data['active_fill_settings']) {
            this.activeFillSettings = new FillSettings().deserialize(data['active_fill_settings']);
        }
        else {
            this.activeFillSettings = undefined;
        }
        if (data['active_border_settings']) {
            this.activeBorderSettings = new BorderSettings().deserialize(data['active_border_settings']);
        }
        else {
            this.activeBorderSettings = undefined;
        }
        if (data['active_shadow']) {
            this.activeShadow = new Shadow().deserialize(data['active_shadow']);
        }
        else {
            this.activeShadow = undefined;
        }
        return this;
    };
    ViewSettingsAction.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { style: this.style, visible: this.visible, pinned: this.pinned, tint: this.tint, icon: this.icon, order: this.order, disabled_input: this.disabledInput ? this.disabledInput.serialize() : null, visible_input: this.visibleInput ? this.visibleInput.serialize() : null, text_style: this.textStyle ? this.textStyle.serialize() : undefined, icon_settings: this.iconSettings ? this.iconSettings.serialize() : undefined, fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined, border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined, border_radius: this.borderRadius, shadow: this.shadow ? this.shadow.serialize() : undefined, padding: this.padding, hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined, hover_icon_settings: this.hoverIconSettings ? this.hoverIconSettings.serialize() : undefined, hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined, hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined, hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined, active_text_style: this.activeTextStyle ? this.activeTextStyle.serialize() : undefined, active_icon_settings: this.activeIconSettings ? this.activeIconSettings.serialize() : undefined, active_fill_settings: this.activeFillSettings ? this.activeFillSettings.serialize() : undefined, active_border_settings: this.activeBorderSettings ? this.activeBorderSettings.serialize() : undefined, active_shadow: this.activeShadow ? this.activeShadow.serialize() : undefined });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return ViewSettingsAction;
}(ActionItem));
export { ViewSettingsAction };
var QueryAction = /** @class */ (function () {
    function QueryAction() {
    }
    QueryAction.prototype.deserialize = function (data) {
        if (data['query']) {
            this.query = new ActionQuery().deserialize(data['query']);
        }
        return this;
    };
    QueryAction.prototype.serialize = function () {
        return {
            query: this.query ? this.query.serialize() : undefined
        };
    };
    return QueryAction;
}());
export { QueryAction };
export var DownloadActionType;
(function (DownloadActionType) {
    DownloadActionType["Input"] = "input";
    DownloadActionType["Query"] = "query";
})(DownloadActionType || (DownloadActionType = {}));
var DownloadAction = /** @class */ (function () {
    function DownloadAction() {
    }
    DownloadAction.prototype.deserialize = function (data) {
        this.type = data['type'] || DownloadActionType.Query;
        this.fileColumn = data['file_column'];
        if (data['query']) {
            this.query = new ActionQuery().deserialize(data['query']);
        }
        if (data['input']) {
            this.input = new Input().deserialize(data['input']);
        }
        return this;
    };
    DownloadAction.prototype.serialize = function () {
        return {
            type: this.type,
            query: this.query ? this.query.serialize() : undefined,
            file_column: this.fileColumn,
            input: this.input ? this.input.serialize() : undefined
        };
    };
    return DownloadAction;
}());
export { DownloadAction };
export var SegueType;
(function (SegueType) {
    SegueType["Page"] = "page";
    SegueType["PreviousPage"] = "previous_page";
    SegueType["ModelCreate"] = "model_create";
    SegueType["ModelChange"] = "model_change";
    SegueType["ModelMassEdit"] = "model_mass_edit";
    SegueType["ModelActivityLog"] = "model_activity_log";
    SegueType["ModelDelete"] = "model_delete";
})(SegueType || (SegueType = {}));
export var modelSegueTypes = [
    SegueType.ModelCreate,
    SegueType.ModelChange,
    SegueType.ModelMassEdit,
    SegueType.ModelActivityLog,
    SegueType.ModelDelete
];
var LinkAction = /** @class */ (function () {
    function LinkAction() {
    }
    LinkAction.prototype.deserialize = function (data) {
        this.type = data['type'];
        this.page = data['page'];
        this.model = data['model'];
        return this;
    };
    LinkAction.prototype.serialize = function () {
        return {
            type: this.type,
            page: this.page,
            model: this.model
        };
    };
    return LinkAction;
}());
export { LinkAction };
var NotificationAction = /** @class */ (function () {
    function NotificationAction() {
        this.closeTimeoutEnabled = true;
    }
    NotificationAction.prototype.deserialize = function (data) {
        this.type = data['type'];
        this.icon = data['icon'];
        this.color = data['color'];
        if (data['title']) {
            // Backward compatibility
            if (typeof data['title'] == 'string') {
                this.title = new Input().deserializeFromStatic('value', data['title']);
            }
            else {
                this.title = new Input().deserialize(data['title']);
            }
        }
        if (data['description']) {
            // Backward compatibility
            if (typeof data['description'] == 'string') {
                this.description = new Input().deserializeFromStatic('value', data['description']);
            }
            else {
                this.description = new Input().deserialize(data['description']);
            }
        }
        if (data['close_timeout_enabled'] !== undefined) {
            this.closeTimeoutEnabled = data['close_timeout_enabled'];
        }
        this.closeTimeout = data['close_timeout'];
        return this;
    };
    NotificationAction.prototype.serialize = function () {
        return {
            title: this.title ? this.title.serialize() : null,
            description: this.description ? this.description.serialize() : null,
            icon: this.icon,
            color: this.color,
            type: this.type,
            close_timeout_enabled: this.closeTimeoutEnabled,
            close_timeout: this.closeTimeout
        };
    };
    return NotificationAction;
}());
export { NotificationAction };
var SetPropertyAction = /** @class */ (function () {
    function SetPropertyAction() {
    }
    SetPropertyAction.prototype.deserialize = function (data) {
        this.property = data['property'];
        if (data['value']) {
            this.value = new Input().deserialize(data['value']);
        }
        return this;
    };
    SetPropertyAction.prototype.serialize = function () {
        return {
            property: this.property,
            value: this.value ? this.value.serialize() : null
        };
    };
    return SetPropertyAction;
}());
export { SetPropertyAction };
var RunJavaScriptAction = /** @class */ (function () {
    function RunJavaScriptAction() {
    }
    RunJavaScriptAction.prototype.deserialize = function (data) {
        this.js = data['js'];
        return this;
    };
    RunJavaScriptAction.prototype.serialize = function () {
        return {
            js: this.js
        };
    };
    return RunJavaScriptAction;
}());
export { RunJavaScriptAction };
var CopyToClipboardAction = /** @class */ (function () {
    function CopyToClipboardAction() {
    }
    CopyToClipboardAction.prototype.deserialize = function (data) {
        if (data['value']) {
            this.value = new Input().deserialize(data['value']);
        }
        return this;
    };
    CopyToClipboardAction.prototype.serialize = function () {
        return {
            value: this.value ? this.value.serialize() : null
        };
    };
    return CopyToClipboardAction;
}());
export { CopyToClipboardAction };
var ExportAction = /** @class */ (function () {
    function ExportAction() {
    }
    ExportAction.prototype.deserialize = function (data) {
        if (data['data_source']) {
            this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
        }
        return this;
    };
    ExportAction.prototype.serialize = function () {
        return {
            data_source: this.dataSource ? this.dataSource.serialize() : undefined
        };
    };
    ExportAction.prototype.getModelId = function () {
        return this.dataSource &&
            this.dataSource.type == DataSourceType.Query &&
            this.dataSource.query &&
            this.dataSource.query.queryType == QueryType.Simple &&
            this.dataSource.query.simpleQuery
            ? [this.dataSource.queryResource, this.dataSource.query.simpleQuery.model].join('.')
            : undefined;
    };
    return ExportAction;
}());
export { ExportAction };
var ImportAction = /** @class */ (function () {
    function ImportAction() {
    }
    ImportAction.prototype.deserialize = function (data) {
        this.resource = data['resource'];
        this.model = data['model'];
        return this;
    };
    ImportAction.prototype.serialize = function () {
        return {
            resource: this.resource,
            model: this.model
        };
    };
    ImportAction.prototype.getModelId = function () {
        if (!isSet(this.resource) || !isSet(this.model)) {
            return;
        }
        return [this.resource, this.model].join('.');
    };
    return ImportAction;
}());
export { ImportAction };
var OpenPopupAction = /** @class */ (function () {
    function OpenPopupAction() {
        this.togglePopup = false;
    }
    OpenPopupAction.prototype.deserialize = function (data) {
        this.popup = data['popup'];
        if (isSet(data['toggle_popup'])) {
            this.togglePopup = data['toggle_popup'];
        }
        return this;
    };
    OpenPopupAction.prototype.serialize = function () {
        return {
            popup: this.popup,
            toggle_popup: this.togglePopup
        };
    };
    return OpenPopupAction;
}());
export { OpenPopupAction };
var ClosePopupAction = /** @class */ (function () {
    function ClosePopupAction() {
    }
    ClosePopupAction.prototype.deserialize = function (data) {
        this.popup = data['popup'];
        return this;
    };
    ClosePopupAction.prototype.serialize = function () {
        return {
            popup: this.popup
        };
    };
    return ClosePopupAction;
}());
export { ClosePopupAction };
var OpenActionMenuAction = /** @class */ (function () {
    function OpenActionMenuAction() {
        this.actions = [];
    }
    OpenActionMenuAction.prototype.deserialize = function (data) {
        if (data['actions']) {
            this.actions = data['actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        return this;
    };
    OpenActionMenuAction.prototype.serialize = function () {
        return {
            actions: this.actions.map(function (item) { return item.serialize(); })
        };
    };
    return OpenActionMenuAction;
}());
export { OpenActionMenuAction };
var WorkflowAction = /** @class */ (function () {
    function WorkflowAction() {
    }
    WorkflowAction.prototype.deserialize = function (data) {
        if (data['workflow']) {
            this.workflow = new Workflow().deserialize(data['workflow']);
        }
        return this;
    };
    WorkflowAction.prototype.serialize = function () {
        return {
            workflow: this.workflow ? this.workflow.serialize() : undefined
        };
    };
    return WorkflowAction;
}());
export { WorkflowAction };
var ActionDescription = /** @class */ (function () {
    function ActionDescription() {
        this.actionParams = [];
        this.outputs = [];
        this.arrayOutput = false;
        this.protected = false;
        this.virtual = false;
        this.featured = false;
        this.orderAfter = undefined;
        this.draft = false;
        this.deleted = false;
        this.deprecatedDynamicStatus = false;
    }
    ActionDescription.generateName = function () {
        return generateAlphanumeric(8);
    };
    ActionDescription.prototype.deserialize = function (data) {
        this.resource = data['resource'];
        this.name = data['name'];
        this.verboseName = data['verbose_name'] || this.name;
        if (data['icon']) {
            this.icon = data['icon'];
        }
        if (data['params']) {
            if (isArray(data['params'])) {
                this.actionParams = data['params'].map(function (item) { return new ParameterField().deserialize(item); });
                this.params = {};
            }
            else {
                this.params = data['params'];
                this.type = this.params['type'];
                this.verboseName = this.params['verbose_name'] || this.name;
                this.icon = this.params['icon'];
                this.arrayOutput = this.params['array_output'];
                if (this.params['protected'] != undefined) {
                    this.protected = this.params['protected'];
                }
                if (this.params['action_params']) {
                    this.actionParams = this.params['action_params'].map(function (item) { return new ParameterField().deserialize(item); });
                }
                if (this.params['outputs']) {
                    this.outputs = this.params['outputs'].map(function (item) { return new FieldOutput().deserialize(item); });
                }
                this.description = this.params['description'];
                if (this.params['query_action']) {
                    this.queryAction = new QueryAction().deserialize(this.params['query_action']);
                }
                else if (this.params['query']) {
                    this.queryAction = new QueryAction().deserialize({
                        query: this.params['query']
                    });
                }
                if (this.params['download_action']) {
                    this.downloadAction = new DownloadAction().deserialize(this.params['download_action']);
                }
                if (this.params['link_action']) {
                    this.linkAction = new LinkAction().deserialize(this.params['link_action']);
                }
                if (this.params['element_action']) {
                    if (isArray(this.params['element_action'])) {
                        this.elementAction = this.params['element_action'];
                    }
                    else if (typeof this.params['element_action'] == 'string') {
                        this.elementAction = this.params['element_action'].split('.');
                    }
                }
                if (this.params['notification_action']) {
                    this.notificationAction = new NotificationAction().deserialize(this.params['notification_action']);
                }
                if (this.params['export_action']) {
                    // Backward compatibility
                    if (this.params['export_action']['model']) {
                        this.exportAction = new ExportAction();
                        this.exportAction.dataSource = new ListModelDescriptionDataSource();
                        this.exportAction.dataSource.type = DataSourceType.Query;
                        this.exportAction.dataSource.queryResource = data['resource'];
                        this.exportAction.dataSource.query = new ListModelDescriptionQuery();
                        this.exportAction.dataSource.query.queryType = QueryType.Simple;
                        this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();
                        this.exportAction.dataSource.query.simpleQuery.model = this.params['export_action']['model'];
                    }
                    else {
                        this.exportAction = new ExportAction().deserialize(this.params['export_action']);
                    }
                }
                if (this.params['import_action']) {
                    this.importAction = new ImportAction().deserialize(this.params['import_action']);
                }
                if (this.params['open_popup_action']) {
                    this.openPopupAction = new OpenPopupAction().deserialize(this.params['open_popup_action']);
                }
                if (this.params['close_popup_action']) {
                    this.closePopupAction = new ClosePopupAction().deserialize(this.params['close_popup_action']);
                }
                if (this.params['open_action_menu_action']) {
                    this.openActionMenuAction = new OpenActionMenuAction().deserialize(this.params['open_action_menu_action']);
                }
                if (this.params['workflow_action']) {
                    this.workflowAction = new WorkflowAction().deserialize(this.params['workflow_action']);
                }
                if (this.params['set_property_action']) {
                    this.setPropertyAction = new SetPropertyAction().deserialize(this.params['set_property_action']);
                }
                if (this.params['run_javascript_action']) {
                    this.runJavaScriptAction = new RunJavaScriptAction().deserialize(this.params['run_javascript_action']);
                }
                if (this.params['copy_to_clipboard_action']) {
                    this.copyToClipboardAction = new CopyToClipboardAction().deserialize(this.params['copy_to_clipboard_action']);
                }
                if (this.params['virtual']) {
                    this.virtual = this.params['virtual'];
                }
                if (this.params['featured']) {
                    this.featured = this.params['featured'];
                }
                if (this.params['order_after'] !== undefined) {
                    this.orderAfter = this.params['order_after'];
                }
            }
        }
        // Backward compatibility
        if (!this.type) {
            if (this.queryAction) {
                this.type = ActionType.Query;
            }
            else if (this.linkAction) {
                this.type = ActionType.Link;
            }
            else if (this.linkAction && this.linkAction.type == 'model_export') {
                // Backward compatibility (<2.2.6)
                var model = this.linkAction.model ? splitmax(this.linkAction.model, '.', 2) : undefined;
                this.type = ActionType.Export;
                if (model && model.length == 2) {
                    this.exportAction = new ExportAction();
                    this.exportAction.dataSource = new ListModelDescriptionDataSource();
                    this.exportAction.dataSource.type = DataSourceType.Query;
                    this.exportAction.dataSource.query = new ListModelDescriptionQuery();
                    this.exportAction.dataSource.query.queryType = QueryType.Simple;
                    this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();
                    this.exportAction.dataSource.queryResource = model[0], this.exportAction.dataSource.query.simpleQuery.model = model[1];
                }
            }
        }
        if (data['draft'] !== undefined) {
            this.draft = data['draft'];
        }
        if (data['deleted'] !== undefined) {
            this.deleted = data['deleted'];
        }
        // Backward compatibility
        if (data['dynamic_status'] != undefined) {
            this.deprecatedDynamicStatus = data['dynamic_status'];
        }
        if (data['model_action'] != undefined) {
            this.deprecatedModelAction = data['model_action'];
        }
        return this;
    };
    Object.defineProperty(ActionDescription.prototype, "id", {
        get: function () {
            return [this.resource, this.name].join('.');
        },
        enumerable: true,
        configurable: true
    });
    ActionDescription.prototype.isSame = function (action) {
        if (!action) {
            return false;
        }
        if (action instanceof ActionDescription) {
            return action.resource == this.resource && action.name == this.name;
        }
        else {
            var id = action;
            var params = splitmax(id, '.', 2);
            return params.length == 2
                ? (!this.resource || this.resource == params[0]) && this.name == params[1]
                : this.name == params[0];
        }
    };
    ActionDescription.prototype.serialize = function () {
        return {
            resource: this.resource,
            name: this.name,
            params: {
                type: this.type,
                verbose_name: this.verboseName,
                icon: this.icon,
                action_params: this.actionParams.map(function (item) { return item.serialize(); }),
                outputs: this.outputs.map(function (item) { return item.serialize(); }),
                array_output: this.arrayOutput,
                description: this.description,
                protected: this.protected,
                query_action: this.queryAction ? this.queryAction.serialize() : undefined,
                download_action: this.downloadAction ? this.downloadAction.serialize() : undefined,
                link_action: this.linkAction ? this.linkAction.serialize() : undefined,
                element_action: this.elementAction,
                notification_action: this.notificationAction ? this.notificationAction.serialize() : undefined,
                export_action: this.exportAction ? this.exportAction.serialize() : undefined,
                import_action: this.importAction ? this.importAction.serialize() : undefined,
                open_popup_action: this.openPopupAction ? this.openPopupAction.serialize() : undefined,
                close_popup_action: this.closePopupAction ? this.closePopupAction.serialize() : undefined,
                open_action_menu_action: this.openActionMenuAction ? this.openActionMenuAction.serialize() : undefined,
                workflow_action: this.workflowAction ? this.workflowAction.serialize() : undefined,
                set_property_action: this.setPropertyAction ? this.setPropertyAction.serialize() : undefined,
                run_javascript_action: this.runJavaScriptAction ? this.runJavaScriptAction.serialize() : undefined,
                copy_to_clipboard_action: this.copyToClipboardAction ? this.copyToClipboardAction.serialize() : undefined,
                virtual: this.virtual,
                featured: this.featured,
                order_after: this.orderAfter
            },
            draft: this.draft,
            deleted: this.deleted
        };
    };
    ActionDescription.prototype.getLink = function (modelId) {
        if (this.queryAction) {
            return ['action', this.id];
        }
        else if (this.linkAction) {
            // return [this.linkAction.link];
        }
    };
    Object.defineProperty(ActionDescription.prototype, "queryLink", {
        get: function () {
            return ['action', this.id];
        },
        enumerable: true,
        configurable: true
    });
    ActionDescription.prototype.clone = function () {
        return new ActionDescription().deserialize(this.serialize());
    };
    Object.defineProperty(ActionDescription.prototype, "typeStr", {
        get: function () {
            if (this.type == ActionType.Query) {
                return 'Run Operation';
            }
            else if (this.type == ActionType.Download) {
                return 'Download File';
            }
            else if (this.type == ActionType.Link) {
                return 'Navigate to Page';
            }
            else if (this.type == ActionType.ExternalLink) {
                return 'Open URL';
            }
            else if (this.type == ActionType.ElementAction) {
                return 'Component Action';
            }
            else if (this.type == ActionType.ShowNotification) {
                return 'Show Notification';
            }
            else if (this.type == ActionType.SetProperty) {
                return 'Set Variable';
            }
            else if (this.type == ActionType.RunJavaScript) {
                return 'Run JavaScript';
            }
            else if (this.type == ActionType.CopyToClipboard) {
                return 'Copy to Clipboard';
            }
            else if (this.type == ActionType.Export) {
                return 'Export Data';
            }
            else if (this.type == ActionType.Import) {
                return 'Import Data';
            }
            else if (this.type == ActionType.OpenPopup) {
                return 'Open Overlay';
            }
            else if (this.type == ActionType.ClosePopup) {
                return 'Close Overlay';
            }
            else if (this.type == ActionType.OpenActionMenu) {
                return 'Open Actions dropdown';
            }
            else if (this.type == ActionType.ScanCode) {
                return 'Scan QR/Bar code';
            }
            else if (this.type == ActionType.Workflow) {
                return 'Run Workflow';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDescription.prototype, "typeIcon", {
        get: function () {
            if (this.type == ActionType.Query) {
                return 'cloud_upload';
            }
            else if (this.type == ActionType.Download) {
                return 'save';
            }
            else if (this.type == ActionType.Link) {
                return 'redo';
            }
            else if (this.type == ActionType.ExternalLink) {
                return 'model_link';
            }
            else if (this.type == ActionType.ElementAction) {
                return 'components';
            }
            else if (this.type == ActionType.ShowNotification) {
                return 'notification';
            }
            else if (this.type == ActionType.SetProperty) {
                return 'variable';
            }
            else if (this.type == ActionType.RunJavaScript) {
                return 'console';
            }
            else if (this.type == ActionType.CopyToClipboard) {
                return 'documents';
            }
            else if (this.type == ActionType.Export) {
                return 'download';
            }
            else if (this.type == ActionType.Import) {
                return 'upload';
            }
            else if (this.type == ActionType.OpenPopup) {
                return 'copy';
            }
            else if (this.type == ActionType.ClosePopup) {
                return 'windows';
            }
            else if (this.type == ActionType.OpenActionMenu) {
                return 'fileds';
            }
            else if (this.type == ActionType.Workflow) {
                return 'workflow';
            }
            else if (this.type == ActionType.ScanCode) {
                return 'qr_code';
            }
            else {
                return 'power';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDescription.prototype, "editLink", {
        get: function () {
            return ['resources', this.resource, 'actions', this.name];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDescription.prototype, "anyName", {
        get: function () {
            return isSet(this.verboseName) ? this.verboseName : this.name;
        },
        enumerable: true,
        configurable: true
    });
    return ActionDescription;
}());
export { ActionDescription };
