import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Option } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TintStyle } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import {
  BaseField,
  FieldDescription,
  FieldType,
  getFieldDescriptionByType,
  Input as FieldInput
} from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, traverseModelPath } from '@modules/models';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-filters-list-item',
  templateUrl: './filters-list-item.component.html',
  providers: [ModelSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersListItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: FilterItem2;
  @Input() filters: FilterItem2[] = [];
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() bright = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() theme = false;
  @Output() filtersUpdated = new EventEmitter<FilterItem2[]>();

  @HostBinding('class.filters__item') cls = true;
  @HostBinding('class.filters__item_active') get activeCls() {
    return false;
  }

  fieldPath: string[] = [];
  valueFieldDescription: FieldDescription;
  valueDisplay: string;
  valueLoading = false;
  tintStyles = TintStyle;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    public source: ModelSelectSource,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FiltersListItemComponent>): void {
    if (changes.item && changes.dataSource) {
      this.updateValueDisplay();
    }
  }

  getRelatedFieldValueDisplay(
    relatedModelDescription: ModelDescription,
    field: BaseField,
    value: any
  ): Observable<string> {
    const listQuery = new ListModelDescriptionQuery();
    const detailQuery = new ModelDescriptionQuery();

    listQuery.queryType = QueryType.Simple;
    listQuery.simpleQuery = new listQuery.simpleQueryClass();
    listQuery.simpleQuery.model = relatedModelDescription.model;

    detailQuery.queryType = QueryType.Simple;
    detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
    detailQuery.simpleQuery.model = relatedModelDescription.model;

    const nameInput = field.params['custom_display_field_input']
      ? new FieldInput().deserialize(field.params['custom_display_field_input'])
      : undefined;

    const subtitleInput = field.params['subtitle_input']
      ? new FieldInput().deserialize(field.params['subtitle_input'])
      : undefined;

    const iconInput = field.params['icon_input'] ? new FieldInput().deserialize(field.params['icon_input']) : undefined;

    this.source.init({
      resource: relatedModelDescription.resource,
      query: listQuery,
      queryParameters: relatedModelDescription.getParameters,
      detailQuery: detailQuery,
      detailQueryParameters: relatedModelDescription.getDetailParametersOrDefaults,
      valueField: field.params['custom_primary_key'] || relatedModelDescription.primaryKeyField,
      nameField: field.params['custom_display_field'] || relatedModelDescription.displayField,
      nameInput: nameInput,
      subtitleField: field.params['subtitle_field'],
      subtitleInput: subtitleInput,
      iconField: field.params['icon_field'],
      iconInput: iconInput,
      context: this.context,
      contextElement: this.contextElement
    });
    this.source.reset();

    return this.source.fetchByValue(value).pipe(map((option: Option) => (option ? option.name : value)));
  }

  getDataSourceParams(): {
    type: DataSourceType;
    query: ListModelDescriptionQuery;
    resource: Resource;
    modelId: string;
  } {
    const type = this.dataSource ? this.dataSource.type : undefined;
    const query = type == DataSourceType.Query ? (this.dataSource.query as ListModelDescriptionQuery) : undefined;
    const resource =
      type == DataSourceType.Query
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.dataSource.queryResource)
        : undefined;
    const modelId =
      resource && query && query.queryType == QueryType.Simple && query.simpleQuery
        ? [resource.uniqueName, query.simpleQuery.model].join('.')
        : undefined;

    return {
      type: type,
      query: query,
      resource: resource,
      modelId: modelId
    };
  }

  traverseDataSourcePath$(
    path: string[]
  ): Observable<{
    path: {
      name: string;
      verboseName: string;
    }[];
    field: BaseField;
  }> {
    const { modelId } = this.getDataSourceParams();

    if (isSet(modelId)) {
      return this.modelDescriptionStore.getFirst().pipe(
        map(modelDescriptions => {
          const modelDescription = isSet(modelId) ? modelDescriptions.find(item => item.isSame(modelId)) : undefined;
          const traversePath = traverseModelPath(modelDescription, path, modelDescriptions);

          if (!traversePath || !traversePath.length) {
            return;
          }

          return {
            path: traversePath.map(item => {
              return {
                name: item.name,
                verboseName: item.verboseName
              };
            }),
            field: traversePath[traversePath.length - 1].field
          };
        })
      );
    } else if (this.dataSource) {
      if (path.length != 1) {
        return of(undefined);
      }

      const field = this.dataSource.columns.find(item => item.name == path[0]);

      return of({
        path: [{ name: field.name, verboseName: field.verboseName }],
        field: field
      });
    } else {
      return of(undefined);
    }
  }

  updateValueDisplay() {
    this.valueLoading = false;
    this.cd.markForCheck();

    combineLatest(this.traverseDataSourcePath$(this.item.field), this.modelDescriptionStore.getFirst())
      .pipe(untilDestroyed(this))
      .subscribe(([path, modelDescriptions]) => {
        let value = this.item.value;
        const field = path ? path.field : undefined;
        const fieldDescription = getFieldDescriptionByType(field ? field.field : undefined);
        const lookup = this.item.lookup
          ? fieldDescription.lookups.find(item => item.type.lookup == this.item.lookup.lookup)
          : undefined;
        const lookupFieldDescription = getFieldDescriptionByType(
          lookup && lookup.field ? lookup.field : FieldType.Boolean
        );

        const relatedModelId =
          field && field.field == FieldType.RelatedModel && field.params && field.params['related_model']
            ? field.params['related_model']['model']
            : undefined;
        const relatedModelDescription = relatedModelId
          ? modelDescriptions.find(item => item.isSame(relatedModelId))
          : undefined;

        this.fieldPath = path ? path.path.map(item => item.verboseName) : [];
        this.valueFieldDescription = fieldDescription;
        this.cd.markForCheck();

        if (relatedModelDescription && lookup.field == FieldType.RelatedModel) {
          this.valueLoading = true;
          this.cd.markForCheck();

          this.getRelatedFieldValueDisplay(relatedModelDescription, field, value)
            .pipe(untilDestroyed(this))
            .subscribe(
              result => {
                this.valueDisplay = result;
                this.valueLoading = false;
                this.cd.markForCheck();
              },
              () => {
                this.valueDisplay = value;
                this.valueLoading = false;
                this.cd.markForCheck();
              }
            );
        } else {
          if (lookupFieldDescription.deserializeValue) {
            value = lookupFieldDescription.deserializeValue(value, field);
          }

          if (lookupFieldDescription.valueToStr) {
            value = lookupFieldDescription.valueToStr(value, { field: field });
          }

          this.valueDisplay = value;
          this.cd.markForCheck();
        }
      });
  }
}
