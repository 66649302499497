<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="defaultTitle"
    [subtitle]="subtitle"
    [deleteEnabled]="deleteEnabled"
    (deleteClick)="delete.emit()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <app-chart-widget-data-source-edit
            [control]="form.controls.data_source"
            [datasetColumnEnabled]="datasetColumnEnabled"
            [datasetColumnAllowed]="datasetColumnAllowed"
            [datasetColumnControl]="datasetColumnControl"
            [chartType]="chartType"
            [label]="'choose collection'"
            [typeOrange]="true"
            [queryOptionEmptyLabel]="'No collections'"
            [context]="context"
            [resourceClasses]="'component-data-resource'"
            [queryOptionClasses]="'component-data-collection'"
            [analyticsSource]="widget.analyticsGenericName"
          ></app-chart-widget-data-source-edit>
        </app-sidebar-section>

        <app-sidebar-section
          *ngIf="
            (form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Input ||
            (form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
            ((form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Query &&
              form.controls.data_source.controls.query.valid)
          "
          [title]="(form.controls.data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
          [description]="
            (form.controls.data_source.getQueryEditable$() | async)
              ? 'Manually or use value from other components'
              : 'Static or use value from other components'
          "
        >
          <app-data-source-inputs-edit
            [control]="form.controls.data_source"
            [editable]="form.controls.data_source.getQueryEditable$() | async"
            [context]="context"
            [classes]="'component-data-inputs'"
            [itemClasses]="'component-data-inputs-item'"
            [addClasses]="'component-data-inputs-add'"
            [analyticsSource]="widget.analyticsGenericName"
            (addInput)="addQueryInput()"
          ></app-data-source-inputs-edit>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div
            *ngIf="(!singleColorDataset || (datasetColumnControl?.value | appIsSet)) && groupsControl"
            class="sidebar__list"
          >
            <div
              cdkDropList
              [cdkDropListLockAxis]="'y'"
              class="sidebar-list"
              (cdkDropListDropped)="dragDropGroup($event)"
            >
              <div
                *ngFor="let group of groupsControl.controls; let i = index"
                [formGroup]="group"
                cdkDrag
                class="sidebar-list__item"
              >
                <app-chart-widget-edit-dataset
                  [group]="group"
                  [collapseContext]="collapseContext"
                  [effectiveColor]="groupsColors[i]"
                  [defaultColor]="getGroupDefaultColor(i)"
                  [defaultColors]="defaultColors"
                  (remove)="groupsControl.remove(group)"
                ></app-chart-widget-edit-dataset>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!(datasetColumnControl?.value | appIsSet)">
            <div *ngIf="labelEnabled" class="sidebar__element">
              <app-sidebar-field [label]="'label'">
                <app-auto-field
                  [form]="form"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'name',
                      field: 'CharField',
                      required: false,
                      placeholder: 'Name in chart',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div *ngIf="singleColorDataset" class="sidebar__element">
              <app-sidebar-field [label]="'color'">
                <app-auto-field
                  [form]="form"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'color',
                      field: 'ColorField',
                      required: false,
                      params: {
                        classes: ['select_fill'],
                        allow_empty: true,
                        empty_color: defaultColor,
                        default_color: defaultColor,
                        colors: defaultColors,
                        custom_colors: true
                      }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>
          </ng-container>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'tooltip format'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'format',
                    field: 'CharField',
                    required: false,
                    placeholder: 'Format value, e.g. 0,0.0000',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
              <ng-container description>
                Numbers formatting supported <a href="http://numeraljs.com/#format" target="_blank">Learn More</a>
              </ng-container>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <app-sidebar-submit
    [saveEnabled]="false"
    [cancelEnabled]="false"
    [backEnabled]="true"
    [errors]="form | appFormErrors"
    (cancelClick)="cancel()"
  >
  </app-sidebar-submit>
</form>
