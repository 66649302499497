import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, first, map, skip, switchMap, tap } from 'rxjs/operators';

import {
  modelFieldToDisplayField,
  modelFieldToRawListViewSettingsColumn,
  RawListViewSettingsColumn,
  ViewContext
} from '@modules/customize';
import { CustomSelectItem, Option } from '@modules/field-components';
import { getFieldDescriptionByType, Input, isRequiredInputsSet } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelFieldType } from '@modules/models';
import {
  FieldInputControl,
  InputFieldProvider,
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  modelDescriptionHasAutoParameters,
  parametersToProviderItems
} from '@modules/parameters';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { ascComparator, controlValue, isSet, splitmax } from '@shared';

export const DefaultForeignKeyPrimaryKeyTransformer = `// add custom transformation here
return value;`;

export function validateInputs(): ValidatorFn {
  return control => {
    const parent = control.parent as ForeignKeyFieldViewParamsForm;

    if (!parent) {
      return;
    }

    const fields = parent.inputFieldProvider.fields;
    const inputs: Input[] = control.value;

    if (!isRequiredInputsSet(fields, inputs)) {
      return { required: true };
    }
  };
}

@Injectable()
export class ForeignKeyFieldViewParamsForm extends FormGroup implements OnDestroy {
  controls: {
    related_resource: FormControl;
    related_model: FormControl;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    custom_primary_key: FormControl;
    custom_display_field: FormControl;
    custom_display_input_enabled: FormControl;
    custom_display_input: FieldInputControl;
    subtitle_field: FormControl;
    subtitle_input_enabled: FormControl;
    subtitle_input: FieldInputControl;
    icon_field: FormControl;
    icon_input_enabled: FormControl;
    icon_input: FieldInputControl;
    foreign_key_transformer: FormControl;
    create_button: FormControl;
    inputs: FormControl;
  };
  inputFieldProvider = new InputFieldProvider();
  control: AbstractControl;
  context: ViewContext;

  constructor(
    private fb: FormBuilder,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore
  ) {
    super({
      related_resource: new FormControl('', Validators.required),
      related_model: new FormControl(undefined, Validators.required),
      sorting_field: new FormControl(undefined),
      sorting_asc: new FormControl(true),
      custom_primary_key: new FormControl(undefined, Validators.required),
      custom_display_field: new FormControl(undefined, Validators.required),
      custom_display_input_enabled: new FormControl(false),
      custom_display_input: new FieldInputControl({ name: 'value' }),
      subtitle_field: new FormControl(''),
      subtitle_input_enabled: new FormControl(false),
      subtitle_input: new FieldInputControl({ name: 'value' }),
      icon_field: new FormControl(''),
      icon_input_enabled: new FormControl(false),
      icon_input: new FieldInputControl({ name: 'value' }),
      foreign_key_transformer: new FormControl(DefaultForeignKeyPrimaryKeyTransformer),
      create_button: new FormControl(true),
      inputs: new FormControl([], validateInputs())
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  initObservers() {
    this.controls.related_resource.valueChanges.subscribe(value => this.onResourceChange(value));

    this.modelDescription$()
      .pipe(
        distinctUntilChanged((lhs, rhs) => {
          const lhsModelId = lhs ? lhs.modelId : undefined;
          const rhsModelId = rhs ? rhs.modelId : undefined;
          return lhsModelId == rhsModelId;
        }),
        skip(1)
      )
      .subscribe(modelDescription => {
        if (modelDescription) {
          let primaryKeyField: string;

          if (modelDescription.primaryKeyField) {
            primaryKeyField = modelDescription.primaryKeyField;
          } else if (modelDescription.dbFields.length) {
            primaryKeyField = modelDescription.dbFields[0].name;
          }

          if (isSet(primaryKeyField)) {
            this.controls.custom_primary_key.patchValue(primaryKeyField);
          }

          let displayField: string;

          if (modelDescription.displayField) {
            displayField = modelDescription.displayField;
          } else if (modelDescription.dbFields.length) {
            displayField = modelDescription.dbFields[0].name;
          }

          if (isSet(displayField)) {
            this.controls.custom_display_field.patchValue(displayField);
            this.controls.custom_display_input_enabled.patchValue(false);
            this.controls.custom_display_input.patchValue({});
          }
        }

        this.onModelParamsChange();
      });
  }

  init(control: AbstractControl, context: ViewContext) {
    this.control = control;
    this.context = context;

    if (control.value) {
      const relatedModel = control.value['related_model'];
      const inputs = control.value['inputs'] ? control.value['inputs'].map(item => new Input().deserialize(item)) : [];

      if (isSet(relatedModel) && isSet(relatedModel['model'])) {
        const defaultResource =
          this.context && this.context.resource ? this.context && this.context.resource.uniqueName : undefined;
        const [resourceName, modelName] = relatedModel['model'].includes('.')
          ? splitmax(relatedModel['model'], '.', 2)
          : [defaultResource, relatedModel['model']];

        if (isSet(resourceName) && isSet(modelName)) {
          this.controls.related_resource.patchValue(resourceName);
          this.controls.related_model.patchValue(modelName);
        }
      }

      this.controls.sorting_field.patchValue(control.value['sorting_field']);
      this.controls.sorting_asc.patchValue(control.value['sorting_asc']);

      if (isSet(control.value['custom_primary_key'])) {
        this.controls.custom_primary_key.patchValue(control.value['custom_primary_key']);
      }

      if (control.value['custom_display_field_input']) {
        this.controls.custom_display_input_enabled.patchValue(true);
        this.controls.custom_display_input.patchValue(control.value['custom_display_field_input']);
      } else {
        if (isSet(control.value['custom_display_field'])) {
          this.controls.custom_display_field.patchValue(control.value['custom_display_field']);
        }
      }

      this.controls.subtitle_field.patchValue(control.value['subtitle_field']);
      this.controls.subtitle_input_enabled.patchValue(!!control.value['subtitle_input']);
      this.controls.subtitle_input.patchValue(control.value['subtitle_input'] ? control.value['subtitle_input'] : {});

      this.controls.icon_field.patchValue(control.value['icon_field']);
      this.controls.icon_input_enabled.patchValue(!!control.value['icon_input']);
      this.controls.icon_input.patchValue(control.value['icon_input'] ? control.value['icon_input'] : {});

      if (isSet(control.value['create_button'])) {
        this.controls.create_button.patchValue(control.value['create_button']);
      }

      if (control.value['foreign_key_transformer']) {
        this.controls.foreign_key_transformer.patchValue(control.value['foreign_key_transformer']);
      } else if (control.value['primary_key_transformer']) {
        this.controls.foreign_key_transformer.patchValue(control.value['primary_key_transformer']);
      }

      this.controls.inputs.patchValue(inputs);

      this.markAsPristine();
    } else {
      this.markAsDirty();
    }

    this.valueChanges.pipe(delay(0)).subscribe(() => this.submit());

    this.updateInputFieldProvider().subscribe();

    this.initObservers();
  }

  toggleDefaultSorting() {
    const control = this.controls.sorting_asc;
    control.patchValue(!control.value);
  }

  updateInputFieldProvider() {
    return combineLatest(this.resource$(), this.modelDescription$()).pipe(
      first(),
      map(([resource, modelDescription]): InputFieldProviderItem[] => {
        const parameters = modelDescription ? modelDescription.getParameters : [];
        const query = modelDescription ? modelDescription.getQuery : undefined;
        const columns = modelDescription
          ? modelDescription.fields
              .filter(item => item.type == ModelFieldType.Db)
              .map(item => modelFieldToRawListViewSettingsColumn(item))
          : [];

        return [
          ...parametersToProviderItems(parameters),
          ...inputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns)
        ];
      }),
      tap(items => {
        this.inputFieldProvider.setProvider(items);
      })
    );
  }

  resource$(): Observable<Resource> {
    return controlValue<string>(this.controls.related_resource).pipe(
      map(value => this.currentEnvironmentStore.resources.find(item => item.uniqueName == value))
    );
  }

  modelDescription$(): Observable<ModelDescription> {
    return combineLatest(
      controlValue<string>(this.controls.related_resource),
      controlValue<string>(this.controls.related_model)
    ).pipe(
      switchMap(([resourceName, modelName]) => {
        const modelId = resourceName && modelName ? [resourceName, modelName].join('.') : null;
        return this.modelDescriptionStore.getDetailFirst(modelId);
      })
    );
  }

  resourceModelItems$(): Observable<CustomSelectItem<string>[]> {
    return combineLatest(this.resource$(), this.modelDescriptionStore.get()).pipe(
      map(([resource, modelDescriptions]) => {
        if (!resource) {
          return [];
        }

        const options: CustomSelectItem<string>[] = [];

        if (modelDescriptions) {
          options.push(
            ...modelDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(
                item =>
                  !resource.demo ||
                  item.featured ||
                  (this.controls.related_model.value && this.controls.related_model.value['model'] == item.model)
              )
              .sort((lhs, rhs) => {
                return ascComparator(
                  String(lhs.verboseNamePlural).toLowerCase(),
                  String(rhs.verboseNamePlural).toLowerCase()
                );
              })
              .map(item => {
                return {
                  option: {
                    value: item.model,
                    name: item.verboseNamePlural,
                    icon: 'document'
                  }
                };
              })
          );
        }

        return options;
      })
    );
  }

  columnOptions$(): Observable<CustomSelectItem<string>[]> {
    return this.modelDescription$().pipe(
      map(modelDescription => {
        if (!modelDescription) {
          return [];
        }

        const resource = this.currentEnvironmentStore.resources.find(
          item => item.uniqueName == modelDescription.resource
        );

        if (!resource) {
          return [];
        }

        const columns = modelDescription
          ? modelDescription.fields
              .map(item => modelFieldToDisplayField(item, false))
              .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item))
          : [];

        return columns.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);
          return {
            option: {
              value: item.name,
              name: item.verboseName || item.name,
              icon: fieldDescription ? fieldDescription.icon : undefined
            }
          };
        });
      })
    );
  }

  isFieldSortable(
    query: ListModelDescriptionQuery,
    field: RawListViewSettingsColumn,
    resource?: Resource,
    modelDescription?: ModelDescription
  ) {
    if (field.flex) {
      return false;
    }

    if (query) {
      if (modelDescriptionHasAutoParameters(resource, modelDescription)) {
        return true;
      } else if (query.queryType == QueryType.Simple) {
        return modelDescription && modelDescription.getQuery && modelDescription.getQuery.isFieldSortable(field);
      } else if (query instanceof ListModelDescriptionQuery) {
        return query.isFieldSortable(field);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  sortableColumnOptions$(): Observable<Option<string>[]> {
    return combineLatest(this.resource$(), this.modelDescription$(), this.columnOptions$()).pipe(
      debounceTime(60),
      map(([resource, modelDescription]) => {
        const query = modelDescription ? modelDescription.getQuery : undefined;
        const columns = modelDescription
          ? modelDescription.fields
              .map(item => modelFieldToDisplayField(item, false))
              .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item))
          : [];

        return columns
          .filter(item => {
            return this.isFieldSortable(query, item, resource, modelDescription);
          })
          .map(item => {
            return {
              value: item.name,
              name: item.verboseName || item.name
            };
          });
      })
    );
  }

  isPrimaryKeyTransformerChanged() {
    return this.controls.foreign_key_transformer.value != DefaultForeignKeyPrimaryKeyTransformer;
  }

  onResourceChange(resourceName: string) {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    const firstModel = this.modelDescriptionStore.instance.find(model => {
      if (model.resource != resourceName) {
        return false;
      } else if (model.getParameters.filter(item => item.required).length) {
        return false;
      }

      if (resource.demo) {
        return model.featured;
      }

      return true;
    });

    if (firstModel) {
      this.controls.related_model.patchValue(firstModel.model);
    }
  }

  onModelParamsChange() {
    setTimeout(() => {
      this.updateInputFieldProvider().subscribe();
    }, 0);
  }

  submit() {
    const modelId =
      this.controls.related_resource.value && this.controls.related_model.value
        ? { model: [this.controls.related_resource.value, this.controls.related_model.value].join('.') }
        : null;
    const params = {
      related_model: modelId,
      sorting_field: this.controls.sorting_field.value,
      sorting_asc: this.controls.sorting_asc.value,
      custom_primary_key: this.controls.custom_primary_key.value,
      custom_display_field: !this.controls.custom_display_input_enabled.value
        ? this.controls.custom_display_field.value
        : undefined,
      custom_display_field_input: this.controls.custom_display_input_enabled.value
        ? this.controls.custom_display_input.value
        : undefined,
      subtitle_field: !this.controls.subtitle_input_enabled.value ? this.controls.subtitle_field.value : undefined,
      subtitle_input: this.controls.subtitle_input_enabled.value ? this.controls.subtitle_input.value : undefined,
      icon_field: !this.controls.icon_input_enabled.value ? this.controls.icon_field.value : undefined,
      icon_input: this.controls.icon_input_enabled.value ? this.controls.icon_input.value : undefined,
      create_button: this.controls.create_button.value,
      inputs: this.controls.inputs.value ? this.controls.inputs.value.map(item => item.serialize()) : []
    };

    if (this.isPrimaryKeyTransformerChanged()) {
      params['foreign_key_transformer'] = this.controls.foreign_key_transformer.value;
    }

    this.control.patchValue({
      ...this.control.value,
      ...params
    });
  }
}
