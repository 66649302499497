import { Injectable, Injector } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { CalendarSettings } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DisplayField, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { View } from '@modules/views';
import { controlValue, generateAlphanumeric, isSet } from '@shared';

import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { ListElementStylesControl } from '../../styles-list-element-edit/list-element-styles.control';
import {
  CustomizeBarListLayoutSettingsForm,
  CustomizeListOptions,
  CustomizeListResult,
  validateAction,
  validateActions
} from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';

@Injectable()
export class CustomizeBarCalendarSettingsForm extends CustomizeBarListLayoutSettingsForm<CalendarSettings> {
  controls: {
    header: FormControl;
    title: FieldInputControl;
    calendar_date_field: FormControl;
    calendar_display1_field: FormControl;
    calendar_display2_field: FormControl;
    calendar_display3_field: FormControl;
    data_source: ListModelDescriptionDataSourceControl;
    card_view_unique_name: FormControl;
    card_view: FormControl;
    card_view_mappings: FormControl;
    search_enabled: FormControl;
    search_live: FormControl;
    group_enabled: FormControl;
    group_resource: FormControl;
    group_model: FormControl;
    group_query: FormControl;
    card_click_action: FormControl;
    actions: FormControl;
    model_actions: FormControl;
    custom_actions: ActionOutputFormGroup;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    multiple_selection: FormControl;
    display_filters: FormControl;
    filter_fields: OptionEnabledArray;
    visible_input: FieldInputControl;
    name: FormControl;
    tooltip: FormControl;

    element_styles: ListElementStylesControl;
  };

  ignoreSubmitControls: AbstractControl[] = [this.controls.card_view];
  submitLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private modelOptionsSource: ModelOptionsSource,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private notificationService: NotificationService,
    private injector: Injector,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    modelDescriptionStore: ModelDescriptionStore,
    actionService: ActionService,
    elementConfigurationService: ElementConfigurationService,
    dataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      resourceControllerService,
      modelDescriptionStore,
      actionService,
      elementConfigurationService,
      {
        header: new FormControl(true),
        title: new FieldInputControl({ name: 'value' }),
        calendar_date_field: new FormControl('', Validators.required),
        calendar_display1_field: new FormControl(''),
        calendar_display2_field: new FormControl(''),
        calendar_display3_field: new FormControl(''),
        data_source: dataSourceControl,
        card_view_unique_name: new FormControl(null),
        card_view: new FormControl(null),
        card_view_mappings: new FormControl([]),
        search_enabled: new FormControl(true),
        search_live: new FormControl(true),
        group_enabled: new FormControl(false),
        group_resource: new FormControl(''),
        group_model: new FormControl(undefined),
        group_query: new FormControl(undefined),
        card_click_action: new FormControl(undefined, undefined, validateAction),
        actions: new FormControl([], undefined, validateActions),
        model_actions: new FormControl([], undefined, validateActions),
        custom_actions: new ActionOutputFormGroup(elementConfigurationService),
        sorting_field: new FormControl(undefined),
        sorting_asc: new FormControl(true),
        multiple_selection: new FormControl(false),
        display_filters: new FormControl(true),
        filter_fields: new OptionEnabledArray([]),
        visible_input: new FieldInputControl({ name: 'value' }),
        name: new FormControl(''),
        tooltip: new FormControl(''),

        element_styles: new ListElementStylesControl(injector)
      }
    );
    dataSourceControl.setRequired(true);
  }

  init(options: CustomizeListOptions<CalendarSettings>): Observable<void> {
    this.settings = options.settings;
    this.pageUid = options.pageUid;
    this.elementUid = options.elementUid;
    this.options = options;

    const customView$ = isSet(options.settings.cardCustomView)
      ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
      : of(undefined);

    return customView$.pipe(
      tap(customView => {
        const value = {
          header: options.settings.header,
          title: options.settings.titleInput ? options.settings.titleInput.serialize() : {},
          card_view_unique_name: options.settings.cardCustomView,
          card_view: customView ? customView.view : null,
          card_view_mappings: options.settings.cardCustomViewMappings,
          calendar_date_field: options.settings.dateField,
          search_enabled: options.settings.searchEnabled,
          search_live: options.settings.searchLive,
          group_enabled: !!options.settings.groupQuery,
          group_resource: options.settings.groupResource,
          group_model: options.settings.groupQuery
            ? {
                queryType: options.settings.groupQuery.queryType,
                model:
                  options.settings.groupQuery.queryType == QueryType.Simple
                    ? options.settings.groupQuery.simpleQuery.model
                    : undefined
              }
            : undefined,
          group_query: options.settings.groupQuery,
          card_click_action: options.settings.cardClickAction,
          actions: options.settings.actions,
          model_actions: options.settings.modelActions,
          sorting_field: options.settings.sortingField || null,
          sorting_asc: options.settings.sortingAsc,
          multiple_selection: options.settings.multipleSelection,
          display_filters: options.settings.displayFilters,
          filter_fields: options.settings.filterFields.map(item => {
            return {
              name: item.name,
              enabled: true
            };
          }),
          visible_input: options.visibleInput ? options.visibleInput.serialize() : {},
          tooltip: options.settings.tooltip
        };

        if (options.nameEditable) {
          value['name'] = options.name;
        }

        this.patchValue(value, { emitEvent: false });

        this.controls.data_source.deserialize(options.settings.dataSource);
        this.controls.custom_actions.deserialize(
          customView && customView.view ? customView.view.actions : [],
          options.settings.customActions
        );

        if (options.elementStylesEditable && options.elementStyles) {
          this.controls.element_styles.deserialize(options.elementStyles);
        }

        this.trackChanges(options);

        if (!options.firstInit) {
          this.markAsDirty();
        }
      })
    );
  }

  trackChanges(options: CustomizeListOptions<CalendarSettings>) {
    super.trackChanges(options);

    combineLatest(
      controlValue<DisplayField[]>(this.controls.data_source.controls.columns),
      this.controls.data_source.getModelDescription$(),
      this.controls.data_source.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of([]);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      )
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([columns, modelDescription, modelOptions]) => {
        const columnNames = modelDescription ? modelOptions.map(item => item.name) : columns.map(item => item.name);
        const modelId = modelDescription ? modelDescription.modelId : null;
        const filterFieldsSourceChanged = this.controls.filter_fields.isSourceSet()
          ? !this.controls.filter_fields.isSource(modelId)
          : false;

        this.controls.filter_fields.syncControls(columnNames, { source: modelId });

        if (this.controls.display_filters.value && this.controls.filter_fields.isAllDisabled()) {
          this.controls.filter_fields.enableDefault();
        } else if (this.controls.display_filters.value && filterFieldsSourceChanged) {
          this.controls.filter_fields.enableDefault();
        }
      });

    this.controls.card_view.valueChanges
      .pipe(
        debounceTime(60),
        switchMap(value => {
          this.submitLoading$.next(true);

          return this.submitCardView(this.controls.card_view_unique_name.value, value);
        }),
        tap(uniqueName => {
          this.controls.card_view_unique_name.patchValue(uniqueName);

          this.submitLoading$.next(false);
        }),
        catchError(error => {
          this.submitLoading$.next(false);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }

          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getCardView(): View {
    return this.controls.card_view.value;
  }

  isConfigured(instance: CalendarSettings): Observable<boolean> {
    return this.elementConfigurationService.isListCalendarConfigured(instance, { restrictDemo: true });
  }

  submitCardView(uniqueName: string, view?: View): Observable<string> {
    if (!view) {
      return of(undefined);
    }

    const customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);

    return customView$.pipe(
      switchMap(customView => {
        if (customView) {
          const instance = cloneDeep(customView);
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        } else {
          const instance = new CustomView();
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.uniqueName =
            isSet(this.pageUid) && isSet(this.elementUid)
              ? [CustomViewType.ListItem, this.pageUid, this.elementUid].join('.')
              : [CustomViewType.ListItem, generateAlphanumeric(8, { letterFirst: true })].join('.');
          instance.viewType = CustomViewType.ListItem;
          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        }
      }),
      delayWhen(() => this.customViewsStore.getFirst(true)),
      map(result => result.uniqueName)
    );
  }

  submit(): CustomizeListResult<CalendarSettings> {
    const instance: CalendarSettings = cloneDeep(this.settings);

    instance.dateField = this.controls.calendar_date_field.value;

    instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.cardCustomView = this.controls.card_view_unique_name.value;
    instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
    instance.searchEnabled = this.controls.search_enabled.value;
    instance.searchLive = this.controls.search_live.value;

    if (this.controls.group_enabled.value) {
      instance.groupResource = this.controls.group_resource.value;
      instance.groupQuery = this.controls.group_query.value;
    } else {
      instance.groupResource = undefined;
      instance.groupQuery = undefined;
    }

    if (this.controls.card_click_action.value) {
      instance.cardClickAction = this.controls.card_click_action.value;
    } else {
      instance.cardClickAction = undefined;
    }

    instance.actions = this.controls.actions.value;
    instance.modelActions = this.controls.model_actions.value;
    instance.customActions = this.controls.custom_actions.serialize();

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;
    instance.multipleSelection = this.controls.multiple_selection.value;
    instance.filterFields = this.controls.filter_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
    instance.header =
      (instance.titleInput && instance.titleInput.isSet()) ||
      !!instance.searchEnabled ||
      !!instance.displayFilters ||
      (instance.actions && instance.actions.length > 0);
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;

    return {
      settings: instance,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      name: this.controls.name.value,
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
