import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { MarginControl, TabsAppearance, TabsLayoutElementItem, TabsOrientation, TabsStyle } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

import { TabsLayoutItemArray } from './tabs-layout-item.array';

@Injectable()
export class CustomizeBarTabsEditForm extends FormGroup {
  controls: {
    title: FormControl;
    items: TabsLayoutItemArray;
    orientation: FormControl;
    appearance: FormControl;
    style: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
    load_invisible: FormControl;
    load_on_show: FormControl;
  };

  element: TabsLayoutElementItem;
  styleOptions = [
    {
      value: TabsStyle.Wrap,
      name: 'Wrap',
      image: 'tabs-style-wrap'
    },
    {
      value: TabsStyle.Background,
      name: 'Background',
      image: 'tabs-style-background'
    }
  ];

  constructor() {
    super({
      title: new FormControl(''),
      items: new TabsLayoutItemArray([]),
      orientation: new FormControl(TabsOrientation.Horizontal),
      appearance: new FormControl(TabsAppearance.Link),
      style: new FormControl(TabsStyle.Wrap),
      visible_input: new FieldInputControl({ name: 'value' }),
      margin: new MarginControl(),
      load_invisible: new FormControl(false),
      load_on_show: new FormControl(false)
    });
  }

  init(element: TabsLayoutElementItem) {
    this.element = element;

    this.controls.title.patchValue(element.name ? element.name : 'Tabs');
    this.controls.orientation.patchValue(element.orientation || TabsOrientation.Horizontal);
    this.controls.appearance.patchValue(element.appearance || TabsAppearance.Link);
    this.controls.style.patchValue(element.style || TabsStyle.Wrap);
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});
    this.controls.margin.patchValue(element.margin);
    this.controls.load_invisible.patchValue(element.loadInvisible);
    this.controls.load_on_show.patchValue(element.loadOnShow);

    this.controls.items.deserialize(element.items);

    this.markAsPristine();
  }

  submit(): TabsLayoutElementItem {
    const instance = cloneDeep(this.element) as TabsLayoutElementItem;

    instance.name = this.controls.title.value;
    instance.items = this.controls.items.serialize();
    instance.orientation = this.controls.orientation.value;
    instance.appearance = this.controls.appearance.value;
    instance.style = this.controls.style.value;
    instance.loadOnShow = this.controls.load_on_show.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.loadInvisible = this.controls.load_invisible.value;
    instance.margin = this.controls.margin.value;

    return instance;
  }
}
