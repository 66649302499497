var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { localize } from '@common/localize';
import { AggregateFunc } from '@modules/charts';
import { ElementActions, ElementActionsPosition } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AggregateDisplayField, DefaultType, FieldType, Input, LookupDisplayField, validateValidatorParams, ValidatorType } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelFlexField, traverseModelPath } from '@modules/models';
import { FieldInputControl } from '@modules/parameters';
import { CurrentProjectStore } from '@modules/projects';
import { controlValid, controlValue, isSet } from '@shared';
import { FieldElementStylesControl } from '../styles-field-element-edit/field-element-styles.control';
export function isRegexValidator() {
    return function (control) {
        var regex;
        try {
            regex = new RegExp(control.value);
        }
        catch (e) {
            return { local: ['Incorrect regular expression'] };
        }
        if (!regex) {
            return { local: ['Incorrect regular expression'] };
        }
        return null;
    };
}
export function isNumberValidator() {
    return function (control) {
        if (control.value === null) {
            return null;
        }
        var isNumber = /^[0-9]*$/.test(control.value);
        if (!isNumber) {
            return { local: ['Is not a number'] };
        }
        return null;
    };
}
export function validateValidatorType() {
    return function (control) {
        if (!control.value) {
            return;
        }
        var parent = control.parent;
        if (!parent) {
            return;
        }
        if (parent.options.configurable.editable) {
            var params = parent.getValidatorParams();
            if (!validateValidatorParams(control.value, params)) {
                return { local: ['Is not configured'] };
            }
        }
    };
}
export function validateActions() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return of(null);
        }
        if (!control.value || !control.value.length) {
            return of(null);
        }
        return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
            if (result.some(function (configured) { return !configured; })) {
                return { required: true };
            }
        }));
    };
}
var CustomizeBarColumnEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarColumnEditForm, _super);
    function CustomizeBarColumnEditForm(formUtils, currentProjectStore, modelDescriptionService, modelDescriptionStore, fb, elementConfigurationService, injector) {
        var _this = _super.call(this, {
            verbose_name: new FormControl(''),
            label_additional: new FormControl(''),
            description: new FormControl(''),
            field: new FormControl('', Validators.required),
            required: new FormControl(false),
            editable: new FormControl(false),
            filterable: new FormControl(false),
            sortable: new FormControl(false),
            placeholder: new FormControl(''),
            actions: new FormControl([], undefined, validateActions()),
            top_actions: new FormControl([], undefined, validateActions()),
            bottom_actions: new FormControl([], undefined, validateActions()),
            on_change_actions: new FormControl([], undefined, validateActions()),
            default_type: new FormControl(''),
            default_value: new FormControl(''),
            params: new FormControl(''),
            value_input: new FieldInputControl({ name: 'value' }),
            validator_type: new FormControl(null, validateValidatorType()),
            validator_custom: new FormControl('', isRegexValidator()),
            validator_custom_input: new FieldInputControl({ name: 'value' }),
            validator_error: new FormControl(''),
            validator_length: new FormControl('', isNumberValidator()),
            validator_min_length: new FormControl('', isNumberValidator()),
            validator_max_length: new FormControl('', isNumberValidator()),
            validator_min_value: new FormControl('', isNumberValidator()),
            validator_max_value: new FormControl('', isNumberValidator()),
            visible_input: new FieldInputControl({ name: 'value' }),
            disable_input: new FieldInputControl({ name: 'value' }),
            lookup_path: new FormControl(),
            aggregate_path: new FormControl(),
            aggregate_func: new FormControl(),
            aggregate_column: new FormControl(),
            reset_enabled: new FormControl(false),
            title: new FormControl(''),
            tooltip: new FormControl(''),
            element_styles: new FieldElementStylesControl(injector)
        }) || this;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.modelDescriptionService = modelDescriptionService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.fb = fb;
        _this.elementConfigurationService = elementConfigurationService;
        _this.injector = injector;
        _this.editableField = false;
        _this.filterableField = false;
        _this.modelField = false;
        _this.defaultTypeOptions = [
            { value: undefined, name: 'None' },
            { value: DefaultType.Value, name: 'Fixed value' },
            { value: DefaultType.DatetimeNow, name: 'Current time' },
            { value: DefaultType.UUID, name: 'UUID' }
        ];
        _this.validatorOptions = [
            { option: { name: 'No validation', value: null } },
            { option: { name: 'Email', value: ValidatorType.Email, icon: 'email' } },
            { option: { name: 'Phone', value: ValidatorType.Phone, icon: 'phone' } },
            { option: { name: 'Text length', value: ValidatorType.LengthRange, icon: 'text' } },
            { option: { name: 'Number in range', value: ValidatorType.ValueRange, icon: 'number' } },
            { option: { name: 'Use Regular expression', value: ValidatorType.Custom, icon: 'one_of' }, orange: true },
            { option: { name: 'Use Formula/JavaScript', value: ValidatorType.CustomInput, icon: 'function' }, orange: true }
        ];
        _this.aggregateFuncOptions = [
            { value: AggregateFunc.Count, name: 'Number of records', valueDisplay: function () { return "Number of records"; } },
            { value: AggregateFunc.Sum, name: 'Sum of field', hasField: true, valueDisplay: function (field) { return "Sum of " + field; } },
            {
                value: AggregateFunc.Min,
                name: 'Minimum of field',
                hasField: true,
                valueDisplay: function (field) { return "Minimum of " + field; }
            },
            {
                value: AggregateFunc.Max,
                name: 'Maximum of field',
                hasField: true,
                valueDisplay: function (field) { return "Maximum of " + field; }
            },
            { value: AggregateFunc.Avg, name: 'Average of field', hasField: true, valueDisplay: function (field) { return "Average of " + field; } }
        ];
        merge.apply(void 0, [
            _this.controls.validator_custom,
            _this.controls.validator_custom_input,
            _this.controls.validator_length,
            _this.controls.validator_min_length,
            _this.controls.validator_max_length,
            _this.controls.validator_min_value,
            _this.controls.validator_max_value
        ].map(function (item) { return item.valueChanges; })).pipe(delay(0), untilDestroyed(_this))
            .subscribe(function () {
            _this.controls.validator_type.updateValueAndValidity();
        });
        return _this;
    }
    CustomizeBarColumnEditForm.prototype.ngOnDestroy = function () { };
    CustomizeBarColumnEditForm.prototype.init = function (options) {
        var _this = this;
        this.options = options;
        var value = __assign({}, (options.field
            ? {
                verbose_name: options.field.verboseName,
                field: options.field.field,
                params: options.field.params,
                description: options.field.description
            }
            : {}), (options.configurable.labelAdditional ? { label_additional: options.labelAdditional } : {}), (options.configurable.visible
            ? { visible_input: options.visibleInput ? options.visibleInput.serialize() : {} }
            : {}), (options.configurable.disable
            ? { disable_input: options.disableInput ? options.disableInput.serialize() : {} }
            : {}));
        if (options.configurable.value) {
            var flexField = options.field;
            if (flexField && flexField.valueInput) {
                value['value_input'] = flexField.valueInput ? flexField.valueInput.serialize() : {};
            }
        }
        if (options.configurable.editable) {
            var editableField = options.field;
            var validatorParams = editableField.validatorParams || {};
            if (editableField) {
                value = assign(value, {
                    editable: editableField.editable,
                    required: !!editableField.required,
                    default_type: editableField.defaultType,
                    default_value: editableField.defaultValue,
                    placeholder: editableField.placeholder,
                    validator_type: editableField.validatorType ? editableField.validatorType : null,
                    validator_custom: editableField.validatorType === ValidatorType.Custom ? validatorParams['regex'] : null,
                    validator_custom_input: editableField.validatorType === ValidatorType.CustomInput ? validatorParams['input'] : {},
                    validator_error: this.isValidatorTypeHasError(editableField.validatorType) ? validatorParams['error'] : null,
                    validator_length: isSet(validatorParams['validator_length']) ? validatorParams['validator_length'] : null,
                    validator_min_length: isSet(validatorParams['validator_min_length'])
                        ? validatorParams['validator_min_length']
                        : null,
                    validator_max_length: isSet(validatorParams['validator_max_length'])
                        ? validatorParams['validator_max_length']
                        : null,
                    validator_min_value: isSet(validatorParams['validator_min_value'])
                        ? validatorParams['validator_min_value']
                        : null,
                    validator_max_value: isSet(validatorParams['validator_max_value'])
                        ? validatorParams['validator_max_value']
                        : null,
                    reset_enabled: editableField.resetEnabled
                });
            }
            this.editableField = true;
        }
        if (options.configurable.sortable) {
            var filterableField = options.field;
            if (filterableField) {
                value = assign(value, {
                    filterable: filterableField.filterable,
                    sortable: filterableField.sortable
                });
            }
            this.filterableField = true;
        }
        if (options.configurable.action) {
            value['actions'] = options.actions || [];
        }
        if (options.configurable.elementActions) {
            var topActions = (options.elementActions || []).find(function (item) { return item.position == ElementActionsPosition.Top; });
            var bottomActions = (options.elementActions || []).find(function (item) { return item.position == ElementActionsPosition.Bottom; });
            value['top_actions'] = topActions ? topActions.actions : [];
            value['bottom_actions'] = bottomActions ? bottomActions.actions : [];
        }
        if (options.configurable.onChangeActions) {
            value['on_change_actions'] = options.onChangeActions || [];
        }
        if (options.field instanceof ModelDbField || options.field instanceof ModelFlexField) {
            //   value = assign(value, {
            //     filterable: field.filterable,
            //     sortable: field.sortable
            //   });
            this.modelField = true;
        }
        // if (field instanceof ModelDbField) {
        //   value = assign(value, {
        //     editable: field.editable
        //   });
        //   this.modelDbField = true;
        // }
        if (options.titleEditable) {
            value['title'] = options.title;
        }
        if (options.tooltipEditable) {
            value['tooltip'] = options.tooltip;
        }
        if (options.configurable.lookup) {
            var lookupField = options.field;
            if (lookupField) {
                value['lookup_path'] = lookupField.path;
            }
        }
        if (options.configurable.aggregate) {
            var aggregateField = options.field;
            if (aggregateField) {
                value['aggregate_path'] = aggregateField.path;
                value['aggregate_func'] = aggregateField.func;
                value['aggregate_column'] = aggregateField.column;
            }
        }
        this.patchValue(value);
        if (options.configurable.elementStyles && options.elementStyles) {
            this.controls.element_styles.deserialize(options.elementStyles);
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
        if (options.titleEditable && options.titleAutoUpdate) {
            this.controls.verbose_name.valueChanges.subscribe(function (item) {
                if (options.titleCleanValue) {
                    item = options.titleCleanValue(item);
                }
                _this.controls.title.patchValue(item);
            });
        }
        merge.apply(void 0, [
            this.controls.validator_custom,
            this.controls.validator_custom_input,
            this.controls.validator_length,
            this.controls.validator_min_length,
            this.controls.validator_max_length,
            this.controls.validator_min_value,
            this.controls.validator_max_value
        ].map(function (item) { return item.valueChanges; })).pipe(delay(0), untilDestroyed(this))
            .subscribe(function () {
            _this.controls.validator_type.updateValueAndValidity();
        });
        if (options.configurable.labelAdditional) {
            controlValue(this.controls.required)
                .pipe(untilDestroyed(this))
                .subscribe(function (required) {
                var optionalLabel = "(" + localize('optional') + ")";
                if (!required && !isSet(_this.controls.label_additional.value)) {
                    _this.controls.label_additional.patchValue(optionalLabel);
                }
                else if (required && _this.controls.label_additional.value == optionalLabel) {
                    _this.controls.label_additional.patchValue('');
                }
            });
        }
    };
    CustomizeBarColumnEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isColumnConfigured(instance, {
            editable: this.options.configurable.editable,
            restrictDemo: true
        });
    };
    CustomizeBarColumnEditForm.prototype.isResetEnabledSupported = function () {
        return [
            FieldType.Text,
            FieldType.Password,
            FieldType.Number,
            FieldType.URL,
            FieldType.DateTime,
            FieldType.Time,
            FieldType.Select,
            FieldType.MultipleSelect,
            FieldType.RelatedModel
        ].includes(this.controls['field'].value);
    };
    CustomizeBarColumnEditForm.prototype.isValidatorTypeHasOption = function (type) {
        return [
            ValidatorType.Length,
            ValidatorType.MaxLength,
            ValidatorType.MinLength,
            ValidatorType.LengthRange,
            ValidatorType.ValueRange,
            ValidatorType.Custom,
            ValidatorType.CustomInput
        ].includes(type);
    };
    CustomizeBarColumnEditForm.prototype.isValidatorTypeHasError = function (type) {
        return [ValidatorType.Custom, ValidatorType.CustomInput].includes(type);
    };
    CustomizeBarColumnEditForm.prototype.getValidatorParams = function () {
        return {
            regex: this.controls.validator_custom.value,
            input: this.controls.validator_custom_input.value,
            error: this.controls.validator_error.value,
            validator_length: this.controls.validator_length.value,
            validator_min_length: this.controls.validator_min_length.value,
            validator_max_length: this.controls.validator_max_length.value,
            validator_min_value: this.controls.validator_min_value.value,
            validator_max_value: this.controls.validator_max_value.value
        };
    };
    CustomizeBarColumnEditForm.prototype.getLookupPath$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.lookup_path), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var path = _a[0], modelDescriptions = _a[1];
            var result = traverseModelPath(_this.options.modelDescription, path, modelDescriptions);
            return result ? result.map(function (item) { return item.verboseName; }) : undefined;
        }));
    };
    CustomizeBarColumnEditForm.prototype.getAggregatePath$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.aggregate_path), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var path = _a[0], modelDescriptions = _a[1];
            var result = traverseModelPath(_this.options.modelDescription, path, modelDescriptions);
            return result ? result.map(function (item) { return item.verboseName; }) : undefined;
        }));
    };
    CustomizeBarColumnEditForm.prototype.getAggregateFuncLabel$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.aggregate_func), controlValue(this.controls.aggregate_column)).pipe(map(function (_a) {
            var func = _a[0], column = _a[1];
            var funcOption = _this.aggregateFuncOptions.find(function (item) { return item.value == func; });
            if (!isSet(func) && isSet(column)) {
                return "Pre-aggregated Field - " + column;
            }
            else if (funcOption) {
                return funcOption.valueDisplay(column);
            }
        }));
    };
    CustomizeBarColumnEditForm.prototype.getAggregateColumnOptions$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.aggregate_path), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var path = _a[0], modelDescriptions = _a[1];
            var modelPath = traverseModelPath(_this.options.modelDescription, path, modelDescriptions);
            var target = modelPath ? modelPath[path.length - 1] : undefined;
            if (!target || !target.relatedModel) {
                return [];
            }
            return target.relatedModel.dbFields.map(function (item) {
                return {
                    value: item.name,
                    name: item.verboseName || item.name,
                    icon: item.fieldDescription.icon
                };
            });
        }));
    };
    CustomizeBarColumnEditForm.prototype.controlsValid$ = function (controls) {
        if (!controls.length) {
            return of(true);
        }
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); })
        // debounceTime(60) TODO: Too long wait with debounceTime
        );
    };
    CustomizeBarColumnEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$((this.options.configurable.action ? [this.controls.actions] : []).concat((this.options.configurable.elementActions ? [this.controls.top_actions, this.controls.bottom_actions] : []), (this.options.configurable.onChangeActions ? [this.controls.on_change_actions] : [])));
    };
    CustomizeBarColumnEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = this.options.field ? cloneDeep(this.options.field) : {};
        instance.verboseName = value.verbose_name;
        instance.field = value.field;
        if (this.options.configurable.value) {
            instance.valueInput = value.value_input ? new Input().deserialize(value.value_input) : undefined;
        }
        if (this.editableField) {
            instance.editable = value.editable;
            instance.required = value.required;
            instance.defaultType = value.default_type;
            instance.defaultValue = value.default_value;
            instance.placeholder = value.placeholder;
            instance.validatorType = value.validator_type;
            instance.validatorParams = this.getValidatorParams();
            instance.resetEnabled = value.reset_enabled;
        }
        if (this.filterableField) {
            instance.filterable = value.filterable;
            instance.sortable = value.sortable;
        }
        if (this.modelField) {
            //   instance['filterable'] = value.filterable;
            //   instance['sortable'] = value.sortable;
            instance['updateFieldDescription']();
        }
        // if (this.modelDbField) {
        //   instance['editable'] = value.editable;
        // }
        instance.params = value.params;
        instance.description = value.description;
        if (this.options.configurable.lookup && instance instanceof LookupDisplayField) {
            instance.path = value.lookup_path;
        }
        if (this.options.configurable.aggregate && instance instanceof AggregateDisplayField) {
            instance.path = value.aggregate_path;
            instance.func = value.aggregate_func;
            instance.column = value.aggregate_column;
        }
        var elementActions = [];
        if (value.top_actions.length) {
            elementActions.push(new ElementActions({
                position: ElementActionsPosition.Top,
                actions: value.top_actions
            }));
        }
        if (value.bottom_actions.length) {
            elementActions.push(new ElementActions({
                position: ElementActionsPosition.Bottom,
                actions: value.bottom_actions
            }));
        }
        return __assign({ field: instance, actions: value.actions, elementActions: elementActions, onChangeActions: value.on_change_actions, labelAdditional: this.controls.label_additional.value, visibleInput: value.visible_input ? new Input().deserialize(value.visible_input) : undefined, disableInput: value.disable_input ? new Input().deserialize(value.disable_input) : undefined, title: value.title, tooltip: isSet(value.tooltip) ? value.tooltip.trim() : undefined }, (this.options.configurable.elementStyles && {
            elementStyles: this.controls.element_styles.serialize()
        }));
    };
    return CustomizeBarColumnEditForm;
}(FormGroup));
export { CustomizeBarColumnEditForm };
