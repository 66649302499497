import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';

import { ActionDescription, ActionItem, ActionType, SegueType } from '@modules/actions';
import { ChartWidget, ValueWidget } from '@modules/dashboard';
import { DataSource, DataSourceType } from '@modules/data-sources';
import {
  BaseField,
  FieldType,
  FormFieldSerialized,
  InputValueType,
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams
} from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { ActionQuery, ListModelDescriptionQuery, Query, QueryType } from '@modules/queries';
import { getFontFamilyVariable } from '@modules/styles';
import { ActionWorkflowStep, ConditionWorkflowStep, ForkWorkflowStep, IteratorWorkflowStep } from '@modules/workflow';
import { forceString, isSet, splitmax } from '@shared';

import { CalendarSettings } from '../data/calendar-settings';
import { CarouselSettings } from '../data/carousel-settings';
import { AccordionElementItem } from '../data/elements/items/accordion';
import { AccordionItem } from '../data/elements/items/accordion-item';
import { ActionElementItem } from '../data/elements/items/action';
import { ActionDropdownElementItem } from '../data/elements/items/action-dropdown';
import { ActionGroupElementItem } from '../data/elements/items/action-group';
import { AlertElementItem } from '../data/elements/items/alert';
import { ElementItem } from '../data/elements/items/base';
import { CardLayoutElementItem } from '../data/elements/items/card-layout';
import { CollapseElementItem } from '../data/elements/items/collapse';
import { ColumnsLayoutElementItem } from '../data/elements/items/columns-layout';
import { DateRangeElementItem } from '../data/elements/items/date-range';
import { FieldElementItem } from '../data/elements/items/field';
import { FilterElementItem } from '../data/elements/items/filter';
import { FormElementItem } from '../data/elements/items/form-element';
import { ListElementItem } from '../data/elements/items/list-element';
import { ModelElementItem } from '../data/elements/items/model-element';
import { RangeSliderElementItem } from '../data/elements/items/range-slider';
import { SectionLayoutElementItem } from '../data/elements/items/section-layout';
import { StackLayoutElementItem } from '../data/elements/items/stack-layout';
import { TabsLayoutElementItem } from '../data/elements/items/tabs-layout';
import { WidgetElementItem } from '../data/elements/items/widget';
import { GridSettings } from '../data/grid-settings';
import { KanbanBoardSettings } from '../data/kanban-board-settings';
import { MapSettings } from '../data/map-settings';
import { PopupSettings } from '../data/popup';
import { TableSettings } from '../data/table-settings';
import { TextStyle } from '../data/text-style';
import { TimelineSettings } from '../data/timeline-settings';
import { ViewContext } from '../data/view-context';
import { ChangeViewSettings } from '../data/view-settings/change';
import { CustomViewSettings } from '../data/view-settings/custom';
import { ListViewSettings } from '../data/view-settings/list';

export function traverseElementItems(item: any, process: (item: any) => boolean | void) {
  if (process(item) === false) {
    return;
  }

  if (item === null || item === undefined) {
    return;
  }

  if (isArray(item)) {
    item.forEach(child => {
      traverseElementItems(child, process);
    });
  } else if (item instanceof ListViewSettings) {
    item.layouts.forEach(child => {
      traverseElementItems(child, process);
    });
  } else if (item instanceof ChangeViewSettings) {
    item.elements.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof CustomViewSettings) {
    item.queries.forEach(child => {
      traverseElementItems(child.detailDataSource, process);
      traverseElementItems(child.listDataSource, process);
    });
    item.openActions.forEach(child => {
      traverseElementItems(child, process);
    });
    item.elements.forEach(child => traverseElementItems(child, process));

    item.popups.forEach(popup => {
      traverseElementItems(popup, process);
    });
  } else if (item instanceof PopupSettings) {
    item.elements.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof SectionLayoutElementItem) {
    item.children.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof TabsLayoutElementItem) {
    item.items.forEach(tab => {
      tab.children.forEach(child => {
        traverseElementItems(child, process);
      });
    });
  } else if (item instanceof ColumnsLayoutElementItem) {
    item.columns.forEach(column => {
      column.children.forEach(child => {
        traverseElementItems(child, process);
      });
    });
  } else if (item instanceof CardLayoutElementItem) {
    item.children.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof StackLayoutElementItem) {
    item.children.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof CollapseElementItem) {
    traverseElementItems(item.titleStyle, process);

    item.children.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof AccordionElementItem) {
    item.items.forEach(section => {
      section.children.forEach(child => traverseElementItems(child, process));
    });
  } else if (item instanceof AccordionItem) {
    traverseElementItems(item.titleStyle, process);
  } else if (item instanceof FormElementItem) {
    traverseElementItems(item.submitAction, process);
    item.children.forEach(child => traverseElementItems(child, process));
  } else if (item instanceof ListElementItem) {
    item.layouts.forEach(child => {
      traverseElementItems(child, process);
    });
  } else if (item instanceof WidgetElementItem) {
    traverseElementItems(item.widget, process);
  } else if (item instanceof ValueWidget) {
    traverseElementItems(item.titleStyle, process);
    traverseElementItems(item.valueStyle, process);

    if (item.dataSource) {
      traverseElementItems(item.dataSource, process);
    }
  } else if (item instanceof ChartWidget) {
    traverseElementItems(item.titleStyle, process);

    item.datasets.forEach(subItem => {
      if (subItem.dataSource) {
        traverseElementItems(subItem.dataSource, process);
      }
    });
  } else if (item instanceof FieldElementItem) {
    traverseElementItems(item.labelStyle, process);
    traverseElementItems(item.labelAdditionalStyle, process);
    traverseElementItems(item.textStyle, process);
    traverseElementItems(item.hoverTextStyle, process);
    traverseElementItems(item.focusTextStyle, process);
    traverseElementItems(item.errorTextStyle, process);

    item.elementActions.forEach(elementActions => {
      elementActions.actions.forEach(child => {
        traverseElementItems(child, process);
      });
    });

    item.onChangeActions.forEach(child => {
      traverseElementItems(child, process);
    });
  } else if (item instanceof ModelElementItem) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.titleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof TableSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.rowClickAction, process);
    traverseElementItems(item.rowActions, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.titleStyle, process);
    traverseElementItems(item.groupTitleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof MapSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.cardClickAction, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.titleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof KanbanBoardSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.cardClickAction, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.cardColumnChangeAction, process);
    traverseElementItems(item.cardOrderChangeAction, process);
    traverseElementItems(item.titleStyle, process);
    traverseElementItems(item.stageTitleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof CalendarSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.cardClickAction, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.titleStyle, process);
  } else if (item instanceof GridSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.cardClickAction, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.titleStyle, process);
    traverseElementItems(item.groupTitleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof CarouselSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.cardClickAction, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.titleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof TimelineSettings) {
    traverseElementItems(item.dataSource, process);
    traverseElementItems(item.actions, process);
    traverseElementItems(item.cardClickAction, process);
    traverseElementItems(item.modelActions, process);
    traverseElementItems(item.titleStyle, process);

    if (item.dataSource && item.dataSource.columns) {
      item.columnActions.forEach(columnAction => {
        columnAction.actions.forEach(action => {
          traverseElementItems(action, process);
        });
      });
    }
  } else if (item instanceof FilterElementItem) {
    item.elementInputs.forEach(child => {
      traverseElementItems(child.labelStyle, process);
      traverseElementItems(child.labelAdditionalStyle, process);
    });
  } else if (item instanceof DataSource) {
    if (item.type == DataSourceType.Workflow && item.workflow) {
      item.workflow.steps.forEach(child => {
        traverseElementItems(child, process);
      });
    }
  } else if (item instanceof ActionElementItem) {
    traverseElementItems(item.textStyle, process);
    traverseElementItems(item.hoverTextStyle, process);
    traverseElementItems(item.activeTextStyle, process);
    traverseElementItems(item.actionItem, process);
  } else if (item instanceof ActionDropdownElementItem) {
    traverseElementItems(item.actionItems, process);
  } else if (item instanceof ActionGroupElementItem) {
    traverseElementItems(item.actions, process);
  } else if (item instanceof AlertElementItem) {
    traverseElementItems(item.actions, process);
  } else if (item instanceof AccordionElementItem) {
    item.items.forEach(collapse => {
      traverseElementItems(collapse.titleStyle, process);
    });
  } else if (item instanceof CollapseElementItem) {
    traverseElementItems(item.titleStyle, process);
  } else if (item instanceof RangeSliderElementItem) {
    traverseElementItems(item.labelStyle, process);
    traverseElementItems(item.labelAdditionalStyle, process);
  } else if (item instanceof DateRangeElementItem) {
    traverseElementItems(item.labelStyle, process);
    traverseElementItems(item.labelAdditionalStyle, process);
  } else if (item instanceof ActionItem) {
    if (
      item.actionDescription &&
      item.actionDescription.type == ActionType.Workflow &&
      item.actionDescription.workflowAction &&
      item.actionDescription.workflowAction.workflow
    ) {
      item.actionDescription.workflowAction.workflow.steps.forEach(child => {
        traverseElementItems(child, process);
      });
    }

    item.onSuccessActions.forEach(child => {
      traverseElementItems(child, process);
    });

    item.onErrorActions.forEach(child => {
      traverseElementItems(child, process);
    });

    if (item.approve) {
      item.approve.onTaskCreateActions.forEach(child => {
        traverseElementItems(child, process);
      });

      item.approve.onRejectActions.forEach(child => {
        traverseElementItems(child, process);
      });
    }
  } else if (item instanceof ActionWorkflowStep) {
    traverseElementItems(item.action, process);

    if (item.resultSteps) {
      item.successSteps.forEach(step => {
        traverseElementItems(step, process);
      });

      item.errorSteps.forEach(step => {
        traverseElementItems(step, process);
      });
    }
  } else if (item instanceof ConditionWorkflowStep) {
    item.items.forEach(child => {
      child.steps.forEach(step => {
        traverseElementItems(step, process);
      });
    });

    item.elseSteps.forEach(step => {
      traverseElementItems(step, process);
    });
  } else if (item instanceof ForkWorkflowStep) {
    item.items.forEach(child => {
      child.steps.forEach(step => {
        traverseElementItems(step, process);
      });
    });
  } else if (item instanceof IteratorWorkflowStep) {
    item.steps.forEach(step => {
      traverseElementItems(step, process);
    });
  }
}

export function processElementItemColumnResources(
  item: any,
  column: BaseField,
  process: (resourceName: string, item: any) => string
) {
  // TODO: Add params interfaces
  if (column.params && column.params.hasOwnProperty('resource')) {
    column.params['resource'] = process(column.params['resource'], item);
  }

  if (column.params && column.params.hasOwnProperty('related_model')) {
    const relatedModel = column.params['related_model'];
    const parts = isSet(relatedModel) && isSet(relatedModel['model']) ? relatedModel['model'].split('.') : [];

    if (parts.length > 1) {
      const resource = process(parts[0], item);
      const model = parts[1];
      column.params['related_model'] = { model: [resource, model].join('.') };
    }
  }
}

export function processElementItemResources(root: any, process: (resourceName: string, item: any) => string) {
  traverseElementItems(root, item => {
    if (item instanceof DataSource) {
      item.queryResource = process(item.queryResource, item);
      item.columns.forEach(subItem => {
        processElementItemColumnResources(item, subItem, process);
      });
    } else if (item instanceof TableSettings) {
      // item.resource = process(item.resource, item);
      item.searchResource = process(item.searchResource, item);
      item.updateResource = process(item.updateResource, item);
    } else if (item instanceof MapSettings) {
      // item.resource = process(item.resource, item);
      item.searchResource = process(item.searchResource, item);
    } else if (item instanceof KanbanBoardSettings) {
      // item.resource = process(item.resource, item);
    } else if (item instanceof CalendarSettings) {
      // item.resource = process(item.resource, item);
      item.searchResource = process(item.searchResource, item);
      item.groupResource = process(item.groupResource, item);
    } else if (item instanceof GridSettings) {
      // item.resource = process(item.resource, item);
      item.searchResource = process(item.searchResource, item);
    } else if (item instanceof CarouselSettings) {
      // item.resource = process(item.resource, item);
      item.searchResource = process(item.searchResource, item);
    } else if (item instanceof TimelineSettings) {
      // item.resource = process(item.resource, item);
      item.searchResource = process(item.searchResource, item);
    } else if (item instanceof ValueWidget) {
      // item.resource = process(item.resource, item);

      if (item.dataSource) {
        item.dataSource.queryResource = process(item.dataSource.queryResource, item);
      }
    } else if (item instanceof ChartWidget) {
      item.datasets.forEach(subItem => {
        // subItem.resource = process(subItem.resource, item);

        if (subItem.dataSource) {
          subItem.dataSource.queryResource = process(subItem.dataSource.queryResource, item);
        }
      });
    } else if (item instanceof ActionItem) {
      if (item.sharedActionDescription) {
        const [resource, action] = splitmax(item.sharedActionDescription, '.', 2);
        item.sharedActionDescription = [process(resource, item), action].join('.');
      } else if (item && item.actionDescription) {
        item.actionDescription.resource = process(item.actionDescription.resource, item);

        if (item.actionDescription.importAction) {
          item.actionDescription.importAction.resource = process(item.actionDescription.importAction.resource, item);
        } else if (item.actionDescription.exportAction) {
          if (item.actionDescription.exportAction.dataSource) {
            item.actionDescription.exportAction.dataSource.queryResource = process(
              item.actionDescription.exportAction.dataSource.queryResource,
              item
            );
          }
        }
      }
    } else if (item instanceof ModelElementItem) {
      // item.resource = process(item.resource, item);

      if (item.dataSource) {
        item.dataSource.queryResource = process(item.dataSource.queryResource, item);
      }
    } else if (item instanceof FormElementItem) {
      if (item.getDataSource) {
        item.getDataSource.queryResource = process(item.getDataSource.queryResource, item);
      }
    } else if (item instanceof FieldElementItem) {
      if (item.settings) {
        processElementItemColumnResources(item, item.settings, process);
      }
    } else if (item instanceof ActionDescription) {
      item.resource = process(item.resource, item);
    }
  });
}

export function traverseElementItemColumnQueries(
  item: any,
  column: BaseField,
  process: (resourceName: string, query: Query, item: any) => boolean | void
) {
  // TODO: Add params interfaces
  if (column.params && column.params.hasOwnProperty('resource') && column.params.hasOwnProperty('query')) {
    const resource = column.params['resource'];
    const query = new ListModelDescriptionQuery().deserialize(column.params['query']);
    process(resource, query, item);
  }

  if (column.params && column.params.hasOwnProperty('related_model')) {
    const relatedModel = column.params['related_model'];
    const parts = isSet(relatedModel) && isSet(relatedModel['model']) ? relatedModel['model'].split('.') : [];

    if (parts.length > 1) {
      const resource = parts[0];
      const model = parts[1];
      const query = new ListModelDescriptionQuery();

      query.queryType = QueryType.Simple;
      query.simpleQuery = new query.simpleQueryClass();
      query.simpleQuery.model = model;

      process(resource, query, item);
    }
  }

  if (column.params && column.params.hasOwnProperty('structure')) {
    const structure = column.params['structure'] as JsonStructureNode;
    if (structure) {
      traverseJsonStructureNodeQueries(structure, process);
    }
  }
}

export function traverseJsonStructureNodeQueries(
  node: JsonStructureNode,
  process: (resourceName: string, query: Query, item: any) => boolean | void
) {
  if (node.type == JsonStructureNodeType.Object) {
    const params = node.params as JsonStructureObjectParams;

    (params.items || []).forEach(item => {
      traverseJsonStructureNodeQueries(item, process);
    });
  } else if (node.type == JsonStructureNodeType.Array) {
    const params = node.params as JsonStructureArrayParams;

    traverseJsonStructureNodeQueries(params.item, process);
  } else if (node.type == JsonStructureNodeType.Field) {
    const params = node.params as FormFieldSerialized;
    const field: BaseField = {
      name: params.name,
      verboseName: params.label || node.label,
      field: params.field as FieldType,
      params: params.params
    };

    traverseElementItemColumnQueries(node, field, process);
  }
}

export function traverseElementItemQueries(
  root: any,
  actions: ActionDescription[],
  process: (resourceName: string, query: Query, item: any) => boolean | void
) {
  traverseElementItems(root, obj => {
    if (obj instanceof DataSource) {
      if (obj.type == DataSourceType.Query) {
        process(obj.queryResource, obj.query, obj);
      }

      obj.columns.forEach(subItem => {
        traverseElementItemColumnQueries(obj, subItem, process);
      });
    } else if (obj instanceof TableSettings) {
      // process(item.resource, item.getQuery, item);

      if (
        obj.editingEnabled &&
        obj.dataSource &&
        obj.dataSource.type == DataSourceType.Query &&
        isSet(obj.dataSource.queryResource) &&
        obj.dataSource.query &&
        obj.dataSource.query.simpleQuery &&
        isSet(obj.dataSource.query.simpleQuery.model)
      ) {
        const modelDescription = new ModelDescription();

        modelDescription.model = obj.dataSource.query.simpleQuery.model;

        const query = new ActionQuery();
        const resource = obj.dataSource.queryResource;
        const actionName = modelDescription.autoActionUniqueName('update');

        query.queryType = QueryType.Simple;
        query.simpleQuery = new query.simpleQueryClass();
        query.simpleQuery.name = actionName;

        process(resource, query, obj);
      }
    } else if (obj instanceof MapSettings) {
      // process(item.resource, item.getQuery, item);
    } else if (obj instanceof KanbanBoardSettings) {
      // process(item.resource, item.getQuery, item);
    } else if (obj instanceof CalendarSettings) {
      // process(item.resource, item.getQuery, item);
    } else if (obj instanceof GridSettings) {
      // process(item.resource, item.getQuery, item);
    } else if (obj instanceof TimelineSettings) {
      // process(item.resource, item.getQuery, item);
    } else if (obj instanceof ValueWidget) {
      // process(item.resource, item.query, item);
    } else if (obj instanceof ChartWidget) {
      // item.datasets.forEach(subItem => {
      //   process(subItem.resource, subItem.query, item);
      // });
    } else if (obj instanceof ActionItem) {
      if (obj.sharedActionDescription) {
        const [resource, action] = splitmax(obj.sharedActionDescription, '.', 2);
        const query = new ActionQuery();

        query.queryType = QueryType.Simple;
        query.simpleQuery = new query.simpleQueryClass();
        query.simpleQuery.name = action;

        process(resource, query, obj);

        const actionDescription = actions.find(item => item.isSame(obj.sharedActionDescription));
        if (actionDescription) {
          actionDescription.actionParams
            .filter(item => {
              return obj.inputs.find(input => input.name == item.name && input.valueType == InputValueType.Prompt);
            })
            .forEach(subItem => {
              traverseElementItemColumnQueries(obj, subItem, process);
            });
        }
      } else if (obj && obj.actionDescription) {
        if (obj.actionDescription.queryAction) {
          process(obj.actionDescription.resource, obj.actionDescription.queryAction.query, obj);
        } else if (obj.actionDescription.importAction) {
          const modelDescription = new ModelDescription();

          modelDescription.model = obj.actionDescription.importAction.model;

          const query = new ActionQuery();

          query.queryType = QueryType.Simple;
          query.simpleQuery = new query.simpleQueryClass();
          query.simpleQuery.name = modelDescription.autoActions().find(action => action.name == 'create').uniqueName;

          process(obj.actionDescription.importAction.resource, query, obj);
        } else if (obj.actionDescription.exportAction) {
          if (obj.actionDescription.exportAction.dataSource) {
            process(
              obj.actionDescription.exportAction.dataSource.queryResource,
              obj.actionDescription.exportAction.dataSource.query,
              obj
            );
          }
        }

        obj.actionDescription.actionParams
          .filter(item => {
            return obj.inputs.find(input => input.name == item.name && input.valueType == InputValueType.Prompt);
          })
          .forEach(subItem => {
            traverseElementItemColumnQueries(obj, subItem, process);
          });
      }
    } else if (obj instanceof ModelElementItem) {
      // process(item.resource, item.getQuery, item);

      if (obj.dataSource) {
        process(obj.dataSource.queryResource, obj.dataSource.query, obj);
      }
    } else if (obj instanceof FormElementItem) {
      if (obj.getDataSource) {
        process(obj.getDataSource.queryResource, obj.getDataSource.query, obj);
      }
    } else if (obj instanceof FieldElementItem) {
      if (obj.settings) {
        traverseElementItemColumnQueries(obj, obj.settings, process);
      }
    } else if (obj instanceof ActionDescription) {
      if (obj.queryAction) {
        process(obj.resource, obj.queryAction.query, obj);
      }
    }
  });
}

export function traverseElementItemFonts(root: any, process: (font: string, item: any) => void) {
  traverseElementItems(root, item => {
    if (item instanceof TextStyle) {
      if (item.fontFamily && !isSet(getFontFamilyVariable(item.fontFamily))) {
        process(item.fontFamily, item);
      }
    }
  });
}

export function processElementItemNames(root: any, process: (name: string, item: ElementItem) => string) {
  traverseElementItems(root, item => {
    if (item instanceof ElementItem) {
      item.name = process(item.name, item);
    }
  });
}

export function processPopupNames(root: any, process: (name: string, item: PopupSettings) => string) {
  traverseElementItems(root, item => {
    if (item instanceof PopupSettings) {
      item.name = process(item.name, item);
    }
  });
}

export function processElementItemPageSegues(root: any, process: (page: string) => string) {
  traverseElementItems(root, item => {
    if (
      item instanceof ActionItem &&
      item.actionDescription &&
      item.actionDescription.linkAction &&
      item.actionDescription.linkAction.type == SegueType.Page
    ) {
      item.actionDescription.linkAction.page = process(item.actionDescription.linkAction.page);
    }
  });
}

export function filterElementItems<T>(root: any, filter: (item: any) => boolean): T[] {
  const result: T[] = [];

  traverseElementItems(root, item => {
    if (filter(item)) {
      result.push(item);
    }
  });

  return result;
}

export function generateElementNameInContext(
  element: ElementItem,
  context: ViewContext,
  options: { name?: string } = {}
): ElementItem {
  const allElements = context ? context.getElementItems() : [];
  const names = getElementNames(allElements, false);

  element.name = generateElementName(element, names, options.name, false, ' ');

  return element;
}

export function generateElementName(
  element: ElementItem,
  names: { [name: string]: any },
  name?: string,
  caseSensitive = true,
  numberSeparator = ''
): string {
  let defaultName = element.defaultName();
  let i = 1;
  let newName: string;

  if (element instanceof FieldElementItem) {
    element.updateFormField();

    if (element.formField && element.formField.label) {
      defaultName = element.formField.label;
    } else if (element.formField && element.formField.name) {
      defaultName = element.formField.name;
    }
  }

  if (isSet(name)) {
    defaultName = name;
  }

  do {
    newName = i > 1 ? [defaultName, i].join(numberSeparator) : defaultName;
    ++i;
  } while (names.hasOwnProperty(caseSensitive ? newName : newName.toLowerCase()));

  return newName;
}

export function generatePopupName(
  element: PopupSettings,
  names: { [name: string]: any },
  name?: string,
  caseSensitive = true,
  numberSeparator = ' '
): string {
  let defaultName = 'Modal';
  let i = 1;
  let newName: string;

  if (isSet(name)) {
    defaultName = name;
  }

  do {
    newName = i > 1 ? [defaultName, i].join(numberSeparator) : defaultName;
    ++i;
  } while (names.hasOwnProperty(caseSensitive ? newName : newName.toLowerCase()));

  return newName;
}

export function getContextElementNames(context: ViewContext): { [name: string]: ElementItem } {
  return fromPairs(context.elements.filter(item => !item.parent).map(item => [item.element.name, item.element]));
}

export function getElementNames(
  elements: ElementItem[],
  caseSensitive = true,
  filter?: (item: ElementItem) => boolean
): { [name: string]: ElementItem } {
  const names: { [name: string]: ElementItem } = {};

  processElementItemNames(elements, (name, element) => {
    if (filter && !filter(element)) {
      return name;
    }

    name = forceString(name);
    if (isSet(name) && !names.hasOwnProperty(name)) {
      const prop = caseSensitive ? name : name.toLowerCase();
      names[prop] = element;
    }

    return name;
  });

  return names;
}
export function getPopupNames(popups: PopupSettings[], caseSensitive = true): { [name: string]: PopupSettings } {
  const names: { [name: string]: PopupSettings } = {};

  processPopupNames(popups, (name, popup) => {
    name = forceString(name);
    if (isSet(name) && !names.hasOwnProperty(name)) {
      const prop = caseSensitive ? name : name.toLowerCase();
      names[prop] = popup;
    }
    return name;
  });

  return names;
}

export function validateElementNames(elements: ElementItem[]) {
  const names = getElementNames(elements);
  const renames: { [uid: string]: string } = {};

  processElementItemNames(elements, (name, element) => {
    if (!isSet(name)) {
      const newName = generateElementName(element, names, undefined, false, ' ');
      names[newName] = element;
      return newName;
    } else if (isSet(name) && names[name].uid != element.uid) {
      if (renames.hasOwnProperty(element.uid)) {
        return renames[element.uid];
      } else {
        const newName = generateElementName(element, names, undefined, false, ' ');
        names[newName] = element;
        renames[element.uid] = newName;
        return newName;
      }
    }

    return name;
  });
}

export function cleanElementName(name: string, element: ElementItem, elements: ElementItem[]) {
  const names = getElementNames(elements, false, item => item.uid != element.uid);
  return generateElementName(element, names, name, false, ' ');
}

export function generateUidAndNamesRecursive(root: any, context: ViewContext, options: { forceNewUid?: boolean } = {}) {
  const allElements = context ? context.getElementItems() : [];
  const allPopups = context && context.viewSettings instanceof CustomViewSettings ? context.viewSettings.popups : [];
  const elementNames = getElementNames(allElements);
  const popupNames = getPopupNames(allPopups);

  traverseElementItems(root, item => {
    if (item instanceof ElementItem) {
      if (!item.uid || options.forceNewUid) {
        item.generateUid();
      }
      item.name = generateElementName(item, elementNames);
      elementNames[item.name] = item;
    } else if (item instanceof PopupSettings) {
      if (!item.uid || options.forceNewUid) {
        item.generateUid();
      }
      item.name = generatePopupName(item, popupNames);
      popupNames[item.name] = item;
    }
  });
}
