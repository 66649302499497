import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { AggregateFunc } from '@modules/charts';
import { ValueWidgetDataSource } from '@modules/data-sources';
import { serializeFieldParamName } from '@modules/field-lookups';
import { Input, InputValueType } from '@modules/fields';
import { fromLegacyModel } from '@modules/models';
import { HttpQuery, QueryType, SqlQuery, ValueWidgetQuery } from '@modules/queries';

// TODO: Refactor import
import { TextStyle } from '../../../../customize/data/text-style';
import { migrateModelDescriptionDataSource } from '../../../../customize/utils/migration';

import { WidgetType } from '../widget-type';
import { registerWidgetForType } from '../widgets';
import { ChartWidgetDataset } from './chart';
import { Widget } from './widget';

// TODO: Remove
export class ValueColumnFunc {
  public column: string;
  public func: AggregateFunc;

  deserialize(data: Object): ValueColumnFunc {
    this.column = data['column'];
    this.func = data['func'];

    return this;
  }

  serialize() {
    return {
      column: this.column,
      func: this.func
    };
  }
}

export class ValueWidget extends Widget {
  public type = WidgetType.Value;
  public titleStyle: TextStyle;
  public dataSource: ValueWidgetDataSource;
  public compareName: string;
  public compareGrowthNegative = false;
  public compareDataSource: ValueWidgetDataSource;
  public chartDataset: ChartWidgetDataset;
  public prefix: string;
  public postfix: string;
  public format: string;
  public valueStyle: TextStyle;
  public icon: string;
  public showReload = true;
  public clickAction: ActionItem;

  deserialize(data: Object): ValueWidget {
    super.deserialize(data);

    if (this.params['title_style']) {
      this.titleStyle = new TextStyle().deserialize(this.params['title_style']);
    } else {
      this.titleStyle = undefined;
    }

    if (this.params['data_source']) {
      this.dataSource = new ValueWidgetDataSource().deserialize(this.params['data_source']);
    } else if (this.params['resource']) {
      // Backward compatibility
      this.dataSource = migrateModelDescriptionDataSource(ValueWidgetDataSource, ValueWidgetQuery, {
        resource: this.params['resource'],
        query: this.params['query'],
        parameters: this.params['parameters'],
        inputs: this.params['inputs'],
        columns: this.params['columns']
      });

      if (this.params['query']) {
        if (this.params['query']['func']) {
          this.dataSource.func = this.params['query']['func'];
        } else if (this.params['query']['simple_query'] && this.params['query']['simple_query']['func']) {
          // Backward compatibility
          this.dataSource.func = this.params['query']['simple_query']['func'];
        }

        if (this.params['query']['column']) {
          this.dataSource.column = this.params['query']['column'];
        } else if (this.params['query']['simple_query'] && this.params['query']['simple_query']['column']) {
          // Backward compatibility
          this.dataSource.column = this.params['query']['simple_query']['column'];
        }
      }

      if (this.params['http_query']) {
        this.dataSource.query = new ValueWidgetQuery();
        this.dataSource.query.queryType = QueryType.Http;
        this.dataSource.query.httpQuery = new HttpQuery().deserialize(this.params['http_query']);
      } else if (this.params['custom_sql']) {
        this.dataSource.query = new ValueWidgetQuery();
        this.dataSource.query.queryType = QueryType.SQL;
        this.dataSource.query.sqlQuery = new SqlQuery();
        this.dataSource.query.sqlQuery.query = this.params['custom_sql'];
      } else if (this.params['y_column']) {
        const yColumn = new ValueColumnFunc().deserialize(this.params['y_column']);
        this.dataSource.query = new ValueWidgetQuery();
        this.dataSource.query.queryType = QueryType.Simple;
        this.dataSource.query.simpleQuery = new this.dataSource.query.simpleQueryClass();
        this.dataSource.query.simpleQuery.model = fromLegacyModel(this.params['model']);
        this.dataSource.column = yColumn.column;
        this.dataSource.func = yColumn.func;
      }
    }

    // Backward compatibility
    if (this.params['filter_items'] && this.dataSource) {
      this.dataSource.queryInputs = this.params['filter_items'].map(item => {
        const result = new Input();

        result.name = serializeFieldParamName(item['field'], item['lookup'], item['exclude']);
        result.valueType = InputValueType.StaticValue;
        result.staticValue = item['value'];

        return result;
      });
    }

    this.compareName = this.params['compare_name'];
    this.compareGrowthNegative = this.params['compare_growth_negative'];

    if (this.params['compare_data_source']) {
      this.compareDataSource = new ValueWidgetDataSource().deserialize(this.params['compare_data_source']);
    }

    if (this.params['chart_dataset']) {
      this.chartDataset = new ChartWidgetDataset().deserialize(this.params['chart_dataset']);
    }

    // this.resource = this.params['resource'];
    this.prefix = this.params['prefix'];
    this.postfix = this.params['postfix'];
    this.format = this.params['format'];

    if (this.params['value_style']) {
      this.valueStyle = new TextStyle().deserialize(this.params['value_style']);
    } else {
      this.valueStyle = undefined;
    }

    this.icon = this.params['icon'];

    if (this.params.hasOwnProperty('show_reload')) {
      this.showReload = this.params['show_reload'];
    }

    if (this.params['click_action']) {
      this.clickAction = new ActionItem().deserialize(this.params['click_action']);
    } else {
      this.clickAction = undefined;
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data = super.serialize(fields);

    data['params'] = JSON.stringify({
      name_input: this.nameInput ? this.nameInput.serialize() : null,
      title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      compare_name: this.compareName,
      compare_growth_negative: this.compareGrowthNegative,
      compare_data_source: this.compareDataSource ? this.compareDataSource.serialize() : undefined,
      chart_dataset: this.chartDataset ? this.chartDataset.serialize() : undefined,
      prefix: this.prefix,
      postfix: this.postfix,
      format: this.format,
      value_style: this.valueStyle ? this.valueStyle.serialize() : undefined,
      icon: this.icon,
      show_reload: this.showReload,
      click_action: this.clickAction ? this.clickAction.serialize() : undefined
    });

    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }

    return data;
  }

  isConfigured(): boolean {
    return this.dataSource && this.dataSource.isConfigured();
  }
}

registerWidgetForType(WidgetType.Value, ValueWidget);
