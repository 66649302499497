import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import fromPairs from 'lodash/fromPairs';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  map,
  publishLast,
  refCount,
  skip,
  switchMap,
  tap,
  timeout
} from 'rxjs/operators';

import { ScreenRecordService } from '@common/cheat-code';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { DocumentService, SessionStorage } from '@core';
import {
  ActionApproveService,
  ActionControllerService,
  ActionDescriptionService,
  ActionService,
  ActionStore,
  ExportController
} from '@modules/action-queries';
import {
  ACTION_CONTROLLER_PROMPT_COMPONENT_PROVIDER,
  ActionEditController,
  ActionQueryEditController
} from '@modules/actions-components';
import { UserActivityDisplayService } from '@modules/activities';
import { TimelineController, UserActivitiesController } from '@modules/activities-components';
import {
  AdminMode,
  ROUTE_ADMIN_MODE,
  ROUTE_ADMIN_MODE$,
  ROUTE_ADMIN_MODE$_PROVIDER,
  ROUTE_ADMIN_MODE_PROVIDER
} from '@modules/admin-mode';
import { AllProjectSettings, PageFontsService, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ApiInfo, ApiService } from '@modules/api';
import { ModelApiController } from '@modules/api-docs';
import { TemplateProvider } from '@modules/change-components/services/template-provider/template.provider';
import { TaskQueueStore } from '@modules/collaboration';
import { TaskQueueEditController } from '@modules/collaboration-components';
import { CustomViewsStore } from '@modules/custom-views';
import {
  AdminComponentService,
  Customizable,
  CustomizeSelection,
  CustomizeService,
  CustomizeType,
  PagePermissionsService,
  ViewSettingsStore
} from '@modules/customize';
import {
  CUSTOMIZE_ACTION_COMPONENT_PROVIDER,
  CUSTOMIZE_MODEL_COMPONENT_PROVIDER,
  CustomizeBarService,
  ModelFieldEditController,
  ProjectPropertyEditController,
  WORKFLOW_CONTROLLER_COMPONENT_PROVIDER,
  WorkflowEditController
} from '@modules/customize-bar';
import { ACTION_SERVICE_ACTION_MENU_COMPONENT_PROVIDER } from '@modules/customize-components';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ElementContainerService } from '@modules/customize-elements';
import {
  AdminPanelPopupsGenerator,
  AdminPanelSeparatePagesGenerator,
  AdminPanelSinglePageGenerator,
  BackGenerator,
  ChartWidgetGenerator,
  CreateFormGenerator,
  CrudPagesGenerator,
  DashboardGenerator,
  DataSourceGeneratorService,
  DeleteButtonGenerator,
  DetailGenerator,
  FilterGenerator,
  GeneratorUtils,
  ListGenerator,
  TextGenerator,
  UpdateFormGenerator,
  ValueWidgetGenerator,
  ViewSettingsGeneratorService
} from '@modules/customize-generators';
import { DraftChangesService, PublishService, ViewSettingsQueries } from '@modules/customize-utils';
import { DashboardStore } from '@modules/dashboard';
import { WidgetDataSourceService, WidgetQueryService } from '@modules/dashboard-queries';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { DataSyncJobTasksController } from '@modules/data-sync-components';
import { DemoBannerService } from '@modules/demo';
import { ProjectDomainController } from '@modules/domain-components';
import { EmailAddressEditController, EmailTemplateEditController } from '@modules/emails-components';
import { ACTION_SERVICE_EXPORT_COMPONENT_PROVIDER } from '@modules/export';
import { FeatureService } from '@modules/features/services/feature/feature.service';
import { FigmaService } from '@modules/figma-integration';
import { ModelOptionsSource } from '@modules/filters-components';
import { ScannerPopupController } from '@modules/image-codes';
import { ACTION_SERVICE_IMPORT_COMPONENT_PROVIDER, ImportService } from '@modules/import';
import {
  CrispIntegrationEditController,
  DriftIntegrationEditController,
  FacebookPixelIntegrationEditController,
  GoogleAnalyticsIntegrationEditController,
  GoogleTagManagerIntegrationEditController,
  HotjarIntegrationEditController,
  HubspotIntegrationEditController,
  IntercomIntegrationEditController
} from '@modules/integrations-components';
import { ProjectUniqueNameController, SSOSettingsEditController } from '@modules/layout-components';
import { ListFiltersController } from '@modules/list-components';
import {
  MenuItemActionService,
  MenuPagesService,
  MenuSection,
  MenuService,
  MenuSettingsService,
  MenuSettingsStore
} from '@modules/menu';
import { MenuCustomizeService } from '@modules/menu-utils';
import { MessageService } from '@modules/messages';
import { MetaService } from '@modules/meta';
import { MigrationCheckService, MigrationService } from '@modules/migrations';
import { ModelEditController, ModelQueryEditController } from '@modules/model-components';
import {
  ModelDescriptionService,
  ModelDescriptionStore,
  ModelService,
  ModelUtilsService,
  ReducedModelService
} from '@modules/model-queries';
import { InputService } from '@modules/parameters';
import { ProjectApiService } from '@modules/project-api';
import { ProjectSettingsService } from '@modules/project-settings';
import { EnvironmentController, ProjectGroupEditController } from '@modules/project-settings-components';
import { ProjectProtocolService } from '@modules/project-utils';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  Project,
  ProjectGroupStore,
  ProjectPermissionGuard,
  ProjectPropertyStorage,
  ProjectPropertyStore,
  ProjectPropertyType,
  ProjectService,
  ProjectsStore,
  Resource,
  ResourceType
} from '@modules/projects';
import {
  ChooseSyncController,
  CompanyInfoService,
  ResourceChoosePagesController,
  ResourceEditController,
  ResourceModelEditController,
  ResourceSummaryService
} from '@modules/projects-components';
import { PublicApiService } from '@modules/public-api';
import { QueryBuilderService } from '@modules/queries-components';
import { QueryTokensService } from '@modules/queries-tokens';
import {
  AirtableGeneratorService,
  AmazonS3GeneratorService,
  AmplitudeGeneratorService,
  AzureBlobGeneratorService,
  DatabaseGeneratorService,
  DigitalOceanSpacesGeneratorService,
  DjangoGeneratorService,
  DumpResourceService,
  FirebaseGeneratorService,
  GoogleCloudStorageGeneratorService,
  GoogleSheetsGeneratorService,
  HubspotGeneratorService,
  IntercomGeneratorService,
  MailchimpGeneratorService,
  MailgunGeneratorService,
  MixpanelGeneratorService,
  OpenAIGeneratorService,
  PipedriveGeneratorService,
  ResourceGeneratorResolver,
  S3CompatibleGeneratorService,
  SalesforceGeneratorService,
  SendGridGeneratorService,
  SlackGeneratorService,
  SmartSuiteGeneratorService,
  StripeGeneratorService,
  SupabaseStorageGeneratorService,
  TwilioGeneratorService,
  XanoGeneratorService,
  XanoLegacyGeneratorService,
  ZendeskGeneratorService
} from '@modules/resource-generators';
import {
  AirtableResourceController,
  AmazonS3ResourceController,
  AzureBlobResourceController,
  FirebaseResourceController,
  GraphQLResourceControllerService,
  JetAppResourceController,
  JetBridgeResourceController,
  MessagesAPIResourceController,
  ProjectStorageService,
  ResourceControllerService,
  RestApiResourceControllerService,
  SmartSuiteResourceController,
  SupabaseStorageResourceController,
  XanoLegacyResourceController,
  XanoResourceController
} from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ShareController } from '@modules/share';
import { SketchService } from '@modules/sketch-integration';
import { SlackChannelStore, SlackService } from '@modules/slack';
import { SlackJoinPopupController } from '@modules/slack-components';
import { StorageUploadPopupController } from '@modules/storages-components';
import { StorageService } from '@modules/storages-queries';
import {
  ChooseTemplateService,
  ResourceCreateController,
  SelectResourcesController,
  TemplateApplyController,
  TemplateApplyService
} from '@modules/template-components';
import { PageTemplatesGeneratorService } from '@modules/template-generators';
import { PAGE_TEMPLATES_COMPONENT_PROVIDER } from '@modules/template-generators-components';
import { PageTemplatesController } from '@modules/template-queries';
import { ThemeService } from '@modules/theme';
import { ThemeContext } from '@modules/theme-components';
import { GuideSectionStore, GuideService, OnboardingService } from '@modules/tutorial';
import { GuideUtilsService } from '@modules/tutorial/services/guide-utils/guide-utils.service';
import { TutorialTipService } from '@modules/tutorial/services/tutorial-tip/tutorial-tip.service';
import { CurrentUserStore } from '@modules/users';
import { VersionService } from '@modules/versions';
import { CustomViewTemplateCounterStore } from '@modules/views';
import {
  CustomViewMapParametersController,
  CustomViewTemplatesController,
  ImportFigmaNodeController,
  ImportSketchFileController,
  ViewEditorController
} from '@modules/views-components';
import { isSet, KeyboardEventKeyCode, removeClass, toggleClass, trackKeyboardCode } from '@shared';

import {
  ROUTE_ENVIRONMENT_UNIQUE_NAME,
  ROUTE_ENVIRONMENT_UNIQUE_NAME_PROVIDER
} from './providers/route-environment-unique-name';
import { ROUTE_PROJECT_UNIQUE_NAME, ROUTE_PROJECT_UNIQUE_NAME_PROVIDER } from './providers/route-project-unique-name';
import { AdminGlobalsService } from './services/admin-globals/admin-globals.service';

export interface AdminComponentState {
  projectCurrent?: Project;
  projectSettings?: AllProjectSettings;
  environmentCurrent?: Environment;
  subscriptionEnded?: boolean;
  subscriptionPastDue?: boolean;
  billingPermission?: boolean;
  demoBannerEnabled?: boolean;
  demoBannerVisible?: boolean;
  verificationBannerVisible?: boolean;
  customizeSelection?: boolean;
  customizeAnimating?: boolean;
  customizeEnabled?: boolean;
  customizeLayoutEnabled?: boolean;
  customizeMenuEnabled?: boolean;
  customizeLayoutCustomization?: CustomizeSelection;
  menuOpened?: boolean;
  menuSection?: MenuSection;
  metaTitlePrimary?: string;
  iframe?: boolean;
  usersFeatureActive?: boolean;
  customizeBarOpened?: boolean;
  mode?: AdminMode;
}

export const BODY_CONTENTFUL_RIGHT_BAR_CLASS = 'body_contentful-right-bar';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  providers: [
    ROUTE_PROJECT_UNIQUE_NAME_PROVIDER,
    ROUTE_ENVIRONMENT_UNIQUE_NAME_PROVIDER,
    ROUTE_ADMIN_MODE$_PROVIDER,
    ROUTE_ADMIN_MODE_PROVIDER,
    CurrentProjectStore,
    CurrentEnvironmentStore,
    ModelDescriptionStore,
    ActionStore,
    CustomViewsStore,
    TaskQueueStore,
    DashboardStore,
    ThemeContext,
    PublicApiService,
    GuideService,
    GuideUtilsService,
    TutorialTipService,
    ProjectProtocolService,
    MessageService,
    ProjectApiService,
    ProjectSettingsService,
    ModelService,
    ReducedModelService,
    ViewSettingsStore,
    ViewSettingsQueries,
    TemplateApplyService,
    DemoBannerService,
    OnboardingService,
    CompanyInfoService,
    GuideSectionStore,
    DataSourceGeneratorService,
    ViewSettingsGeneratorService,
    AdminPanelSeparatePagesGenerator,
    AdminPanelSinglePageGenerator,
    AdminPanelPopupsGenerator,
    MenuSettingsStore,
    ProjectSettingsStore,
    ChooseTemplateService,
    ActionDescriptionService,
    DatabaseGeneratorService,
    DjangoGeneratorService,
    AmplitudeGeneratorService,
    FirebaseGeneratorService,
    GoogleSheetsGeneratorService,
    HubspotGeneratorService,
    IntercomGeneratorService,
    MailchimpGeneratorService,
    MailgunGeneratorService,
    MixpanelGeneratorService,
    PipedriveGeneratorService,
    SendGridGeneratorService,
    SlackGeneratorService,
    StripeGeneratorService,
    TwilioGeneratorService,
    OpenAIGeneratorService,
    ZendeskGeneratorService,
    AzureBlobGeneratorService,
    AmazonS3GeneratorService,
    DigitalOceanSpacesGeneratorService,
    S3CompatibleGeneratorService,
    GoogleCloudStorageGeneratorService,
    SupabaseStorageGeneratorService,
    AirtableGeneratorService,
    SmartSuiteGeneratorService,
    SalesforceGeneratorService,
    XanoGeneratorService,
    ResourceGeneratorResolver,
    CustomizeBarService,
    ProjectGroupStore,
    ActionService,
    InputService,
    ACTION_CONTROLLER_PROMPT_COMPONENT_PROVIDER,
    ACTION_SERVICE_EXPORT_COMPONENT_PROVIDER,
    ACTION_SERVICE_IMPORT_COMPONENT_PROVIDER,
    ACTION_SERVICE_ACTION_MENU_COMPONENT_PROVIDER,
    ActionControllerService,
    TemplateProvider,
    ModelUtilsService,
    DumpResourceService,
    ProjectPermissionGuard,
    ResourceControllerService,
    ModelDescriptionService,
    WidgetQueryService,
    MigrationService,
    MigrationCheckService,
    UserActivityDisplayService,
    StorageService,
    MenuCustomizeService,
    ActionApproveService,
    FeatureService,
    MenuSettingsService,
    ThemeService,
    QueryTokensService,
    MenuPagesService,
    ResourceSummaryService,
    QueryBuilderService,
    EnvironmentController,
    ResourceEditController,
    SelectResourcesController,
    TemplateApplyController,
    ResourceChoosePagesController,
    ActionEditController,
    ActionQueryEditController,
    ElementConfigurationService,
    ResourceModelEditController,
    TaskQueueEditController,
    CUSTOMIZE_ACTION_COMPONENT_PROVIDER,
    CUSTOMIZE_MODEL_COMPONENT_PROVIDER,
    TimelineController,
    UserActivitiesController,
    ElementContainerService,
    FirebaseResourceController,
    ModelEditController,
    ModelQueryEditController,
    PagePermissionsService,
    ProjectGroupEditController,
    ModelDescriptionDataSourceService,
    WidgetDataSourceService,
    DataSyncJobTasksController,
    ImportService,
    ListFiltersController,
    ExportController,
    ModelFieldEditController,
    ProjectDomainController,
    ResourceCreateController,
    ProjectUniqueNameController,
    SSOSettingsEditController,
    EmailTemplateEditController,
    EmailAddressEditController,
    ProjectPropertyStore,
    ShareController,
    ProjectPropertyEditController,
    WorkflowEditController,
    PageTemplatesController,
    PageTemplatesGeneratorService,
    GeneratorUtils,
    CreateFormGenerator,
    DeleteButtonGenerator,
    ListGenerator,
    UpdateFormGenerator,
    DetailGenerator,
    BackGenerator,
    FilterGenerator,
    DashboardGenerator,
    ValueWidgetGenerator,
    ChartWidgetGenerator,
    TextGenerator,
    CrudPagesGenerator,
    ModelApiController,
    ProjectStorageService,
    DraftChangesService,
    XanoLegacyResourceController,
    XanoLegacyGeneratorService,
    PublishService,
    MenuItemActionService,
    ModelOptionsSource,
    AdminGlobalsService,
    ViewEditorController,
    ChooseSyncController,
    CustomViewTemplatesController,
    CustomViewMapParametersController,
    CustomViewTemplateCounterStore,
    ImportSketchFileController,
    SketchService,
    ImportFigmaNodeController,
    FigmaService,
    GoogleTagManagerIntegrationEditController,
    CrispIntegrationEditController,
    DriftIntegrationEditController,
    FacebookPixelIntegrationEditController,
    GoogleAnalyticsIntegrationEditController,
    HotjarIntegrationEditController,
    HubspotIntegrationEditController,
    IntercomIntegrationEditController,
    GoogleTagManagerIntegrationEditController,
    StorageUploadPopupController,
    ScannerPopupController,
    SlackService,
    SlackChannelStore,
    SlackJoinPopupController,
    AdminComponentService,
    PageFontsService,
    PAGE_TEMPLATES_COMPONENT_PROVIDER,
    WORKFLOW_CONTROLLER_COMPONENT_PROVIDER
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Customizable
export class AdminComponent implements OnInit, OnDestroy, AfterViewInit {
  state: AdminComponentState = {};
  hasCustomizationPermission = false;
  loading = true;
  error: string;
  resourcesApiInfoSubscription: Subscription;
  backgroundColor$: Observable<string>;
  menuSections = MenuSection;
  adminModes = AdminMode;
  menuCustomizeSelection = {
    subtitle: '',
    title: 'Edit Menu',
    image: 'menu',
    id: 'customize-menu',
    clickEvent: AnalyticsEvent.Deprecated.CustomizeInterfaceChooseNavigationView,
    description: [
      { icon: 'edit', label: 'Change the title and icons of a specific collection' },
      { icon: 'filter_down', label: 'Rearrange the order of collections' },
      { icon: 'section', label: 'Organize collections into groups' }
    ]
  };
  recording = false;
  externalFonts: string[] = [];
  isMobile = false;

  constructor(
    @Inject(ROUTE_PROJECT_UNIQUE_NAME) private projectUniqueName$: Observable<string>,
    @Inject(ROUTE_ENVIRONMENT_UNIQUE_NAME) private environmentUniqueName$: Observable<string>,
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public adminComponentService: AdminComponentService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectsStore: ProjectsStore,
    private routing: RoutingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private injector: Injector,
    public customizeService: CustomizeService,
    public menuService: MenuService,
    public demoBannerService: DemoBannerService,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private projectSettingsStore: ProjectSettingsStore,
    private actionStore: ActionStore,
    private customViewsStore: CustomViewsStore,
    private dashboardStore: DashboardStore,
    private guideSectionStore: GuideSectionStore,
    private projectGroupStore: ProjectGroupStore,
    private taskQueueStore: TaskQueueStore,
    private projectPropertyStore: ProjectPropertyStore,
    private onboardingService: OnboardingService,
    private companyInfoService: CompanyInfoService,
    public metaService: MetaService,
    private sessionStorage: SessionStorage,
    private notificationService: NotificationService,
    public currentUserStore: CurrentUserStore,
    private resourceControllerService: ResourceControllerService,
    private projectService: ProjectService,
    public themeService: ThemeService,
    private migrationCheckService: MigrationCheckService,
    private analyticsService: UniversalAnalyticsService,
    private projectApiService: ProjectApiService,
    private apiService: ApiService,
    private versionService: VersionService,
    private publicApiService: PublicApiService,
    public screenRecordService: ScreenRecordService,
    private popupService: PopupService,
    private documentService: DocumentService,
    private globalsService: AdminGlobalsService
  ) {}

  ngOnInit() {
    this.backgroundColor$ = this.themeService.backgroundColorEffective$;

    this.resourceControllerService.register(ResourceType.JetApp, JetAppResourceController);
    this.resourceControllerService.register(ResourceType.JetBridge, JetBridgeResourceController);
    this.resourceControllerService.register(ResourceType.MessagesAPI, MessagesAPIResourceController);
    this.resourceControllerService.register(ResourceType.RestAPI, RestApiResourceControllerService);
    this.resourceControllerService.register(ResourceType.GraphQL, GraphQLResourceControllerService);
    this.resourceControllerService.register(ResourceType.AzureBlob, AzureBlobResourceController);
    this.resourceControllerService.register(ResourceType.AmazonS3, AmazonS3ResourceController);
    this.resourceControllerService.register(ResourceType.SupabaseStorage, SupabaseStorageResourceController);
    this.resourceControllerService.register(ResourceType.Airtable, AirtableResourceController);
    this.resourceControllerService.register(ResourceType.SmartSuite, SmartSuiteResourceController);
    this.resourceControllerService.register(ResourceType.Firebase, FirebaseResourceController);
    this.resourceControllerService.register(ResourceType.Xano, XanoResourceController);

    this.analyticsService.logProjectApiServiceErrors(this.projectApiService).pipe(untilDestroyed(this)).subscribe();

    combineLatest(this.projectUniqueName$, this.environmentUniqueName$)
      .pipe(
        tap(() => {
          if (this.resourcesApiInfoSubscription) {
            this.resourcesApiInfoSubscription.unsubscribe();
            this.resourcesApiInfoSubscription = undefined;
          }

          this.popupService.clear();

          this.loading = true;
          this.cd.markForCheck();
        }),
        switchMap(([projectUniqueName, environmentUniqueName]) => {
          this.currentProjectStore.uniqueName = projectUniqueName;
          this.currentProjectStore.environmentUniqueName = environmentUniqueName;
          this.currentEnvironmentStore.uniqueName = environmentUniqueName;
          return this.currentProjectStore.getFirst(true).pipe(
            tap(project => {
              // this.currentEnvironmentStore.uniqueName = environmentUniqueName;
              // this.currentEnvironmentStore.instance = project.environments.find(
              //   item => item.uniqueName == environmentUniqueName
              // );
              // this.currentProjectStore.instance.resources = this.currentProjectStore.instance.resources.filter(
              //   item => item.environment == environmentUniqueName || item.demo
              // );
            }),
            delayWhen(project => {
              return project ? this.getProjectToken(projectUniqueName, environmentUniqueName) : of(undefined);
            })
          );
        }),
        switchMap(project => {
          this.projectSettingsStore.instance = project.projectSettings;
          this.modelDescriptionStore.reset();
          this.viewSettingsStore.reset();
          this.menuSettingsStore.reset();
          this.actionStore.reset();
          this.customViewsStore.reset();
          this.dashboardStore.reset();
          this.guideSectionStore.reset();
          this.projectGroupStore.reset();
          this.taskQueueStore.reset();
          this.projectPropertyStore.reset();

          window['project'] = project ? project.uniqueName : undefined;
          window['project_environment'] = this.currentEnvironmentStore.instance
            ? this.currentEnvironmentStore.instance.uniqueName
            : undefined;

          const environment = this.currentEnvironmentStore.instance;
          const resources = project && environment ? project.getEnvironmentResources(environment.uniqueName) : [];
          const apiInfo$ = this.fetchApiInfo(resources);
          const menuSettings$ = this.menuSettingsStore.getFirst();
          const customViews$ = this.customViewsStore.getFirst();

          return combineLatest(of(project), apiInfo$, menuSettings$, customViews$);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        ([project, apiInfo]) => {
          // this.projectsStore.current = project;
          window['legacyModels'] =
            !!project.params['legacy_models'] ||
            apiInfo.filter(item => item).some(item => item.versionLessThan('0.6.8'));
          window['legacy_tokens'] = !!project.params['legacy_tokens'];
          window['version'] = this.currentEnvironmentStore.instance
            ? this.currentEnvironmentStore.instance.version
            : undefined;
          window['project_has_parent'] = project ? !!project.parent : false;

          if (project) {
            this.onProjectLoaded(
              project,
              this.currentEnvironmentStore.instance,
              this.projectSettingsStore.getAllSettings()
            );
          }

          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          this.error = error;
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.mode$.pipe(untilDestroyed(this)).subscribe(mode => {
      window['mode'] = mode;

      if (mode == AdminMode.Builder) {
        this.customizeService.enabled = CustomizeType.Layout;
      } else if (mode == AdminMode.App) {
        this.customizeService.enabled = undefined;
      }
    });

    merge(of({}), this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.menuService.opened = false;
      });

    combineLatest(
      this.currentProjectStore.instance$,
      this.projectSettingsStore.getAllSettings$(),
      this.currentEnvironmentStore.original$,
      this.currentProjectStore.instance$.pipe(map(item => (item ? item.isSubscriptionEnded() : false))),
      this.currentProjectStore.instance$.pipe(map(item => (item ? item.isSubscriptionPastDue() : false))),
      this.currentProjectStore.instance$.pipe(map(item => (item ? item.hasProjectBillingPermission() : false))),
      this.demoBannerService.enabled$,
      this.demoBannerService.visible$,
      this.currentUserStore.original$.pipe(map(user => user && !user.emailVerified)),
      this.customizeService.selection$,
      // this.customizeService.animating$,
      this.customizeService.enabled$,
      this.customizeService.layoutEnabled$,
      this.customizeService.menuEnabled$,
      this.customizeService.layoutCustomization$,
      this.menuService.opened$,
      this.menuService.section$,
      this.metaService.titlePrimary$,
      this.currentProjectStore.instance$.pipe(
        map(project => {
          if (!project) {
            return true;
          }
          const iframeKey = `iframe_${project.uniqueName}`;
          return !!this.sessionStorage.get(iframeKey);
        })
      ),
      combineLatest(
        this.currentProjectStore.instance$,
        this.currentUserStore.original$.pipe(map(user => user && user.isAnonymous()))
      ).pipe(
        map(([project, isAnonymous]) => {
          if (!project) {
            return false;
          }

          return project.isOwner || isAnonymous || project.features.isUsersEnabled();
        })
      ),
      this.menuService.customizeBarOpened$,
      this.mode$
    )
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this.state = fromPairs(
          [
            'projectCurrent',
            'projectSettings',
            'environmentCurrent',
            'subscriptionEnded',
            'subscriptionPastDue',
            'billingPermission',
            'demoBannerEnabled',
            'demoBannerVisible',
            'verificationBannerVisible',
            'customizeSelection',
            // 'customizeAnimating',
            'customizeEnabled',
            'customizeLayoutEnabled',
            'customizeMenuEnabled',
            'customizeLayoutCustomization',
            'menuOpened',
            // 'menuSlided',
            'menuSection',
            'metaTitlePrimary',
            'iframe',
            'usersFeatureActive',
            'customizeBarOpened',
            'mode'
          ].map((item, i) => [item, state[i]])
        );
        this.hasCustomizationPermission =
          this.state.projectCurrent &&
          this.state.environmentCurrent &&
          this.state.projectCurrent.hasEnvironmentCustomizationPermission(this.state.environmentCurrent);
        this.externalFonts = this.getExternalFonts(this.state.projectSettings);
        this.notificationService.topPadding = this.state.customizeEnabled;
        this.cd.markForCheck();

        Sentry.setContext(
          'project',
          this.state.projectCurrent
            ? {
                project: this.state.projectCurrent.uniqueName,
                environment: this.state.environmentCurrent ? this.state.environmentCurrent.uniqueName : undefined,
                mode: this.state.mode
              }
            : {}
        );
      });

    this.screenRecordService
      .cheatCode$('jet!screenrecord')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.customizeService.enabled) {
          return;
        }

        this.recording = !this.recording;
        this.cd.markForCheck();

        if (this.recording) {
          this.notificationService.warning('Screen recording mode activated!');
        } else {
          this.notificationService.warning('Screen recording mode deactivated!');
        }
      });

    this.themeService.staffEnabled$
      .pipe(
        filter(item => item),
        switchMap(() => {
          return trackKeyboardCode([
            KeyboardEventKeyCode.E,
            KeyboardEventKeyCode.R,
            KeyboardEventKeyCode.R,
            KeyboardEventKeyCode.O,
            KeyboardEventKeyCode.R
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe(entered => {
        if (entered) {
          setTimeout(() => this.triggerException());
        }
      });

    this.documentService
      .isMobile$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.isMobile = value;
        this.cd.markForCheck();
      });

    this.customizeService
      .getContentfulRightBarHandlers()
      .pipe(untilDestroyed(this))
      .subscribe(handlers => {
        toggleClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS, handlers.length > 0);
      });
  }

  ngOnDestroy(): void {
    this.notificationService.topPadding = false;
    this.projectsStore.current = undefined;
    this.customizeService.enabled = undefined;

    Sentry.setContext('project', {});

    removeClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'wheel', { passive: false })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (this.customizeService.selection || this.menuService.opened) {
          e.preventDefault();
        }
      });

    fromEvent(document.body, 'touchmove', { passive: false })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (this.customizeService.selection || this.menuService.opened) {
          e.preventDefault();
        }
      });

    // merge(this.customizeService.selection$.pipe(skip(1)), this.menuService.opened$.pipe(skip(1)))
    //   .pipe(untilDestroyed(this))
    //   .subscribe(zoomOut => {
    //     if (!this.contentInner) {
    //       return;
    //     }
    //
    //     if (zoomOut) {
    //       const scrollTop = getWindowScrollTop();
    //       const transformOrigin = (scrollTop + window.innerHeight * 0.5) / document.body.offsetHeight;
    //
    //       this.contentInner.nativeElement.style['transform-origin'] = `center ${transformOrigin * 100}%`;
    //     }
    //   });

    // merge(
    //   this.customizeService.selection$.pipe(skip(1)),
    //   this.customizeService.enabled$.pipe(skip(1))
    // )
    //   .pipe(
    //     tap(() => {
    //       this.customizeService.animating = true;
    //     }),
    //     delay(600),
    //     untilDestroyed(this)
    //   )
    //   .subscribe(() => {
    //     this.customizeService.animating = false;
    //   });
  }

  fetchApiInfo(allResources: Resource[]): Observable<any> {
    const resources = allResources.filter(item => {
      const controller = this.resourceControllerService.getForResource(item, true);
      return controller && controller.checkApiInfo && !item.apiInfo;
    });

    if (!resources.length) {
      return of([]);
    }

    const getApiInfoKey = (resource: Resource) => {
      return JSON.stringify({ url: resource.params['url'] });
    };

    const groupedByKey = resources.reduce<{ [key: string]: Resource[] }>((acc, item) => {
      const key = getApiInfoKey(item);
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item);

      return acc;
    }, {});

    return combineLatest(
      values(groupedByKey).map(groupResources => {
        const resource = groupResources[0];
        const controller = this.resourceControllerService.getForResource(resource, true);

        return controller.getApiInfo(resource).pipe(
          tap(apiInfo => {
            if (apiInfo) {
              groupResources.forEach(item => {
                item.apiInfo = apiInfo;
              });
            }
          }),
          timeout(5000),
          catchError(() => of(undefined))
        );
      })
    );
  }

  getProjectToken(projectName: string, environmentName: string): Observable<boolean> {
    return this.projectApiService.getToken(projectName, environmentName, { mode: this.mode }).pipe(
      tap(result => this.projectApiService.saveToken(result)),
      map(() => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  getExternalFonts(projectSettings: AllProjectSettings): string[] {
    if (!projectSettings) {
      return [];
    }

    return uniq(
      [projectSettings.fontRegular, projectSettings.fontHeading, ...projectSettings.fontsUsed].filter(item =>
        isSet(item)
      )
    );
  }

  setProjectTheme(project: Project, projectSettings: AllProjectSettings) {
    this.themeService.color = project.logoColor;

    if (projectSettings && projectSettings.defaultTheme) {
      this.themeService.setDefaultTheme(projectSettings.defaultTheme);
    }
  }

  initGlobalStorage(project: Project, environment: Environment) {
    if (!project || !environment) {
      this.currentEnvironmentStore.globalStorage = undefined;
      return;
    }

    this.currentEnvironmentStore.globalStorage = Injector.create({
      providers: [
        {
          provide: ProjectPropertyStorage,
          useFactory: (projectPropertyStore: ProjectPropertyStore) => {
            return new ProjectPropertyStorage(
              project.uniqueName,
              environment.uniqueName,
              projectPropertyStore,
              ProjectPropertyType.Global
            );
          },
          deps: [ProjectPropertyStore]
        }
      ],
      parent: this.injector
    }).get<ProjectPropertyStorage>(ProjectPropertyStorage);
  }

  onProjectLoaded(project: Project, environment: Environment, projectSettings: AllProjectSettings) {
    this.projectService.updateProjectLastUsed(project);
    this.setProjectTheme(project, projectSettings);
    this.versionService.checkWrongProjectVersionLoaded(project, environment);
    this.migrationCheckService.checkNotAppliedMigrations(project, environment);
    this.initGlobalStorage(project, environment);

    this.resourcesApiInfoSubscription = this.currentEnvironmentStore.resources$
      .pipe(
        skip(1),
        switchMap(resources => this.fetchApiInfo(resources)),
        untilDestroyed(this)
      )
      .subscribe();

    // TODO: refactor into single place
    setTimeout(() => {
      if (!this.companyInfoService.showCompanyInfoPopupIfNeeded(project)) {
        this.onboardingService.showOnboardingPopupIfNeeded(project);
      }
    }, 3000);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.Opened, {
      Name: project.name,
      DateCreated: project.dateAdd ? project.dateAdd.toISOString() : undefined,
      SubscriptionPlan:
        project.subscription && project.subscription.plan ? project.subscription.plan.uniqueName : undefined,
      SubscriptionFreeTrial: project.subscription ? !!project.subscription.freeTrial : undefined,
      SubscriptionDateBegin:
        project.subscription && project.subscription.dateBegin
          ? project.subscription.dateBegin.toISOString()
          : undefined,
      SubscriptionDateCancelled:
        project.subscription && project.subscription.dateCancelled
          ? project.subscription.dateCancelled.toISOString()
          : undefined
    });
  }

  onBackgroundClick(e) {
    if (!this.customizeService.selection) {
      return;
    }

    if (!e.target.getAttribute('data-selection-cancel')) {
      return;
    }

    this.disableCustomizeSelection();
  }

  disableCustomizeSelection() {
    this.customizeService.selection = false;
  }

  disableCustomize() {
    this.customizeService.enabled = undefined;
  }

  onRouteActivate(e) {
    const project = this.state.projectCurrent;
    const environmentName = this.state.environmentCurrent ? this.state.environmentCurrent.uniqueName : undefined;
    const state = this.router.routerState.snapshot;
    const mode = this.state.mode;
    const allowedUrlPrefixes = [];

    if (project.hasProjectBillingPermission()) {
      allowedUrlPrefixes.push(project.settingsBillingLink);
    } else {
      allowedUrlPrefixes.push(project.subscriptionExpiredLink);
    }

    if (project.hasEnvironmentAccessPermission(project.defaultEnvironment)) {
      allowedUrlPrefixes.push(project.settingsUsersLink);
    }

    const urlAllowed = allowedUrlPrefixes.some(item =>
      state.url.startsWith(
        this.routing
          .appLink(item, { projectName: project.uniqueName, environmentName: environmentName, modeName: mode })
          .join('/')
      )
    );

    if (project.isSubscriptionEnded() && !urlAllowed) {
      if (project.hasProjectBillingPermission()) {
        const link = project.settingsBillingLink;
        this.routing.navigateApp(
          link,
          { replaceUrl: true },
          {
            projectName: project.uniqueName,
            environmentName: environmentName,
            modeName: mode
          }
        );
        this.notificationService.warning(
          'Subscription has expired',
          'Choose subscription plan to continue using Jet Admin'
        );
      } else {
        const link = project.subscriptionExpiredLink;
        this.routing.navigateApp(
          link,
          { replaceUrl: true },
          {
            projectName: project.uniqueName,
            environmentName: environmentName,
            modeName: mode
          }
        );
      }
    }
  }

  triggerException() {
    const a = {
      testProperty: {
        testNestedProperty: 'value'
      }
    };
    delete a['testProperty'];
    console.log(a.testProperty.testNestedProperty);
  }
}
