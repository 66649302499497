import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { AggregateFunc } from '@modules/charts';
import { ChartWidgetDataSource, DataSourceType } from '@modules/data-sources';
import { serializeFieldParamName } from '@modules/field-lookups';
import { Input, InputValueType } from '@modules/fields';
import { fromLegacyModel } from '@modules/models';
import { ChartWidgetQuery, HttpQuery, QueryType, SqlQuery } from '@modules/queries';

// TODO: Refactor import
import { TextStyle } from '../../../../customize/data/text-style';
import { migrateModelDescriptionDataSource } from '../../../../customize/utils/migration';

import { WidgetType } from '../widget-type';
import { registerWidgetForType } from '../widgets';
import { Widget } from './widget';

// TODO: Remove
export class ChartColumnFunc {
  public column: string;
  public func: AggregateFunc;
  public color: string;
  public name: string;
  public format: string;

  deserialize(data: Object): ChartColumnFunc {
    this.column = data['column'];
    this.func = data['func'];
    this.color = data['color'];
    this.name = data['name'];
    this.format = data['format'];

    return this;
  }

  serialize() {
    return {
      column: this.column,
      func: this.func,
      color: this.color,
      name: this.name,
      format: this.format
    };
  }
}

export enum ChartType {
  Line = 'line',
  Bar = 'bar',
  StackedBar = 'stacked_bar',
  Pie = 'pie',
  Doughnut = 'doughnut',
  Radar = 'radar',
  PolarArea = 'polar_area',
  Scatter = 'scatter',
  Bubble = 'bubble'
}

export const singleColorDatasetChartTypes = [
  ChartType.Bar,
  ChartType.StackedBar,
  ChartType.Line,
  ChartType.Radar,
  ChartType.PolarArea,
  ChartType.Scatter,
  ChartType.Bubble
];

export class ChartWidgetDataset {
  public dataSource: ChartWidgetDataSource;
  public color = '';
  public name: string;
  public format: string;

  deserialize(data: Object): ChartWidgetDataset {
    this.color = data['color'];
    this.name = data['name'];
    this.format = data['format'];

    if (data['data_source']) {
      this.dataSource = new ChartWidgetDataSource().deserialize(data['data_source']);
    } else if (data['resource']) {
      // Backward compatibility
      this.dataSource = migrateModelDescriptionDataSource(ChartWidgetDataSource, ChartWidgetQuery, {
        resource: data['resource'],
        query: data['query'],
        parameters: data['parameters'],
        inputs: data['inputs'],
        columns: data['columns']
      });

      if (data['query']) {
        this.dataSource.xColumn = data['query']['x_column'];
        this.dataSource.xLookup = data['query']['x_lookup'];
        this.dataSource.xColumn2 = data['query']['x_column_2'];
        this.dataSource.xLookup2 = data['query']['x_lookup_2'];

        if (data['query']['y_func']) {
          this.dataSource.yFunc = data['query']['y_func'];
        } else if (data['query']['simple_query'] && data['query']['simple_query']['y_func']) {
          // Backward compatibility
          this.dataSource.yFunc = data['query']['simple_query']['y_func'];
        }

        if (data['query']['y_column']) {
          this.dataSource.yColumn = data['query']['y_column'];
        } else if (data['query']['simple_query'] && data['query']['simple_query']['y_column']) {
          // Backward compatibility
          this.dataSource.yColumn = data['query']['simple_query']['y_column'];
        }
      }

      // Backward compatibility
      if (data['x_column'] && this.dataSource.xColumn === undefined) {
        this.dataSource.xColumn = data['x_column'];
      }

      // Backward compatibility
      if (data['x_lookup'] && this.dataSource.xLookup === undefined) {
        this.dataSource.xLookup = data['x_lookup'];
      }

      // Backward compatibility
      if (data['y_column'] && this.dataSource.yColumn === undefined) {
        this.dataSource.yColumn = data['y_column'];
      }
    }

    return this;
  }

  serialize() {
    return {
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      color: this.color,
      name: this.name,
      format: this.format
    };
  }

  isConfigured(): boolean {
    return this.dataSource && this.dataSource.isConfigured();
  }
}

export class ChartWidgetGroup {
  value: any;
  name: string;
  color: string;

  deserialize(data: Object): ChartWidgetGroup {
    this.value = data['value'];
    this.name = data['name'];
    this.color = data['color'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      value: this.value,
      name: this.name,
      color: this.color
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

export class ChartWidget extends Widget {
  public type = WidgetType.Chart;
  public titleStyle: TextStyle;
  public datasets: ChartWidgetDataset[] = [];
  public groups: ChartWidgetGroup[] = [];
  public chartType: ChartType = ChartType.Bar;
  public datasetColumnEnabled = false;
  public datasetColumn: string;
  public yFormat: string;
  public min: number;
  public max: number;
  public customQueryXColumn = true;
  public customDateRange = true;
  public showReload = true;
  public legend = true;
  public percentage = false;
  public smooth = false;
  public clickAction: ActionItem;

  deserialize(data: Object): ChartWidget {
    super.deserialize(data);
    this.chartType = this.params['chart_type'];
    this.datasetColumnEnabled = this.params['dataset_column_enabled'];
    this.datasetColumn = this.params['dataset_column'];
    this.yFormat = this.params['y_format'];
    this.min = this.params['min'];
    this.max = this.params['max'];

    if (this.params['title_style']) {
      this.titleStyle = new TextStyle().deserialize(this.params['title_style']);
    } else {
      this.titleStyle = undefined;
    }

    if (this.params.hasOwnProperty('custom_query_x_column')) {
      this.customQueryXColumn = this.params['custom_query_x_column'];
    }

    if (this.params.hasOwnProperty('custom_date_range')) {
      this.customDateRange = this.params['custom_date_range'];
    }

    if (this.params.hasOwnProperty('show_reload')) {
      this.showReload = this.params['show_reload'];
    }

    if (this.params.hasOwnProperty('legend')) {
      this.legend = this.params['legend'];
    }

    if (this.params.hasOwnProperty('percentage')) {
      this.percentage = this.params['percentage'];
    }

    if (this.params.hasOwnProperty('smooth')) {
      this.smooth = this.params['smooth'];
    }

    if (this.params['datasets']) {
      this.datasets = this.params['datasets'].map(item => new ChartWidgetDataset().deserialize(item));
    } else if (this.params['y_columns']) {
      // Backward compatibility
      this.datasets = this.params['y_columns']
        .map(item => new ChartColumnFunc().deserialize(item))
        .map((item: ChartColumnFunc) => {
          const query = new ChartWidgetQuery();
          const dataset = new ChartWidgetDataset();

          if (this.params['http_query']) {
            query.queryType = QueryType.Http;
            query.httpQuery = new HttpQuery().deserialize(this.params['http_query']);
          } else if (this.params['custom_sql']) {
            query.queryType = QueryType.SQL;
            query.sqlQuery = new SqlQuery();
            query.sqlQuery.query = this.params['custom_sql'];
          } else {
            query.queryType = QueryType.Simple;
            query.simpleQuery = new query.simpleQueryClass();
            query.simpleQuery.model = fromLegacyModel(this.params['model']);
          }

          dataset.dataSource = new ChartWidgetDataSource();
          dataset.dataSource.type = DataSourceType.Query;
          dataset.dataSource.queryResource = 'jet_bridge';
          dataset.dataSource.query = query;

          if (this.params['x_lookup']) {
            dataset.dataSource.xLookup = this.params['x_lookup'];
          }

          dataset.dataSource.yFunc = item.func;

          dataset.color = item.color;
          dataset.name = item.name;
          dataset.format = item.format;

          return dataset;
        });
    }

    // Backward compatibility
    if (this.params['filter_items']) {
      this.datasets
        .filter(dataset => dataset.dataSource)
        .forEach(dataset => {
          dataset.dataSource.queryInputs = this.params['filter_items'].map(item => {
            const result = new Input();

            result.name = serializeFieldParamName(item['field'], item['lookup'], item['exclude']);
            result.valueType = InputValueType.StaticValue;
            result.staticValue = item['value'];

            return result;
          });
        });
    }

    if (this.params['groups']) {
      this.groups = this.params['groups'].map(item => new ChartWidgetGroup().deserialize(item));
    }

    if (this.params['click_action']) {
      this.clickAction = new ActionItem().deserialize(this.params['click_action']);
    } else {
      this.clickAction = undefined;
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data = super.serialize(fields);

    data['params'] = JSON.stringify({
      name_input: this.nameInput ? this.nameInput.serialize() : null,
      title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
      chart_type: this.chartType,
      dataset_column_enabled: this.datasetColumnEnabled,
      dataset_column: this.datasetColumn,
      datasets: this.datasets.map(item => item.serialize()),
      groups: this.groups.map(item => item.serialize()),
      y_format: this.yFormat,
      min: this.min,
      max: this.max,
      custom_query_x_column: this.customQueryXColumn,
      custom_date_range: this.customDateRange,
      show_reload: this.showReload,
      legend: this.legend,
      smooth: this.smooth,
      percentage: this.percentage,
      click_action: this.clickAction ? this.clickAction.serialize() : undefined
    });

    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }

    return data;
  }

  get analyticsName(): string {
    if (this.chartType == ChartType.Line) {
      return 'chart_line';
    } else if (this.chartType == ChartType.Bar) {
      return 'chart_bar';
    } else if (this.chartType == ChartType.Pie) {
      return 'chart_pie';
    } else if (this.chartType == ChartType.Doughnut) {
      return 'chart_doughnut';
    } else if (this.chartType == ChartType.Radar) {
      return 'chart_radar';
    } else if (this.chartType == ChartType.PolarArea) {
      return 'chart_polar_area';
    } else if (this.chartType == ChartType.Scatter) {
      return 'chart_scatter';
    } else {
      return 'chart';
    }
  }
}

registerWidgetForType(WidgetType.Chart, ChartWidget);
