var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import clone from 'lodash/clone';
import { TintStyle } from '@modules/actions';
import { MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
var CustomizeBarActionDropdownEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarActionDropdownEditForm, _super);
    function CustomizeBarActionDropdownEditForm(elementConfigurationService) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            actions: new FormControl([]),
            icon: new FormControl(null),
            style: new FormControl(TintStyle.Primary),
            tint: new FormControl(null),
            verbose_name: new FieldInputControl({ name: 'value' }),
            disabled_input: new FieldInputControl({ name: 'value' }),
            visible_input: new FieldInputControl({ name: 'value' }),
            tooltip: new FormControl(''),
            margin: new MarginControl()
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        _this.styleOptions = [
            {
                value: TintStyle.Primary,
                image: 'button-primary'
            },
            {
                value: TintStyle.Default,
                image: 'button-default'
            },
            {
                value: TintStyle.Transparent,
                image: 'button-transparent'
            }
        ];
        return _this;
    }
    CustomizeBarActionDropdownEditForm.prototype.init = function (options) {
        var _this = this;
        this.options = options;
        var value = {
            actions: options.element.actionItems,
            icon: options.element.icon,
            style: options.element.style,
            tint: options.element.tint,
            verbose_name: options.element.verboseNameInput ? options.element.verboseNameInput.serialize() : {},
            disabled_input: options.element.disabledInput ? options.element.disabledInput.serialize() : {},
            visible_input: options.element.visibleInput ? options.element.visibleInput.serialize() : {},
            tooltip: options.element.tooltip,
            margin: options.element.margin
        };
        this.patchValue(value, { emitEvent: false });
        if (options.nameEditable) {
            this.controls.verbose_name.valueChanges.subscribe(function (inputValue) {
                var input = inputValue ? new FieldInput().deserialize(inputValue) : undefined;
                if (!input || input.valueType != InputValueType.StaticValue) {
                    return;
                }
                var name = input.staticValue;
                if (options.nameCleanValue) {
                    name = options.nameCleanValue(name);
                }
                _this.controls.name.patchValue(name);
            });
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarActionDropdownEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isActionDropdownConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarActionDropdownEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = clone(this.options.element);
        if (this.options.nameEditable) {
            instance.name = value['name'];
        }
        instance.actionItems = value['actions'];
        instance.icon = value['icon'];
        instance.style = value['style'];
        instance.tint = value['tint'];
        instance.verboseNameInput = value['verbose_name'] ? new FieldInput().deserialize(value['verbose_name']) : undefined;
        if (value['disabled_input']) {
            instance.disabledInput = new Input().deserialize(value['disabled_input']);
        }
        else {
            instance.disabledInput = undefined;
        }
        if (value['visible_input']) {
            instance.visibleInput = new Input().deserialize(value['visible_input']);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.tooltip = isSet(value['tooltip']) ? value['tooltip'].trim() : undefined;
        instance.margin = value['margin'];
        return instance;
    };
    return CustomizeBarActionDropdownEditForm;
}(FormGroup));
export { CustomizeBarActionDropdownEditForm };
