import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { SelectComponent } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, map, skip, switchMap, take, tap } from 'rxjs/operators';

import { Option } from '@modules/field-components';
import { createFormFieldFactory, LOG } from '@modules/fields';
import { controlValue } from '@shared';

import { GoogleSheetsFileSource } from '../google-sheets-file-source';
import { GoogleSheetsFileControl, parseSheetUrl } from '../google-sheets-resource-settings.form';

// TODO: Refactor import
import {
  GoogleDriveFile,
  GoogleDriveSpreadsheet,
  GoogleDriveSpreadsheetSheet
} from '../../../../data/google-sheets-resource-params.data';

export interface SheetValue {
  name: string;
  index: number;
  sheetId: number;
}

@Component({
  selector: 'app-google-sheets-resource-settings-file',
  templateUrl: './google-sheets-resource-settings-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSheetsResourceSettingsFileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: GoogleSheetsFileControl;
  @Input() accessToken: string;
  @Input() delete = false;
  @Input() deleteDisabled = false;
  @Output() deleted = new EventEmitter<void>();
  @Output() updating = new EventEmitter<boolean>();

  @ViewChild(SelectComponent) selectComponent: SelectComponent;

  createField = createFormFieldFactory();
  fileSubscriptions: Subscription[] = [];
  sheetOptions: Option<SheetValue>[] = [];
  sheetSubscription: Subscription;
  rangeSubscription: Subscription;
  fileLoading$ = new BehaviorSubject<boolean>(false);
  sheetLoading$ = new BehaviorSubject<boolean>(false);
  rangeLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private zone: NgZone, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(this.fileLoading$, this.sheetLoading$, this.rangeLoading$)
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(loadings => {
        const loading = loadings.some(item => item);
        this.updating.emit(loading);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      this.cd.markForCheck();
      this.updateSheetOptions();
      this.addFileObserver();
      this.openFileDropdownIfNotSelected();
    }

    if (changes['accessToken']) {
      this.updateSheetOptions();
    }
  }

  get isEditable() {
    return this.form.controls.file.value && !this.fileLoading$.value && !this.sheetLoading$.value;
  }

  openFileDropdownIfNotSelected() {
    this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(() => {
      if (this.form && !this.form.controls.file.value && this.selectComponent) {
        this.selectComponent.optionsComponent.open();
      }
    });
  }

  sheetOptionsEquals(lhs: SheetValue, rhs: SheetValue) {
    const lhsName = lhs ? lhs.name : undefined;
    const rhsName = rhs ? rhs.name : undefined;

    return lhsName == rhsName;
  }

  numberToChar(number: number) {
    if (number >= 1 && number <= 26) {
      return String.fromCharCode(96 + number).toUpperCase();
    }
  }

  indexToLetters(index: number) {
    // TODO: Wrong calculations after 676
    const n = index != 0 ? Math.ceil(LOG(index + 1, 26)) : 1;
    return range(n)
      .map(r => {
        const l = (Math.floor(index / Math.pow(26, r)) % 26) + 1 - r;
        return this.numberToChar(l);
      })
      .reverse()
      .join('');

    // const chars = Number(index).toString(26).split('');
    // const result = chars
    //   .map((item, i) => {
    //     return numberToChar(parseInt(item, 26) + 1);
    //   })
    //   .join('');
  }

  getSheetDefaultRange(sheet: GoogleDriveSpreadsheetSheet): string {
    let defaultRange: string;

    for (const data of sheet.data) {
      if (!data.rowData) {
        continue;
      }

      for (let row = 0; row < data.rowData.length; ++row) {
        const rowData = data.rowData[row];

        if (!rowData.values) {
          continue;
        }

        const indexes = rowData.values.map((item, column) =>
          item.hasOwnProperty('formattedValue') ? column : undefined
        );

        if (indexes.every(item => item === undefined)) {
          continue;
        }

        const firstColumn = indexes.find(item => item !== undefined);
        const lastColumn = indexes
          .slice()
          .reverse()
          .find(item => item !== undefined);

        const fromChar = this.indexToLetters(firstColumn);
        const toChar = this.indexToLetters(lastColumn);

        if (row == 0) {
          defaultRange = `${fromChar}:${toChar}`;
        } else {
          const maxRows = 9999;
          defaultRange = `${fromChar}${row + 1}:${toChar}${row + maxRows}`;
        }
        break;
      }
    }

    return defaultRange;
  }

  getSheetDefaults(result: GoogleDriveSpreadsheet): { sheetIndex: number } {
    const defaultSheet = result.sheets[0];
    const defaultSheetIndex = defaultSheet ? 0 : undefined;

    return {
      sheetIndex: defaultSheetIndex
    };
  }

  addFileObserver() {
    if (this.fileSubscriptions.length) {
      this.fileSubscriptions.forEach(item => item.unsubscribe());
    }

    this.fileSubscriptions = [];

    this.fileSubscriptions.push(
      controlValue<string>(this.form.controls.file_url)
        .pipe(
          distinctUntilChanged(),
          skip(1),
          switchMap(fileUrl => this.getFileByUrl(fileUrl)),
          untilDestroyed(this)
        )
        .subscribe(file => this.form.controls.file.patchValue(file))
    );

    this.fileSubscriptions.push(
      controlValue<GoogleDriveFile>(this.form.controls.file)
        .pipe(
          map(item => (item ? item.id : undefined)),
          distinctUntilChanged(),
          skip(1),
          delay(0),
          untilDestroyed(this)
        )
        .subscribe(() => this.updateSheetOptions(true))
    );

    this.fileSubscriptions.push(
      combineLatest(
        controlValue<GoogleDriveFile>(this.form.controls.file),
        controlValue<SheetValue>(this.form.controls.sheet)
      )
        .pipe(
          map(([file, sheet]) => ({
            file: file ? file.id : undefined,
            sheet: sheet ? sheet.index : undefined
          })),
          distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
          skip(1),
          delay(0),
          untilDestroyed(this)
        )
        .subscribe(() => this.updateSheetRange(true))
    );
  }

  getFileByUrl(fileUrl: string): Observable<GoogleDriveFile> {
    const sheet = parseSheetUrl(fileUrl);

    if (!sheet || !this.accessToken) {
      return of(undefined);
    }

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheet.id}`;
    const headers = {
      Authorization: `Bearer ${this.accessToken}`
    };

    this.fileLoading$.next(true);
    this.cd.markForCheck();

    return this.http
      .get<GoogleDriveSpreadsheet>(url, { headers: headers })
      .pipe(
        switchMap(result => {
          const sheetName = result.sheets[0].properties.title;
          const updateUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheet.id}/values/'${encodeURIComponent(
            sheetName
          )}'!A:A:append`;
          const updateParams = {
            valueInputOption: 'raw'
          };
          const data = { values: [] };

          return this.http.post(updateUrl, data, { headers: headers, params: updateParams }).pipe(
            map(() => true),
            catchError(() => of(false)),
            map(canEdit => {
              return {
                id: sheet.id,
                name: result.properties.title,
                capabilities: {
                  canEdit: canEdit
                }
              } as GoogleDriveFile;
            })
          );
        }),
        tap(() => {
          this.fileLoading$.next(false);
          this.cd.markForCheck();
        }),
        catchError(error => {
          this.fileLoading$.next(false);
          this.cd.markForCheck();

          return throwError(error);
        })
      );
  }

  updateSheetOptions(forceSheetUpdate = false) {
    if (this.sheetSubscription) {
      this.sheetSubscription.unsubscribe();
    }

    const file = this.form.controls.file.value as GoogleDriveFile;

    if (!file || !this.accessToken) {
      this.sheetOptions = [];
      this.cd.markForCheck();
      return;
    }

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${file.id}`;
    const headers = {
      Authorization: `Bearer ${this.accessToken}`
    };
    this.sheetLoading$.next(true);
    this.cd.markForCheck();

    this.sheetSubscription = this.http
      .get<GoogleDriveSpreadsheet>(url, { headers: headers })
      .pipe(untilDestroyed(this))
      .subscribe(
        (result: GoogleDriveSpreadsheet) => {
          this.sheetOptions = result.sheets.map((item, i) => {
            return {
              name: item.properties.title,
              value: { name: item.properties.title, index: i, sheetId: item.properties.sheetId }
            };
          });
          this.sheetSubscription = undefined;
          this.sheetLoading$.next(false);
          this.cd.markForCheck();

          const defaults = this.getSheetDefaults(result);

          if (!this.form.controls.verbose_name.value || forceSheetUpdate) {
            this.form.controls.verbose_name.patchValue(result.properties.title);
          }

          if ((!this.form.controls.sheet.value || forceSheetUpdate) && defaults.sheetIndex !== undefined) {
            this.form.controls.sheet.patchValue(this.sheetOptions[defaults.sheetIndex].value);
          }
        },
        () => {
          this.sheetOptions = [];
          this.sheetSubscription = undefined;
          this.sheetLoading$.next(false);
          this.cd.markForCheck();
        }
      );
  }

  updateSheetRange(forceRangeUpdate = false) {
    if (this.rangeSubscription) {
      this.rangeSubscription.unsubscribe();
    }

    const file = this.form.controls.file.value as GoogleDriveFile;
    const sheetValue = this.form.controls.sheet.value as SheetValue;

    if (!file || !sheetValue || !this.accessToken) {
      return;
    }

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${file.id}`;
    const headers = {
      Authorization: `Bearer ${this.accessToken}`
    };
    const httpParams = new HttpParams({
      fromObject: {
        includeGridData: 'true',
        ranges: `'${sheetValue.name}'!A1:Z1000`
      }
    });

    this.rangeLoading$.next(true);
    this.cd.markForCheck();

    this.rangeSubscription = this.http
      .get<GoogleDriveSpreadsheet>(url, { headers: headers, params: httpParams })
      .pipe(untilDestroyed(this))
      .subscribe(
        (result: GoogleDriveSpreadsheet) => {
          const sheet = sheetValue ? result.sheets[0] : undefined;
          const defaultRange = sheet ? this.getSheetDefaultRange(sheet) : undefined;

          if ((!this.form.controls.range.value || forceRangeUpdate) && defaultRange) {
            this.form.controls.range.patchValue(defaultRange);
          }

          this.rangeLoading$.next(false);
          this.cd.markForCheck();
        },
        () => {
          this.rangeSubscription = undefined;
          this.rangeLoading$.next(false);
          this.cd.markForCheck();
        }
      );
  }
}
