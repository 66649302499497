<app-popup2>
  <app-popup2-header>
    <app-popup2-title>Choose operation mode</app-popup2-title>
  </app-popup2-header>

  <app-page-block>
    <div class="field">
      <div class="button-selector button-selector_size_l">
        <div class="button-selector__item">
          <a
            href="javascript:void(0)"
            class="button-selector-item button-selector-item_color_orange button-selector-item_interactive"
            [class.button-selector-item_active]="control.value"
            (click)="control.patchValue(true)"
          >
            <app-sync-mode-image class="button-selector-item__image-block" [typeItem]="typeItem"></app-sync-mode-image>
            <div class="button-selector-item__tag background-color_orange_1">
              <span class="button-selector-item__tag-raw">β</span>eta
            </div>
            <div class="button-selector-item__list">
              <div class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Allow <strong>SQL queries</strong> with {{ typeItem?.label }}
                </div>
              </div>

              <div class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  <strong>Blend and join</strong> data from over 30 data sources
                </div>
              </div>

              <div
                *ngIf="
                  [
                    resourceNames.Airtable,
                    resourceNames.SmartSuite,
                    resourceNames.Firebase,
                    resourceNames.GoogleSheets
                  ].includes($any(typeItem.name))
                "
                class="button-selector-item__list-item"
              >
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Suitable for <strong>large volume data</strong> (100k+ records)
                </div>
              </div>

              <div *ngIf="typeItem.name == resourceNames.Xano" class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Suitable for <strong>large volume data</strong> (10+ records)
                </div>
              </div>

              <div class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Data is synced to <strong>Jet Tables</strong>
                  <ng-container
                    *ngIf="[resourceNames.Airtable, resourceNames.SmartSuite].includes($any(typeItem.name))"
                  >
                    every 15 minutes
                  </ng-container>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="button-selector__item">
          <a
            href="javascript:void(0)"
            class="button-selector-item button-selector-item_color_teal-blue button-selector-item_interactive"
            [class.button-selector-item_active]="!control.value"
            (click)="control.patchValue(false)"
          >
            <app-direct-mode-image
              class="button-selector-item__image-block"
              [typeItem]="typeItem"
            ></app-direct-mode-image>
            <!--            <div class="button-selector-item__tag button-selector-item__tag_position_top background-color_blue_1">-->
            <!--              has limitations-->
            <!--            </div>-->
            <div class="button-selector-item__list">
              <div
                *ngIf="[resourceNames.Airtable, resourceNames.SmartSuite].includes($any(typeItem.name))"
                class="button-selector-item__list-item"
              >
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Charts not supported
                </div>
              </div>

              <div
                *ngIf="
                  [
                    resourceNames.Airtable,
                    resourceNames.SmartSuite,
                    resourceNames.Firebase,
                    resourceNames.GoogleSheets
                  ].includes($any(typeItem.name))
                "
                class="button-selector-item__list-item"
              >
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Not suitable for <strong>large volume data</strong> (100k+ records)
                </div>
              </div>

              <div *ngIf="typeItem.name == resourceNames.Xano" class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  Not suitable for <strong>large volume data</strong> (10k+ records)
                </div>
              </div>

              <div class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  No sync delays
                </div>
              </div>

              <div class="button-selector-item__list-item">
                <div class="button-selector-item__list-item-left">
                  <span
                    class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                  ></span>
                </div>
                <div class="button-selector-item__list-item-main">
                  <strong>Direct requests</strong> to {{ typeItem?.label }}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </app-page-block>

  <app-popup2-footer>
    <app-page-block>
      <app-stack [align]="'right'">
        <button type="button" class="button popup-content__submit-item" (click)="close()">
          Cancel
        </button>

        <button type="button" class="button button_primary button_shadow popup-content__submit-item" (click)="submit()">
          <span class="button__label">Continue</span>
        </button>
      </app-stack>
    </app-page-block>
  </app-popup2-footer>
</app-popup2>
