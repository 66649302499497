var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { imageFieldCrops, ImageFieldType, imageFieldTypes } from '@modules/field-components';
import { imageFieldFits, ImageOutputFormat, Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValid } from '@shared';
export var validateAction = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value) {
        return of(null);
    }
    return parent.elementConfigurationService.isActionConfigured(control.value).pipe(map(function (configured) {
        if (!configured) {
            return { required: true };
        }
    }));
};
var CustomizeBarImageEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarImageEditForm, _super);
    function CustomizeBarImageEditForm(currentProjectStore, currentEnvironmentStore, elementConfigurationService) {
        var _this = _super.call(this, {
            value_input: new FieldInputControl({ name: 'value' }),
            output_format: new FormControl(ImageOutputFormat.Storage),
            storage: new FormControl(undefined),
            path: new FieldInputControl({ name: 'value' }),
            display_type: new FormControl(imageFieldTypes[0].value),
            fit: new FormControl(imageFieldFits[0].value),
            show_preview: new FormControl(false),
            lightbox: new FormControl(false),
            click_action: new FormControl(undefined, undefined, validateAction),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl()
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.elementConfigurationService = elementConfigurationService;
        _this.outputFormatOptions = [
            { value: ImageOutputFormat.URL, name: 'Specify URL' },
            { value: ImageOutputFormat.Storage, name: 'Save to Storage' }
        ];
        _this.displayTypeOptions = imageFieldTypes.filter(function (item) {
            return [ImageFieldType.Rectangular, ImageFieldType.Circular].includes(item.value);
        });
        _this.fitOptions = imageFieldFits;
        _this.imageFieldCrops = imageFieldCrops;
        return _this;
    }
    CustomizeBarImageEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var storage = this.currentProjectStore.instance.getStorage(this.currentEnvironmentStore.instance.uniqueName, element.storageResource, element.storageName, { defaultFirst: true });
        this.controls.value_input.patchValue(element.valueInput ? element.valueInput.serialize() : {});
        this.controls.output_format.patchValue(element.outputFormat || ImageOutputFormat.Storage);
        this.controls.storage.patchValue(storage ? { resource: storage.resource.uniqueName, name: storage.storage.uniqueName } : undefined);
        this.controls.path.patchValue(element.path ? element.path.serialize() : {});
        this.controls.display_type.patchValue(element.displayType || imageFieldTypes[0].value);
        this.controls.fit.patchValue(element.fit || imageFieldFits[0].value);
        this.controls.show_preview.patchValue(element.showPreview);
        this.controls.lightbox.patchValue(element.lightbox);
        this.controls.click_action.patchValue(element.clickAction);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});
        this.controls.margin.patchValue(element.margin);
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarImageEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isImageConfigured(instance);
    };
    CustomizeBarImageEditForm.prototype.controlsValid$ = function (controls) {
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarImageEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.click_action]);
    };
    CustomizeBarImageEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        if (this.controls.value_input.value) {
            instance.valueInput = new Input().deserialize(this.controls.value_input.value);
        }
        else {
            instance.valueInput = undefined;
        }
        instance.outputFormat = this.controls.output_format.value;
        if (this.controls.storage.value) {
            instance.storageResource = this.controls.storage.value['resource'];
            instance.storageName = this.controls.storage.value['name'];
        }
        instance.path = this.controls.path.value;
        instance.displayType = this.controls.display_type.value;
        instance.fit = this.controls.fit.value;
        instance.showPreview = this.controls.show_preview.value;
        instance.lightbox = this.controls.lightbox.value;
        if (this.controls.click_action.value) {
            instance.clickAction = this.controls.click_action.value;
        }
        else {
            instance.clickAction = undefined;
        }
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.margin = this.controls.margin.value;
        return instance;
    };
    return CustomizeBarImageEditForm;
}(FormGroup));
export { CustomizeBarImageEditForm };
