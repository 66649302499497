var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { createFormFieldFactory, getFieldDescriptionByType } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { isSet } from '@shared';
import { FilterEditForm } from './filter-edit.form';
var FilterEditComponent = /** @class */ (function () {
    function FilterEditComponent(form, cd) {
        this.form = form;
        this.cd = cd;
        this.filters = [];
        this.onlyVisible = false;
        this.theme = false;
        this.added = new EventEmitter();
        this.edited = new EventEmitter();
        this.deleted = new EventEmitter();
        this.filtersUpdated = new EventEmitter();
        this.loading = false;
        this.lookups = [];
        this.excludeLookupsSupported = false;
        this.createField = createFormFieldFactory();
    }
    FilterEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.form
            .init({
            dataSource: this.dataSource,
            filter: this.filter,
            field: this.field
        })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            combineLatest(_this.form.getLookups$(), _this.form.getValueField$())
                .pipe(untilDestroyed(_this))
                .subscribe(function (_a) {
                var lookups = _a[0], valueField = _a[1];
                _this.lookups = lookups.lookups;
                _this.excludeLookupsSupported = lookups.excludeSupported;
                if (valueField) {
                    var classes = valueField.params && valueField.params['classes'] ? valueField.params['classes'] : [];
                    _this.valueField = __assign({}, valueField, { params: __assign({}, valueField.params, { classes: ['select_fill', 'select_small', 'input_fill', 'input_small'].concat(classes), theme: true }) });
                }
                else {
                    _this.valueField = undefined;
                }
                _this.loading = false;
                _this.cd.markForCheck();
            }, function () {
                _this.loading = false;
                _this.cd.markForCheck();
            });
        });
        this.form
            .getField$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.fieldValue = value;
            _this.fieldValueDescription = value ? getFieldDescriptionByType(value.field.field) : undefined;
            _this.cd.markForCheck();
        });
    };
    FilterEditComponent.prototype.ngOnDestroy = function () { };
    FilterEditComponent.prototype.selectField = function (e) {
        if (!e.field) {
            return;
        }
        this.form.controls.field.patchValue({
            path: e.path.map(function (item) { return item.name; }),
            field: e.field
        });
    };
    FilterEditComponent.prototype.submit = function () {
        var _this = this;
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (filterItem) {
            if (!filterItem) {
                return;
            }
            var applyFilterItem = isSet(filterItem.value) ? filterItem : undefined;
            if (_this.filter) {
                var filters = _this.filters
                    .map(function (item) {
                    if (item === _this.filter) {
                        return applyFilterItem;
                    }
                    else {
                        return item;
                    }
                })
                    .filter(function (item) { return item; });
                _this.edited.emit(filterItem);
                _this.filtersUpdated.emit(filters);
            }
            else {
                var filters = _this.filters.slice();
                if (applyFilterItem) {
                    filters.push(applyFilterItem);
                }
                _this.added.emit(filterItem);
                _this.filtersUpdated.emit(filters);
            }
            return filterItem;
        });
    };
    FilterEditComponent.prototype.removeFilter = function () {
        var _this = this;
        var filters = this.filters.filter(function (item) { return item !== _this.filter; });
        this.deleted.emit(this.filter);
        this.filtersUpdated.emit(filters);
    };
    FilterEditComponent.prototype.addValueControl = function () {
        this.addedValueControl = this.form.controls.value_array.pushNew();
    };
    FilterEditComponent.prototype.removeValueControl = function (index) {
        this.form.controls.value_array.removeAt(index);
    };
    return FilterEditComponent;
}());
export { FilterEditComponent };
