import { ascComparator } from '@shared';

import { ResourceDeploy } from './resource-deploy';
import { ResourceName } from './resource-name';
import { ResourceType } from './resource-type';
import { ResourceTypeItem } from './resource-type-item';
import { ResourceTypeItemCategory } from './resource-type-item-category';

// Should be 4
// export const resourcesTop = [
//   ResourceName.Firebase,
//   ResourceName.GoogleSheets,
//   ResourceName.RestApi,
//   ResourceName.PostgreSQL
// ];

// Should be <= 4
export const resourcesCustom = [
  ResourceName.RestApi,
  ResourceName.GraphQL,
  ResourceName.Zapier,
  ResourceName.Integromat
];

export const resourcesOrder = [
  ResourceName.Firebase,
  ResourceName.GoogleSheets,
  ResourceName.RestApi,
  ResourceName.PostgreSQL,
  ResourceName.Airtable,
  ResourceName.JetDatabase,
  ResourceName.MySQL,
  ResourceName.GraphQL,
  ResourceName.Supabase,
  ResourceName.SupabaseStorage,
  ResourceName.Xano,
  ResourceName.BigQuery,
  ResourceName.OpenAI,
  ResourceName.SmartSuite,
  ResourceName.Snowflake,
  ResourceName.Django,
  ResourceName.Stripe,
  ResourceName.Salesforce,
  ResourceName.Mixpanel,
  ResourceName.Zapier,
  ResourceName.Integromat,
  ResourceName.MicrosoftSQL,
  ResourceName.Redshift,
  ResourceName.AlloyDB,
  ResourceName.MariaDB,
  ResourceName.Oracle,
  ResourceName.JetApp,
  ResourceName.AmazonS3,
  ResourceName.DigitalOceanSpaces,
  ResourceName.S3Compatible
];

export const resourceTypeItems: ResourceTypeItem[] = [
  {
    name: ResourceName.JetApp,
    categories: [ResourceTypeItemCategory.APIs],
    resourceType: ResourceType.JetBridge,
    label: 'App built-ins',
    shortDescription: 'Current App',
    featuresDescription: 'Manage users and teams',
    image: 'jet-database',
    icon: 'jet-database',
    color: '#2B50ED',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    protected: true
  },
  {
    name: ResourceName.PostgreSQL,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'PostgreSQL',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from PostgreSQL, manipulate with your tables: create, update, delete',
    image: 'postgresql',
    icon: 'postgresql',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#346791',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.MySQL,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'MySQL',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from MySQL, manipulate with your tables: create, update, delete',
    image: 'mysql',
    icon: 'mysql',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#01668f',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.MariaDB,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'MariaDB',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from MariaDB, manipulate with your tables: create, update, delete',
    image: 'mariadb',
    icon: 'mariadb',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#003545',
    iconBackgroundColor: '#fff',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.MicrosoftSQL,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'Microsoft SQL',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from Microsoft SQL, manipulate with your tables: create, update, delete',
    image: 'mssql',
    icon: 'mssql',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#a91e22',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Oracle,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'Oracle',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from Oracle, manipulate with your tables: create, update, delete',
    image: 'oracle',
    icon: 'oracle',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#f80000',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.BigQuery,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'BigQuery',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from BigQuery, manipulate with your tables: create, update, delete',
    image: 'bigquery',
    icon: 'bigquery',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#4386FA',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Snowflake,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'Snowflake',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from Snowflake, manipulate with your tables: create, update, delete',
    image: 'snowflake',
    icon: 'snowflake',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#29B5E8',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Redshift,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'Amazon Redshift',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from Amazon Redshift, manipulate with your tables: create, update, delete',
    image: 'redshift',
    icon: 'redshift',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#2D72B8',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.AlloyDB,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'AlloyDB',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from AlloyDB, manipulate with your tables: create, update, delete',
    image: 'alloydb',
    icon: 'alloydb',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#4285F4',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Supabase,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'Supabase',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from Supabase, manipulate with your tables: create, update, delete',
    image: 'supabase',
    icon: 'supabase',
    deployTypes: [ResourceDeploy.Direct, ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#3ECF8E',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.SupabaseStorage,
    categories: [ResourceTypeItemCategory.Storages],
    label: 'Supabase Storage',
    shortDescription: 'Store and access data',
    featuresDescription: 'Store Files',
    resourceType: ResourceType.SupabaseStorage,
    image: 'supabase',
    icon: 'supabase',
    color: '#3ECF8E',
    storagesEnabled: true
  },
  {
    name: ResourceName.Xano,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.Xano,
    label: 'Xano',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from Xano, manipulate with your tables: create, update, delete',
    image: 'xano',
    icon: 'xano',
    color: '#0e99e1',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.SQLite,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'SQLite',
    shortDescription: 'Database',
    featuresDescription: 'Import all tables from SQLite, manipulate with your tables: create, update, delete',
    image: 'sqlite',
    icon: 'sqlite',
    deployTypes: [ResourceDeploy.Docker, ResourceDeploy.Python],
    instructions: true,
    color: '#0e80cc',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.JetDatabase,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.JetBridge,
    label: 'Jet Tables',
    shortDescription: 'Database',
    featuresDescription: 'Store tables in Jet Tables, manipulate with your tables: create, update, delete',
    image: 'jet-database',
    icon: 'jet-database',
    deployTypes: [ResourceDeploy.Direct],
    color: '#2B50ED',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: true
  },
  // {
  //   name: ResourceName.DynamoDB,
  //   categories: [ResourceTypeItemCategory.Databases],
  //   resourceType: ResourceType.JetBridge,
  //   label: 'DynamoDB',
  //   shortDescription: 'Database',
  //   featuresDescription: 'Import all tables from DynamoDB , manipulate with your tables: create, update, delete',
  //   image: 'dynamodb',
  //   icon: 'dynamodb',
  //   hasAutoCollections: true,
  //   modelsEnabled: true,
  //   actionsEnabled: true,
  //   storagesEnabled: true,
  //   chartsEnabled: true
  // },
  {
    name: ResourceName.Firebase,
    categories: [ResourceTypeItemCategory.Databases, ResourceTypeItemCategory.Storages],
    resourceType: ResourceType.Firebase,
    label: 'Firebase',
    shortDescription: 'Database',
    featuresDescription:
      'Import all collections from Firebase, manipulate with your collections: create, update, delete',
    image: 'firebase',
    icon: 'firebase',
    color: '#FFA610',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true,
    syncEnabled: true,
    syncRunInterval: 60 * 24
  },
  {
    name: ResourceName.Airtable,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.Airtable,
    label: 'Airtable',
    shortDescription: 'Database',
    featuresDescription:
      'Import all collections from Airtable, manipulate with your collections: create, update, delete',
    image: 'airtable',
    icon: 'airtable',
    color: '#FCB400',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 60
  },
  {
    name: ResourceName.SmartSuite,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: ResourceType.SmartSuite,
    label: 'SmartSuite',
    shortDescription: 'Database',
    featuresDescription:
      'Import all collections from SmartSuite, manipulate with your collections: create, update, delete',
    image: 'smartsuite',
    icon: 'smartsuite',
    color: '#3a86ff',
    beta: true,
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.MongoDB,
    categories: [ResourceTypeItemCategory.Databases],
    resourceType: null,
    label: 'MongoDB',
    shortDescription: 'Database',
    featuresDescription:
      'Import all collections from MongoDB, manipulate with your collections: create, update, delete',
    image: 'mongo',
    beta: true,
    hidden: true,
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Django,
    categories: [ResourceTypeItemCategory.Frameworks],
    resourceType: ResourceType.JetBridge,
    label: 'Django',
    shortDescription: 'Framework',
    featuresDescription: 'Import all models from Django, manipulate with your models: create, update, delete',
    image: 'django',
    icon: 'django',
    instructions: true,
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Stripe,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Stripe',
    shortDescription: 'Payment System',
    featuresDescription: 'Monitor payments, manage user subscriptions, and refund money.',
    resourceType: ResourceType.RestAPI,
    image: 'stripe',
    icon: 'stripe',
    color: '#6672e5',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.GoogleSheets,
    categories: [ResourceTypeItemCategory.Databases],
    label: 'Google Sheets',
    shortDescription: 'Spreadsheets',
    featuresDescription:
      'Import all spreadsheets from Google Sheets, manipulate with your spreadsheets: create, update, delete',
    resourceType: ResourceType.RestAPI,
    image: 'googlesheets',
    icon: 'googlesheets',
    color: '#22A565',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: true,
    syncEnabled: true,
    syncRunInterval: 60
  },
  {
    name: ResourceName.Twilio,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Twilio',
    shortDescription: 'SMS and Calls',
    featuresDescription: 'Send SMS messages and make Calls',
    resourceType: ResourceType.RestAPI,
    image: 'twilio',
    icon: 'twilio',
    color: '#f23046',
    actionsEnabled: true
  },
  {
    name: ResourceName.SendGrid,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'SendGrid',
    shortDescription: 'Emails delivery',
    featuresDescription: 'Send Emails',
    resourceType: ResourceType.RestAPI,
    image: 'sendgrid',
    icon: 'sendgrid',
    color: '#00b3e3',
    actionsEnabled: true
  },
  {
    name: ResourceName.OpenAI,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'OpenAI',
    shortDescription: 'Natural language queries',
    featuresDescription:
      'Perform natural language and code tasks like semantic search, clustering, topic modeling, and classification.',
    resourceType: ResourceType.RestAPI,
    image: 'openai',
    icon: 'openai',
    color: '#000',
    iconBackgroundColor: '#fff',
    actionsEnabled: true
  },
  {
    name: ResourceName.Zendesk,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Zendesk',
    shortDescription: 'Support System',
    featuresDescription: 'Manage tickets, users',
    resourceType: ResourceType.RestAPI,
    image: 'zendesk',
    icon: 'zendesk',
    color: '#03363d',
    iconBackgroundColor: '#fff',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.HubSpot,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'HubSpot',
    shortDescription: 'CRM',
    featuresDescription: 'Manage contacts, companies, deals, and engagements',
    resourceType: ResourceType.RestAPI,
    image: 'hubspot',
    icon: 'hubspot',
    color: '#ff7958',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.Salesforce,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Salesforce',
    shortDescription: 'CRM',
    featuresDescription: 'Manage contacts, companies, deals, and engagements',
    resourceType: ResourceType.RestAPI,
    image: 'salesforce',
    icon: 'salesforce',
    color: '#00a1df',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false
  },
  {
    name: ResourceName.Pipedrive,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Pipedrive',
    shortDescription: 'CRM',
    featuresDescription: 'Manage contacts, companies, deals, and engagements',
    resourceType: ResourceType.RestAPI,
    image: 'pipedrive',
    icon: 'pipedrive',
    color: '#203332',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.Slack,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Slack',
    shortDescription: 'Communication platform',
    featuresDescription: 'Send messages and notifications to channels',
    resourceType: ResourceType.RestAPI,
    image: 'slack',
    icon: 'slack',
    color: '#ecb22e',
    actionsEnabled: true
  },
  {
    name: ResourceName.Mixpanel,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Mixpanel',
    shortDescription: 'Product Analytics',
    featuresDescription: 'Track customer activities and events',
    resourceType: ResourceType.RestAPI,
    image: 'mixpanel',
    icon: 'mixpanel',
    color: '#33547D',
    hasAutoCollections: true,
    modelsEnabled: true,
    chartsEnabled: false
  },
  {
    name: ResourceName.Amplitude,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Amplitude',
    shortDescription: 'Product Analytics',
    featuresDescription: 'Track customer activities and events',
    resourceType: ResourceType.RestAPI,
    image: 'amplitude',
    icon: 'amplitude',
    color: '#007FD2',
    hasAutoCollections: true,
    modelsEnabled: true,
    chartsEnabled: false
  },
  {
    name: ResourceName.Intercom,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Intercom',
    shortDescription: 'Conversational Platform',
    featuresDescription: 'Access all your conversations and customers',
    resourceType: ResourceType.RestAPI,
    image: 'intercom',
    icon: 'intercom',
    color: '#1F8DED',
    hasAutoCollections: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.Mailchimp,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Mailchimp',
    shortDescription: 'Emails delivery',
    featuresDescription: 'Send Emails',
    resourceType: ResourceType.RestAPI,
    image: 'mailchimp',
    icon: 'mailchimp',
    color: '#000000',
    iconBackgroundColor: '#fff',
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false,
    syncEnabled: true,
    syncRunInterval: 15
  },
  {
    name: ResourceName.Mailgun,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Mailgun',
    shortDescription: 'Emails delivery',
    featuresDescription: 'Send Emails',
    resourceType: ResourceType.RestAPI,
    image: 'mailgun',
    icon: 'mailgun',
    color: '#AF252A',
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: false
  },
  {
    name: ResourceName.AmazonS3,
    categories: [ResourceTypeItemCategory.Storages],
    label: 'Amazon S3',
    shortDescription: 'Store and access data',
    featuresDescription: 'Store Files',
    resourceType: ResourceType.AmazonS3,
    image: 'amazon-s3',
    icon: 'amazon-s3',
    color: '#E15343',
    storagesEnabled: true
  },
  {
    name: ResourceName.DigitalOceanSpaces,
    categories: [ResourceTypeItemCategory.Storages],
    label: 'DigitalOcean Spaces',
    shortDescription: 'Store and access data',
    featuresDescription: 'Store Files',
    resourceType: ResourceType.AmazonS3,
    image: 'digital-ocean',
    icon: 'digital-ocean',
    color: '#0061eb',
    storagesEnabled: true
  },
  {
    name: ResourceName.S3Compatible,
    categories: [ResourceTypeItemCategory.Storages],
    label: 'S3 Compatible',
    shortDescription: 'Store and access data',
    featuresDescription: 'Store Files',
    resourceType: ResourceType.AmazonS3,
    image: 's3-compatible',
    icon: 'amazon-s3',
    color: '#E15343',
    storagesEnabled: true
  },
  {
    name: ResourceName.GoogleCloudStorage,
    categories: [ResourceTypeItemCategory.Storages],
    label: 'Google Cloud Storage',
    shortDescription: 'Store and access data',
    featuresDescription: 'Store Files',
    resourceType: ResourceType.RestAPI,
    image: 'google-cloud-storage',
    icon: 'google-cloud-storage',
    color: '#4387FD',
    storagesEnabled: true
  },
  {
    name: ResourceName.AzureBlob,
    categories: [ResourceTypeItemCategory.Storages],
    label: 'Azure Blob Storage',
    shortDescription: 'Store and access data',
    featuresDescription: 'Store Files',
    resourceType: ResourceType.AzureBlob,
    image: 'azure',
    icon: 'azure',
    color: '#0089D6',
    storagesEnabled: true
  },
  {
    name: ResourceName.RestApi,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Rest API',
    shortDescription: 'Custom Rest API',
    resourceType: ResourceType.RestAPI,
    image: 'api',
    icon: 'api',
    installConfigurationNeeded: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.GraphQL,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'GraphQL',
    shortDescription: 'GraphQL resource',
    resourceType: ResourceType.GraphQL,
    image: 'graphql',
    icon: 'graphql',
    installConfigurationNeeded: true,
    modelsEnabled: true,
    actionsEnabled: true,
    storagesEnabled: true,
    chartsEnabled: true
  },
  {
    name: ResourceName.Zapier,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Zapier',
    shortDescription: 'Automate workflows',
    resourceType: ResourceType.RestAPI,
    image: 'zapier',
    icon: 'zapier',
    color: '#FF4A00',
    installConfigurationNeeded: true,
    actionsEnabled: true,
    requestName: 'Webhook request'
  },
  {
    name: ResourceName.Integromat,
    categories: [ResourceTypeItemCategory.APIs],
    label: 'Integromat',
    shortDescription: 'Automate workflows',
    resourceType: ResourceType.RestAPI,
    image: 'integromat',
    icon: 'integromat',
    color: '#1578B0',
    installConfigurationNeeded: true,
    actionsEnabled: true,
    requestName: 'Webhook request'
  },
  {
    name: ResourceName.MessagesApi,
    categories: [ResourceTypeItemCategory.APIs],
    resourceType: ResourceType.MessagesAPI,
    label: 'Jet Messages API',
    shortDescription: 'Jet Admin protocol',
    image: 'api',
    icon: 'jet',
    hidden: true,
    modelsEnabled: true,
    actionsEnabled: true,
    chartsEnabled: true
  }
].sort((lhs, rhs) => {
  const lhsIndex = resourcesOrder.indexOf(lhs.name);
  const rhsIndex = resourcesOrder.indexOf(rhs.name);

  if (lhsIndex != -1 && rhsIndex != -1) {
    return lhsIndex - rhsIndex;
  } else if (lhsIndex != -1 && rhsIndex == -1) {
    return -1;
  } else if (lhsIndex == -1 && rhsIndex != -1) {
    return 1;
  } else {
    return ascComparator(lhs.label, rhs.label);
  }
});
