<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (nameEditable ? 'map' : 'Map')"
    [titleEnabled]="nameEnabled"
    [titleEditable]="nameEditable"
    [titleCleanValue]="titleCleanValue"
    [backEnabled]="backEnabled"
    [backLabel]="'All components'"
    [subtitle]="'List'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (titleChanged)="onTitleChanged($event)"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'" [error]="(dataValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="form.controls.data_source"
              [context]="context"
              [contextElement]="contextElement"
              [error]="(dataValid$ | async) !== false ? undefined : 'Not configured'"
              [classes]="'component-data-button'"
              (editClick)="editDataSource()"
              (addInputClick)="editDataSource({ addInput: true })"
            >
            </app-model-description-data-source-button>
          </div>

          <div *ngIf="queryConfigured$ | async" class="sidebar__element">
            <app-custom-view-item-edit
              [control]="form.controls.card_view"
              [mappingsControl]="form.controls.card_view_mappings"
              [sourceParameters]="columnParameters$ | async"
              [subtitle]="'Card component'"
              [deleteEnabled]="true"
              [componentLabel]="'card'"
              [initialTemplatesFilter]="{ type: customViewTemplateTypes.ListItem }"
              [loading]="form.submitLoading$ | async"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="itemContextElementPath"
              [analyticsSource]="element.analyticsGenericName"
            ></app-custom-view-item-edit>
          </div>
        </app-sidebar-section>

        <app-sidebar-section *ngIf="queryConfigured$ | async" [title]="'Card fields'">
          <ng-container header-left>
            <div
              class="sidebar-section__title-icon sidebar-section__title-icon_clickable sidebar-section__title-action icon-search"
              [class.sidebar-section__title-icon_active]="columnsSearch"
              (mousedown)="toggleSearch()"
              [appTip]="'Search'"
              [appTipOptions]="{ side: 'top' }"
            ></div>
          </ng-container>

          <ng-container header-right>
            <div class="sidebar-section__header-links">
              <a class="sidebar__link" href="javascript:void(0)" (click)="resetColumns()">
                Reset
              </a>

              <ng-container *ngIf="fieldsComponent.hasMultipleItems()">
                <span class="sidebar-section__header-separator"></span>
                <a class="sidebar__link" href="javascript:void(0)" (click)="fieldsComponent.toggleAll()">
                  <span *ngIf="fieldsComponent.isToggledAll()" class="button__label">Deselect All</span>
                  <span *ngIf="!fieldsComponent.isToggledAll()" class="button__label">Select All</span>
                </a>
              </ng-container>
            </div>
          </ng-container>

          <div class="sidebar__list">
            <app-display-fields-edit
              [form]="form.controls.data_source.controls.columns"
              [fieldActionsControl]="form.controls.columns_actions"
              [resource]="resource"
              [modelDescription]="modelDescription"
              [componentName]="'card'"
              [element]="element"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="itemContextElementPath"
              [searchEnabled]="columnsSearch"
              [searchFocus]="columnsSearch"
              [visibleEditable]="!form.controls.card_view.value"
              [customViewEnabled]="!form.controls.card_view.value"
              [firstInit]="firstInit"
              [analyticsSource]="element.analyticsGenericName"
              (searchCleared)="setColumnsSearch(false)"
              #fieldsComponent
            >
            </app-display-fields-edit>
          </div>
        </app-sidebar-section>

        <app-related-model-components
          *ngIf="modelDescription"
          [modelDescription]="modelDescription"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementModelOutput]="selectedItemContextElementPath"
          [collapseContext]="collapseContext"
        ></app-related-model-components>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'map title'"
            [required]="true"
            [itemForm]="form.controls.title"
            [textStyleControl]="form.controls.element_styles.controls.title_style"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_title'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'load maximum markers'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'per_page',
                  field: 'IntegerField',
                  placeholder: 'Default is 20',
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'marker color'">
            <ng-container actions>
              <span
                class="sidebar__field-action sidebar__field-action_button icon-function"
                [class.sidebar__field-action_active]="form.controls.marker_color_input_enabled.value"
                [appTip]="form.controls.marker_color_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
                [appTipOptions]="{ side: 'top' }"
                (click)="
                  form.controls.marker_color_input_enabled.patchValue(!form.controls.marker_color_input_enabled.value)
                "
              ></span>
            </ng-container>

            <app-auto-field
              *ngIf="!form.controls.marker_color_input_enabled.value"
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'marker_color',
                  field: 'ColorField',
                  required: false,
                  params: { classes: ['select_fill'], allow_empty: true, empty_accent_color: true, custom_colors: true }
                })
              "
            ></app-auto-field>

            <app-input-edit
              *ngIf="form.controls.marker_color_input_enabled.value"
              [itemForm]="form.controls.marker_color_input"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="itemContextElementPath"
              [staticValueDisabled]="true"
              [formulaPlaceholder]="'Color with Formula'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_marker_color'"
            ></app-input-edit>

            <ng-container *ngIf="form.controls.marker_color_input_enabled.value" description>
              Example: IF(item.amount >= 400, 'red', 'blue')
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'marker size'">
            <ng-container actions>
              <span
                class="sidebar__field-action sidebar__field-action_button icon-function"
                [class.sidebar__field-action_active]="form.controls.marker_size_input_enabled.value"
                [appTip]="form.controls.marker_size_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
                [appTipOptions]="{ side: 'top' }"
                (click)="
                  form.controls.marker_size_input_enabled.patchValue(!form.controls.marker_size_input_enabled.value)
                "
              ></span>
            </ng-container>

            <app-slider
              *ngIf="!form.controls.marker_size_input_enabled.value"
              [baseControl]="form.controls.marker_size"
              [min]="8"
              [max]="44"
              [normal]="26"
              [orange]="true"
              [thumbInside]="true"
            ></app-slider>

            <app-input-edit
              *ngIf="form.controls.marker_size_input_enabled.value"
              [itemForm]="form.controls.marker_size_input"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="itemContextElementPath"
              [staticValueDisabled]="true"
              [formulaPlaceholder]="'Size with Formula'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_marker_size'"
            ></app-input-edit>

            <ng-container *ngIf="form.controls.marker_size_input_enabled.value" description>
              Example: IF(item.amount >= 400, 36, 26)
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Fit markers in viewport on load'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'markers_fit_on_change',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'viewport center'"
            [itemForm]="form.controls.location_input"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [staticValueField]="fieldTypes.Location"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_location_input'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'viewport zoom'"
            [itemForm]="form.controls.zoom_input"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [staticValueField]="fieldTypes.Number"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_zoom_input'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'enable search'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'search_enabled',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div *ngIf="form.controls.search_enabled.value" class="sidebar__element">
          <app-sidebar-field [label]="'update results while typing'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'search_live',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'display filters'" [layoutLeft]="true">
            <app-toggle
              *ngIf="!form.controls.display_filters.value"
              [selected]="false"
              [orange]="true"
              (click)="form.controls.display_filters.patchValue(true); form.controls.filter_fields.enableDefault()"
            ></app-toggle>

            <app-toggle
              *ngIf="form.controls.display_filters.value"
              [selected]="true"
              [orange]="true"
              (click)="form.controls.display_filters.patchValue(false)"
            ></app-toggle>

            <ng-container actions>
              <ng-container *ngIf="form.controls.display_filters.value">
                <app-option-enabled-edit-overlay
                  [control]="form.controls.filter_fields"
                  [dataSourceControl]="form.controls.data_source"
                  [emptyMessage]="'Nothing to filter'"
                  [origin]="filter_fields_edit_origin"
                  #filter_fields_edit_overlay="appOptionEnabledEditOverlay"
                ></app-option-enabled-edit-overlay>

                <span
                  class="sidebar__field-action sidebar__field-action_button icon-gear_2"
                  [class.sidebar__field-action_active]="filter_fields_edit_overlay.isOpened()"
                  [appTip]="'Customize Enabled filters'"
                  [appTipOptions]="{ side: 'top' }"
                  cdkOverlayOrigin
                  #filter_fields_edit_origin="cdkOverlayOrigin"
                ></span>
              </ng-container>
            </ng-container>
          </app-sidebar-field>
        </div>

        <div *ngIf="visibleEditable" class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="settings.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="visibleContextElementPath"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'tooltip'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tooltip',
                  field: 'CharField',
                  placeholder: 'Show information for users',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div *ngIf="elementStylesEditable" class="sidebar__element">
          <app-styles-list-element [control]="form.controls.element_styles"></app-styles-list-element>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When card clicked'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-action-single-edit
                  [options]="{ originEnabled: true }"
                  [title]="'Card click'"
                  [control]="form.controls.card_click_action"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPath]="itemContextElementPath"
                  [defaultSubtitle]="'Not specified'"
                  [remove]="form.controls.card_click_action.value"
                  [icon]="'cursor'"
                  [object]="settings.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-action-single-edit>
              </div>
            </div>

            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'Card actions'"></app-sidebar-list-separator>
            </div>

            <ng-container *ngIf="!form.getCardView()">
              <app-actions-edit
                [options]="{
                  actionItemClass: viewSettingsActionClass,
                  nameEditable: true,
                  iconEditable: true,
                  styleEditable: true,
                  colorsEditable: true,
                  approveEnabled: true,
                  confirmationEnabled: true,
                  completionEditable: true,
                  disabledEditable: true,
                  visibleEditable: true,
                  originEnabled: true,
                  elementStylesEditable: true,
                  elementStylesMarginEditable: false
                }"
                [control]="form.controls.model_actions"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="itemContextElementPath"
                [emptyAction]="'Card action'"
                [emptyActionValue]="'Not specified'"
                [emptyIcon]="'duplicate_2'"
                [actionLabel]="'Card click'"
                [object]="settings.analyticsGenericName"
                [firstInit]="firstInit"
              >
              </app-actions-edit>
            </ng-container>

            <ng-container *ngIf="form.getCardView() as view">
              <div class="sidebar-list">
                <div *ngFor="let output of view.actions" class="sidebar-list__item">
                  <app-action-single-edit
                    *ngIf="form.controls.custom_actions.controls[output.name] as control"
                    [title]="output.verboseName"
                    [control]="control"
                    [context]="context"
                    [contextElement]="contextElement"
                    [contextElementPath]="itemContextElementPath"
                    [defaultSubtitle]="'Not specified'"
                    [remove]="control.value"
                    [icon]="output.icon || 'power'"
                    [object]="element.analyticsName"
                    [firstInit]="firstInit"
                  >
                  </app-action-single-edit>
                </div>

                <div *ngIf="!view.actions.length" class="sidebar__message">
                  There are no actions in component
                </div>
              </div>
            </ng-container>

            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'Header actions'"></app-sidebar-list-separator>
            </div>
            <app-actions-edit
              [options]="{
                actionItemClass: viewSettingsActionClass,
                nameEditable: true,
                iconEditable: true,
                styleEditable: true,
                colorsEditable: true,
                approveEnabled: true,
                confirmationEnabled: true,
                completionEditable: true,
                disabledEditable: true,
                visibleEditable: true,
                originEnabled: true,
                elementStylesEditable: true,
                elementStylesMarginEditable: false
              }"
              [control]="form.controls.actions"
              [context]="context"
              [contextElement]="contextElement"
              [pin]="true"
              [emptyAction]="'Header action'"
              [emptyActionValue]="'Not specified'"
              [emptyIcon]="'fileds'"
              [actionLabel]="'Header action click'"
              [object]="settings.analyticsGenericName"
              [firstInit]="firstInit"
            >
            </app-actions-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
