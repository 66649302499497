<div
  class="list"
  [class.list_scrollable]="scrollable"
  [class.list_theme]="theme"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters || []"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      [theme]="theme"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>

    <div *ngIf="scrollable" class="list__fixed-content">
      <div class="list__fixed-content-inner" #fixed_table>
        <table *ngIf="loading" class="table">
          <thead
            app-table-stub-content-header
            [columns]="columnsCount"
            [animating]="true"
            [scrollable]="scrollable"
          ></thead>
        </table>

        <table *ngIf="!loading && configured" class="table" [class.table_selection]="isAnyChecked">
          <thead
            app-table-header
            [state]="listState"
            [params]="params"
            [filters]="listState.filters || []"
            [sort]="listState.sort || []"
            [settings]="settings"
            [dataSource]="listState.dataSource"
            [inputs]="inputs"
            [parameters]="parameters"
            [customizing]="customizing"
            [columns]="visibleColumns"
            [context]="context"
            [contextElement]="contextElement"
            [theme]="theme"
            (paramsChanged)="paramsChanged.emit($event)"
            (filtersUpdated)="onFiltersUpdated($event)"
            (sortChanged)="onSortUpdated($event)"
            (settingsChanged)="settingsChanged.emit($event)"
            (columnResize)="onColumnResize($event.index, $event.width)"
            (columnResizeFinished)="onColumnResizeFinished($event.index, $event.width)"
            #table_header_fixed
          ></thead>
        </table>
      </div>
      <div
        *ngIf="scrollable"
        class="list__fixed-content-shadow"
        [class.list__fixed-content-shadow_visible]="false"
      ></div>
    </div>
  </div>

  <div class="list__content list__content_border">
    <app-table-actions
      [listTitle]="title"
      [settings]="settings"
      [selectedItems]="checkedItems"
      [selectedInverse]="checkedInverse"
      [displayItems]="totalVisibleItems"
      [totalItems]="totalItems"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      [theme]="theme"
      (selectAllRequested)="checkAll()"
      (deselectRequested)="uncheckAll()"
    >
    </app-table-actions>

    <div class="list__viewport list__viewport_scrollable" #scrollable_element>
      <app-error *ngIf="!configured" [title]="'Not configured' | localize" [theme]="theme">
        {{ 'Unfortunately, this component is not configured yet' | localize }}
      </app-error>

      <table
        *ngIf="configured"
        class="table"
        [class.table_openable]="!loading"
        [class.table_selection]="isAnyChecked"
        [class.table_theme]="theme"
        #table_element
      >
        <thead
          *ngIf="visibleColumns.length"
          app-table-header
          [state]="listState"
          [params]="params"
          [filters]="listState.filters || []"
          [sort]="listState.sort || []"
          [settings]="settings"
          [dataSource]="listState.dataSource"
          [inputs]="inputs"
          [customizing]="customizing"
          [columns]="visibleColumns"
          [style.visibility]="scrollable ? 'hidden' : 'visible'"
          [context]="context"
          [contextElement]="contextElement"
          [theme]="theme"
          (paramsChanged)="paramsChanged.emit($event)"
          (filtersUpdated)="onFiltersUpdated($event)"
          (sortChanged)="onSortUpdated($event)"
          (settingsChanged)="settingsChanged.emit($event)"
          (columnResize)="onColumnResize($event.index, $event.width)"
          (columnResizeFinished)="onColumnResizeFinished($event.index, $event.width)"
          #table_header
        ></thead>

        <thead
          *ngIf="!visibleColumns.length"
          app-table-stub-content-header
          [columns]="scrollable ? 8 : 5"
          [animating]="true"
          [scrollable]="scrollable"
        ></thead>

        <ng-container *ngIf="(settings.groupField | appIsSet) && listState.groups?.length">
          <ng-container *ngFor="let group of listState.groups; trackBy: trackGroupFn; let i = index">
            <app-table-group
              [group]="group"
              [groupIndex]="i"
              [listState]="listState"
              [title]="title"
              [modelDescription]="listState.modelDescription"
              [visibleColumns]="visibleColumns"
              [columnsCount]="columnsCount"
              [selectedItem]="selectedItem"
              [checkedItems]="checkedItems"
              [checkedInverse]="checkedInverse"
              [settings]="settings"
              [scrollable]="scrollable"
              [context]="context"
              [contextElement]="contextElement"
              [viewId]="viewId"
              [accentColor]="accentColor"
              [theme]="theme"
              (fetchStarted)="onFetch()"
              (fetchFinished)="onFetched($event)"
              (modelUpdated)="onModelUpdated($event)"
              (updateSelected)="setSelectedItem($event)"
              (selectToggle)="toggleSelectedItem($event.item, $event.element, true)"
              (checkedToggle)="toggleChecked($event.component, i, $event.index)"
              (changeColumnValue)="updateColumnValue($event.item, { name: $event.name, value: $event.value })"
              #group_component
            ></app-table-group>

            <ng-container *ngTemplateOutlet="group_component.template"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!(settings.groupField | appIsSet) || !listState.groups?.length">
          <app-table-group
            [groupIndex]="0"
            [listState]="listState"
            [title]="title"
            [modelDescription]="listState.modelDescription"
            [visibleColumns]="visibleColumns"
            [columnsCount]="columnsCount"
            [selectedItem]="selectedItem"
            [checkedItems]="checkedItems"
            [checkedInverse]="checkedInverse"
            [settings]="settings"
            [scrollable]="scrollable"
            [context]="context"
            [contextElement]="contextElement"
            [viewId]="viewId"
            [accentColor]="accentColor"
            [theme]="theme"
            (fetchStarted)="onFetch()"
            (fetchFinished)="onFetched($event)"
            (modelUpdated)="onModelUpdated($event)"
            (updateSelected)="setSelectedItem($event)"
            (selectToggle)="toggleSelectedItem($event.item, $event.element, true)"
            (checkedToggle)="toggleChecked($event.component, 0, $event.index)"
            (changeColumnValue)="updateColumnValue($event.item, { name: $event.name, value: $event.value })"
            #group_component
          ></app-table-group>

          <ng-container *ngTemplateOutlet="group_component.template"></ng-container>
        </ng-container>
      </table>
    </div>
  </div>
</div>
