var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { localize } from '@common/localize';
import { applyRangeSliderElementStyles, getRangeSliderElementStyles, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid, controlValue, isSet } from '@shared';
import { RangeSliderElementStylesControl } from '../styles-range-slider-element-edit/range-slider-element-styles.control';
var defaultMinValueInput = {
    name: 'value',
    value_type: InputValueType.StaticValue,
    static_value: 1
};
var defaultMaxValueInput = {
    name: 'value',
    value_type: InputValueType.StaticValue,
    static_value: 10
};
var defaultStepSizeInput = {
    name: 'value',
    value_type: InputValueType.StaticValue,
    static_value: 1
};
export function validateActions() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return of(null);
        }
        if (!control.value || !control.value.length) {
            return of(null);
        }
        return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
            if (result.some(function (configured) { return !configured; })) {
                return { required: true };
            }
        }));
    };
}
var CustomizeBarRangeSliderEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarRangeSliderEditForm, _super);
    function CustomizeBarRangeSliderEditForm(elementConfigurationService, injector) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            verbose_name: new FormControl(''),
            label_additional: new FormControl(''),
            from: new FieldInputControl({ name: 'value' }),
            to: new FieldInputControl({ name: 'value' }),
            min_value_input: new FieldInputControl(defaultMinValueInput),
            max_value_input: new FieldInputControl(defaultMaxValueInput),
            step_size_input: new FieldInputControl(defaultStepSizeInput),
            required: new FormControl(false),
            disable_input: new FieldInputControl({ name: 'value' }),
            tint: new FormControl(null),
            tooltip: new FormControl(''),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl(),
            on_change_actions: new FormControl([], undefined, validateActions()),
            element_styles: new RangeSliderElementStylesControl(injector)
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        _this.injector = injector;
        return _this;
    }
    CustomizeBarRangeSliderEditForm.prototype.ngOnDestroy = function () { };
    CustomizeBarRangeSliderEditForm.prototype.init = function (element, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            name: element.name ? element.name : 'Range',
            verbose_name: element.verboseName,
            label_additional: element.labelAdditional,
            from: element.from ? element.from.serialize() : {},
            to: element.to ? element.to.serialize() : {},
            min_value_input: element.minValueInput ? element.minValueInput.serialize() : defaultMinValueInput,
            max_value_input: element.maxValueInput ? element.maxValueInput.serialize() : defaultMaxValueInput,
            step_size_input: element.stepSizeInput ? element.stepSizeInput.serialize() : defaultStepSizeInput,
            required: element.required,
            disable_input: element.disableInput ? element.disableInput.serialize() : {},
            tint: element.tint,
            tooltip: element.tooltip,
            visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
            on_change_actions: element.onChangeActions
        };
        this.patchValue(value, { emitEvent: false });
        var elementStyles = getRangeSliderElementStyles(element);
        this.controls.element_styles.deserialize(elementStyles);
        if (!firstInit) {
            this.markAsDirty();
        }
        controlValue(this.controls.required)
            .pipe(untilDestroyed(this))
            .subscribe(function (required) {
            var optionalLabel = "(" + localize('optional') + ")";
            if (!required && !isSet(_this.controls.label_additional.value)) {
                _this.controls.label_additional.patchValue(optionalLabel);
            }
            else if (required && _this.controls.label_additional.value == optionalLabel) {
                _this.controls.label_additional.patchValue('');
            }
        });
    };
    CustomizeBarRangeSliderEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isRangeSliderConfigured(instance);
    };
    CustomizeBarRangeSliderEditForm.prototype.controlsValid$ = function (controls) {
        if (!controls.length) {
            return of(true);
        }
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); })
        // debounceTime(60) TODO: Too long wait with debounceTime
        );
    };
    CustomizeBarRangeSliderEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.on_change_actions]);
    };
    CustomizeBarRangeSliderEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.name.value;
        instance.verboseName = this.controls.verbose_name.value;
        instance.labelAdditional = this.controls.label_additional.value;
        instance.from = this.controls.from.value ? new FieldInput().deserialize(this.controls.from.value) : undefined;
        instance.to = this.controls.to.value ? new FieldInput().deserialize(this.controls.to.value) : undefined;
        instance.minValueInput = this.controls.min_value_input.value
            ? new FieldInput().deserialize(this.controls.min_value_input.value)
            : undefined;
        instance.maxValueInput = this.controls.max_value_input.value
            ? new FieldInput().deserialize(this.controls.max_value_input.value)
            : undefined;
        instance.stepSizeInput = this.controls.step_size_input.value
            ? new FieldInput().deserialize(this.controls.step_size_input.value)
            : undefined;
        instance.required = this.controls.required.value;
        instance.disableInput = this.controls.disable_input.value
            ? new FieldInput().deserialize(this.controls.disable_input.value)
            : undefined;
        instance.tint = this.controls.tint.value;
        instance.tooltip = this.controls.tooltip.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.onChangeActions = this.controls.on_change_actions.value;
        var elementStyles = this.controls.element_styles.serialize();
        applyRangeSliderElementStyles(instance, elementStyles);
        return instance;
    };
    return CustomizeBarRangeSliderEditForm;
}(FormGroup));
export { CustomizeBarRangeSliderEditForm };
