var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { DataSourceType } from '@modules/data-sources';
import { FieldType, getFieldDescriptionByType, InputValueType } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelDescriptionStore } from '@modules/model-queries';
import { traverseModelPath } from '@modules/models';
import { queryHasFrontendAutoParameters, queryHasResourceAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { coerceArray, controlValue, isSet } from '@shared';
var ValueArray = /** @class */ (function (_super) {
    __extends(ValueArray, _super);
    function ValueArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ValueArray.prototype.pushNew = function () {
        var control = new FormControl(undefined);
        this.push(control);
        return control;
    };
    ValueArray.prototype.removeLast = function () {
        this.removeAt(this.controls.length - 1);
    };
    ValueArray.prototype.prepareControls = function (value) {
        var _this = this;
        var addControls = value.length > this.controls.length ? value.length - this.controls.length : 0;
        var removeControls = value.length < this.controls.length ? this.controls.length - value.length : 0;
        range(addControls).forEach(function () { return _this.pushNew(); });
        range(removeControls).forEach(function () { return _this.removeLast(); });
    };
    ValueArray.prototype.patchValue = function (value, options) {
        this.prepareControls(value);
        _super.prototype.patchValue.call(this, value, options);
    };
    ValueArray.prototype.setValue = function (value, options) {
        this.prepareControls(value);
        _super.prototype.setValue.call(this, value, options);
    };
    Object.defineProperty(ValueArray.prototype, "value", {
        get: function () {
            return this.controls.map(function (item) { return item.value; });
        },
        set: function (value) { },
        enumerable: true,
        configurable: true
    });
    return ValueArray;
}(FormArray));
export { ValueArray };
var FilterEditForm = /** @class */ (function (_super) {
    __extends(FilterEditForm, _super);
    function FilterEditForm(currentEnvironmentStore, modelDescriptionStore) {
        var _this = _super.call(this, {
            field: new FormControl(),
            lookup: new FormControl(),
            value: new FormControl(),
            value_array: new ValueArray([]),
            exclude: new FormControl(false)
        }) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.modelDescription$ = new BehaviorSubject(undefined);
        return _this;
    }
    FilterEditForm.prototype.ngOnDestroy = function () { };
    FilterEditForm.prototype.init = function (options) {
        this.dataSource = options.dataSource;
        this.filter = options.filter;
        this.field = options.field;
        return this.initFieldOptions();
    };
    FilterEditForm.prototype.getDataSourceParams = function () {
        var _this = this;
        var type = this.dataSource ? this.dataSource.type : undefined;
        var query = type == DataSourceType.Query ? this.dataSource.query : undefined;
        var resource = type == DataSourceType.Query
            ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.dataSource.queryResource; })
            : undefined;
        var modelId = resource && query && query.queryType == QueryType.Simple && query.simpleQuery
            ? [resource.uniqueName, query.simpleQuery.model].join('.')
            : undefined;
        return {
            type: type,
            query: query,
            resource: resource,
            modelId: modelId
        };
    };
    FilterEditForm.prototype.initFieldOptions = function () {
        var _this = this;
        var modelId = this.getDataSourceParams().modelId;
        return this.modelDescriptionStore.getDetailFirst(modelId).pipe(tap(function (modelDescription) {
            _this.modelDescription$.next(modelDescription);
        }), switchMap(function () {
            if (_this.filter) {
                return _this.getFieldValue$(_this.filter.field);
            }
            else if (_this.field) {
                return _this.getFieldValue$(_this.field);
            }
            else {
                return of(undefined);
            }
        }), map(function (field) {
            var fieldDescription = field ? getFieldDescriptionByType(field.field.field) : undefined;
            if (_this.filter) {
                var lookup = fieldDescription && _this.filter.lookup
                    ? fieldDescription.lookups.find(function (item) { return item.type && item.type.lookup == _this.filter.lookup.lookup; })
                    : undefined;
                _this.controls.lookup.patchValue(lookup);
                _this.controls.exclude.patchValue(_this.filter.exclude);
                if (lookup && lookup.array) {
                    _this.controls.value_array.patchValue(coerceArray(_this.filter.value));
                }
                else {
                    _this.controls.value.patchValue(_this.filter.value);
                }
            }
            else if (_this.field) {
                _this.getFieldLookups$(field)
                    .pipe(first(), untilDestroyed(_this))
                    .subscribe(function (lookups) {
                    var firstLookup = lookups.lookups[0];
                    _this.controls.field.patchValue(field);
                    _this.controls.lookup.patchValue(firstLookup);
                });
            }
            _this.controls.field.valueChanges
                .pipe(switchMap(function (value) { return _this.getFieldLookups$(value).pipe(first()); }), untilDestroyed(_this))
                .subscribe(function (lookups) {
                var firstLookup = lookups.lookups[0];
                _this.controls.lookup.patchValue(firstLookup);
                _this.controls.exclude.patchValue(false);
            });
            combineLatest(_this.getField$(), controlValue(_this.controls.lookup))
                .pipe(map(function (_a) {
                var fieldValue = _a[0], lookup = _a[1];
                return ({
                    fieldValue: fieldValue,
                    lookup: lookup,
                    valueField: _this.getValueField(fieldValue, lookup)
                });
            }), distinctUntilChanged(function (lhs, rhs) {
                var lhsLookup = lhs.lookup && lhs.lookup.type ? lhs.lookup.type.lookup : undefined;
                var lhsPath = lhs.fieldValue ? lhs.fieldValue.path : undefined;
                var rhsLookup = rhs.lookup && rhs.lookup.type ? rhs.lookup.type.lookup : undefined;
                var rhsPath = rhs.fieldValue ? rhs.fieldValue.path : undefined;
                return isEqual(lhsPath, rhsPath) && lhsLookup == rhsLookup;
            }), startWith(undefined), pairwise(), filter(function (_a) {
                var prev = _a[0], current = _a[1];
                if (prev) {
                    var prevValueField = prev.valueField ? prev.valueField.field : undefined;
                    var prevLookupArray = prev.lookup ? prev.lookup.array : false;
                    var currentValueField = current.valueField ? current.valueField.field : undefined;
                    var currentLookupArray = current.lookup ? current.lookup.array : false;
                    return prevValueField != currentValueField || prevLookupArray != currentLookupArray;
                }
                else {
                    if (_this.filter) {
                        var currentLookupArray = current.lookup ? current.lookup.array : false;
                        return currentLookupArray && isEqual(_this.filter.value, []);
                    }
                    else {
                        return true;
                    }
                }
            }), untilDestroyed(_this))
                .subscribe(function (_a) {
                var prev = _a[0], current = _a[1];
                var valueFieldDescription = getFieldDescriptionByType(current.valueField ? current.valueField.field : FieldType.Text);
                if (current.lookup && current.lookup.array) {
                    _this.controls.value_array.patchValue([valueFieldDescription.defaultValue]);
                }
                else {
                    _this.controls.value.patchValue(valueFieldDescription.defaultValue);
                }
            });
            return true;
        }));
    };
    FilterEditForm.prototype.traverseDataSourcePath$ = function (path) {
        if (!path) {
            return of(undefined);
        }
        var modelId = this.getDataSourceParams().modelId;
        if (isSet(modelId) && path.length > 1) {
            return this.modelDescriptionStore.getFirst().pipe(map(function (modelDescriptions) {
                var modelDescription = isSet(modelId) ? modelDescriptions.find(function (item) { return item.isSame(modelId); }) : undefined;
                var traversePath = traverseModelPath(modelDescription, path, modelDescriptions);
                if (!traversePath || !traversePath.length) {
                    return;
                }
                return {
                    path: traversePath.map(function (item) {
                        return {
                            name: item.name,
                            verboseName: item.verboseName
                        };
                    }),
                    field: traversePath[traversePath.length - 1].field
                };
            }));
        }
        else if (this.dataSource && path.length == 1) {
            var field = this.dataSource.columns.find(function (item) { return item.name == path[0]; });
            return of({
                path: [{ name: field.name, verboseName: field.verboseName }],
                field: field
            });
        }
        else {
            return of(undefined);
        }
    };
    FilterEditForm.prototype.getFieldValue$ = function (path) {
        return this.traverseDataSourcePath$(path).pipe(map(function (value) {
            if (!value) {
                return;
            }
            return {
                path: value.path.map(function (item) { return item.name; }),
                field: value.field
            };
        }));
    };
    FilterEditForm.prototype.getField$ = function () {
        if (this.filter && this.filter.field) {
            var path = this.filter.field;
            return this.getFieldValue$(path);
        }
        else if (this.field) {
            var path = this.field;
            return this.getFieldValue$(path);
        }
        else {
            return controlValue(this.controls.field);
        }
    };
    FilterEditForm.prototype.getFieldLookups$ = function (fieldValue) {
        var _this = this;
        if (!fieldValue) {
            return of({ lookups: [] });
        }
        var fieldDescription = getFieldDescriptionByType(fieldValue.field.field);
        var _a = this.getDataSourceParams(), type = _a.type, query = _a.query, resource = _a.resource, modelId = _a.modelId;
        if (isSet(modelId)) {
            return this.modelDescriptionStore.getDetail(modelId).pipe(map(function (modelDescription) {
                var getQuery = modelDescription ? modelDescription.getQuery : undefined;
                if (getQuery &&
                    (queryHasResourceAutoParameters(resource, getQuery, modelDescription) ||
                        queryHasFrontendAutoParameters(resource, getQuery, modelDescription))) {
                    return { lookups: fieldDescription.lookups, excludeSupported: true };
                }
                else {
                    var lookups = fieldDescription.lookups.filter(function (lookup) {
                        return _this.dataSource.queryInputs.find(function (item) {
                            return item.valueType == InputValueType.Filter &&
                                item.filterField == fieldValue.field.name &&
                                item.filterLookup == lookup.type.lookup;
                        });
                    });
                    return {
                        lookups: lookups
                    };
                }
            }));
        }
        else if (type == DataSourceType.Input || type == DataSourceType.Workflow) {
            return of({ lookups: fieldDescription.lookups, excludeSupported: true });
        }
        else if (this.dataSource) {
            if (query &&
                (queryHasResourceAutoParameters(resource, query, undefined) ||
                    queryHasFrontendAutoParameters(resource, query, undefined))) {
                return of({
                    lookups: fieldDescription.lookups,
                    excludeSupported: true
                });
            }
            else {
                var lookups = fieldDescription.lookups.filter(function (lookup) {
                    return _this.dataSource.queryInputs.find(function (item) {
                        return item.valueType == InputValueType.Filter &&
                            item.filterField == fieldValue.field.name &&
                            item.filterLookup == lookup.type.lookup;
                    });
                });
                return of({ lookups: lookups });
            }
        }
        else {
            return of({ lookups: [] });
        }
    };
    FilterEditForm.prototype.getLookups$ = function () {
        var _this = this;
        return this.getField$().pipe(switchMap(function (fieldValue) { return _this.getFieldLookups$(fieldValue); }));
    };
    FilterEditForm.prototype.getValueField = function (field, lookup) {
        if (!field || !lookup || !lookup.field) {
            return;
        }
        if (lookup.field == FieldType.Boolean) {
            return {
                field: FieldType.Select,
                params: {
                    valueEquals: function (lhs, rhs) { return lhs === rhs; },
                    options: [
                        { value: false, name: 'No' },
                        { value: true, name: 'Yes' }
                    ],
                    classes: ['select_fill']
                }
            };
        }
        else {
            var fieldParams = lookup.fieldParams
                ? lookup.fieldParams.reduce(function (acc, item) {
                    acc[item] = field.field.params[item];
                    return acc;
                }, {})
                : {};
            var params = __assign({}, fieldParams, lookup.extraParams);
            return {
                field: lookup.field,
                params: params
            };
        }
    };
    FilterEditForm.prototype.getValueField$ = function () {
        var _this = this;
        return combineLatest(this.getField$(), controlValue(this.controls.lookup)).pipe(map(function (_a) {
            var field = _a[0], lookup = _a[1];
            return _this.getValueField(field, lookup);
        }));
    };
    FilterEditForm.prototype.submit = function () {
        var _this = this;
        return this.getField$().pipe(first(), map(function (field) {
            var fieldValue = _this.controls.field.value;
            var filterField;
            var filterLookup = _this.controls.lookup.value;
            if (_this.filter) {
                filterField = _this.filter.field;
            }
            else if (fieldValue) {
                filterField = fieldValue.path;
            }
            if (!isSet(filterField)) {
                return;
            }
            var valueField = _this.getValueField(field, filterLookup);
            var filterValue;
            var filterExclude = _this.controls.exclude.value;
            if (valueField) {
                if (filterLookup && filterLookup.array) {
                    filterValue = _this.controls.value_array.value.filter(function (item) { return isSet(item); });
                }
                else {
                    filterValue = _this.controls.value.value;
                }
            }
            else {
                filterValue = true;
            }
            return new FilterItem2({
                field: filterField,
                lookup: filterLookup ? filterLookup.type : undefined,
                value: filterValue,
                exclude: filterExclude
            });
        }));
    };
    return FilterEditForm;
}(FormGroup));
export { FilterEditForm };
