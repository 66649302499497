import { FormControl, FormGroup } from '@angular/forms';

import { Color } from '@modules/views';
import { isSet } from '@shared';

export interface ColorControlValue {
  global?: string;
  red: number;
  green: number;
  blue: number;
  alpha: number;
}

export class ColorControl extends FormGroup {
  instance: Color;

  controls: {
    global: FormControl;
    red: FormControl;
    green: FormControl;
    blue: FormControl;
    alpha: FormControl;
  };

  _applyFormState: any;

  constructor(state: Partial<Color> = {}) {
    super({
      global: new FormControl(isSet(state.global) ? state.global : null),
      red: new FormControl(isSet(state.red) ? state.red : 0),
      green: new FormControl(isSet(state.green) ? state.green : 0),
      blue: new FormControl(isSet(state.blue) ? state.blue : 0),
      alpha: new FormControl(isSet(state.alpha) ? state.alpha : 1)
    });
  }

  deserialize(value: Color, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.global.patchValue(value.global, { emitEvent: options.emitEvent });
    this.controls.red.patchValue(value.red, { emitEvent: options.emitEvent });
    this.controls.green.patchValue(value.green, { emitEvent: options.emitEvent });
    this.controls.blue.patchValue(value.blue, { emitEvent: options.emitEvent });
    this.controls.alpha.patchValue(value.alpha, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: Color): Color {
    if (!instance) {
      instance = new Color();
    }

    instance.global = this.controls.global.value;
    instance.red = this.controls.red.value;
    instance.green = this.controls.green.value;
    instance.blue = this.controls.blue.value;
    instance.alpha = this.controls.alpha.value;

    return instance;
  }

  serialize(): Color {
    return this.getInstance(this.instance);
  }

  onDisabledChange = (value: boolean) => undefined;

  registerOnChange(fn: Function): void {
    this.valueChanges.subscribe(value => fn(value));
  }

  registerOnDisabledChange(fn: (disabled: boolean) => void): void {
    this.onDisabledChange = fn;
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.enable(opts);
    this.onDisabledChange(false);
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.disable(opts);
    this.onDisabledChange(true);
  }
}
