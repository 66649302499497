import { Injectable, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { ActionService } from '@modules/action-queries';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ListDefaultSelection, TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { WidgetQueryService } from '@modules/dashboard-queries';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInputs, DisplayField, FieldType, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { FieldInputControl, modelDescriptionHasAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { ChartWidgetQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListGroupArray } from '../../list-groups-edit/list-group.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { TableListElementStylesControl } from '../../styles-table-list-element-edit/table-list-element-styles.control';
import {
  CustomizeBarListLayoutSettingsForm,
  CustomizeListOptions,
  CustomizeListResult,
  validateAction,
  validateActions
} from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';

@Injectable()
export class CustomizeBarTableSettingsForm extends CustomizeBarListLayoutSettingsForm<TableSettings> {
  context: ViewContext;
  contextElement: ViewContextElement;

  controls: {
    header: FormControl;
    title: FieldInputControl;
    data_source: ListModelDescriptionDataSourceControl;
    search_enabled: FormControl;
    search_live: FormControl;
    group_field: FormControl;
    group_collapse: FormControl;
    group_counter: FormControl;
    groups: ListGroupArray;
    update_enabled: FormControl;
    update_resource: FormControl;
    update_model: FormControl;
    update_query: FormControl;
    row_click_action: FormControl;
    actions: FormControl;
    row_actions: FormControl;
    model_actions: FormControl;
    columns_actions: FieldActionsArray;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    editing_enabled: FormControl;
    editing_fields: OptionEnabledArray;
    per_page: FormControl;
    first_item_default_selection: FormControl;
    display_filters: FormControl;
    filter_fields: OptionEnabledArray;
    display_footer: FormControl;
    visible_input: FieldInputControl;
    name: FormControl;
    tooltip: FormControl;

    element_styles: TableListElementStylesControl;
  };

  constructor(
    private widgetQueryService: WidgetQueryService,
    private modelOptionsSource: ModelOptionsSource,
    private injector: Injector,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    modelDescriptionStore: ModelDescriptionStore,
    actionService: ActionService,
    elementConfigurationService: ElementConfigurationService,
    dataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      resourceControllerService,
      modelDescriptionStore,
      actionService,
      elementConfigurationService,
      {
        header: new FormControl(true),
        title: new FieldInputControl({ name: 'value' }),
        data_source: dataSourceControl,
        search_enabled: new FormControl(true),
        search_live: new FormControl(true),
        group_field: new FormControl(''),
        group_collapse: new FormControl(true),
        group_counter: new FormControl(true),
        groups: new ListGroupArray([]),
        update_enabled: new FormControl(false),
        update_resource: new FormControl(''),
        update_model: new FormControl(undefined),
        update_query: new FormControl(undefined),
        row_click_action: new FormControl(undefined, undefined, validateAction),
        actions: new FormControl([], undefined, validateActions),
        row_actions: new FormControl([], undefined, validateActions),
        model_actions: new FormControl([], undefined, validateActions),
        columns_actions: new FieldActionsArray([]),
        sorting_field: new FormControl(undefined),
        sorting_asc: new FormControl(true),
        editing_enabled: new FormControl(false),
        editing_fields: new OptionEnabledArray([]),
        per_page: new FormControl(undefined),
        first_item_default_selection: new FormControl(false),
        display_filters: new FormControl(true),
        filter_fields: new OptionEnabledArray([]),
        display_footer: new FormControl(true),
        visible_input: new FieldInputControl({ name: 'value' }),
        name: new FormControl(''),
        tooltip: new FormControl(''),

        element_styles: new TableListElementStylesControl(injector)
      }
    );
    dataSourceControl.setRequired(true);
  }

  init(options: CustomizeListOptions<TableSettings>): Observable<void> {
    this.settings = options.settings;
    this.pageUid = options.pageUid;
    this.elementUid = options.elementUid;
    this.options = options;

    const value = {
      header: options.settings.header,
      title: options.settings.titleInput ? options.settings.titleInput.serialize() : {},
      search_enabled: options.settings.searchEnabled,
      search_live: options.settings.searchLive,
      group_field: options.settings.groupField,
      group_collapse: options.settings.groupCollapse,
      group_counter: options.settings.groupCounter,
      update_enabled: !!options.settings.updateQuery,
      update_resource: options.settings.updateResource,
      update_model: options.settings.updateQuery
        ? {
            queryType: options.settings.updateQuery.queryType,
            model:
              options.settings.updateQuery.queryType == QueryType.Simple
                ? options.settings.updateQuery.simpleQuery.model
                : undefined
          }
        : undefined,
      update_query: options.settings.updateQuery,
      row_click_action: options.settings.rowClickAction,
      actions: options.settings.actions,
      row_actions: options.settings.rowActions,
      model_actions: options.settings.modelActions,
      columns_actions: options.settings.columnActions,
      sorting_field: options.settings.sortingField || null,
      sorting_asc: options.settings.sortingAsc,
      editing_enabled: options.settings.editingEnabled,
      editing_fields: options.settings.editingFields.map(item => {
        return {
          name: item.name,
          enabled: true
        };
      }),
      per_page: options.settings.perPage,
      first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First,
      display_filters: options.settings.displayFilters,
      filter_fields: options.settings.filterFields.map(item => {
        return {
          name: item.name,
          enabled: true
        };
      }),
      display_footer: options.settings.displayFooter,
      visible_input: options.visibleInput ? options.visibleInput.serialize() : {},
      tooltip: options.settings.tooltip
    };

    if (options.nameEditable) {
      value['name'] = options.name;
    }

    this.patchValue(value, { emitEvent: false });

    this.controls.data_source.deserialize(options.settings.dataSource);
    this.controls.groups.deserialize(options.settings.groups);

    if (options.elementStylesEditable && options.elementStyles) {
      this.controls.element_styles.deserialize(options.elementStyles);
    }

    this.trackChanges(options);
    this.initPerPageClean(this.controls.per_page);

    if (!options.firstInit) {
      this.markAsDirty();
    }

    return of(undefined);
  }

  trackChanges(options: CustomizeListOptions<TableSettings>) {
    super.trackChanges(options);

    combineLatest(
      controlValue<DisplayField[]>(this.controls.data_source.controls.columns),
      this.controls.data_source.getModelDescription$(),
      this.controls.data_source.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of([]);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      )
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([columns, modelDescription, modelOptions]) => {
        const visibleColumnNames = columns.filter(item => item.visible).map(item => item.name);
        const columnNames = modelDescription ? modelOptions.map(item => item.name) : columns.map(item => item.name);
        const modelId = modelDescription ? modelDescription.modelId : null;
        const filterFieldsSourceChanged = this.controls.filter_fields.isSourceSet()
          ? !this.controls.filter_fields.isSource(modelId)
          : false;

        this.controls.editing_fields.defaultDisabled = modelDescription ? [modelDescription.primaryKeyField] : [];
        this.controls.editing_fields.syncControls(visibleColumnNames, { source: modelId });
        this.controls.filter_fields.syncControls(columnNames, { source: modelId });

        if (this.controls.display_filters.value && this.controls.filter_fields.isAllDisabled()) {
          this.controls.filter_fields.enableDefault();
        } else if (this.controls.display_filters.value && filterFieldsSourceChanged) {
          this.controls.filter_fields.enableDefault();
        }
      });

    this.controls.group_field.valueChanges.subscribe(() => this.controls.groups.removeControls());
  }

  isGetMissingEnabled() {
    if (this.controls.data_source.controls.type.value != DataSourceType.Query) {
      return false;
    }

    const query = this.controls.data_source.controls.query.value as ListModelDescriptionQuery;

    if (!query) {
      return false;
    } else if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const resourceName = this.controls.data_source.controls.query_resource.value;
      const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
      const modelDescription = this.modelDescriptionStore.instance
        ? this.modelDescriptionStore.instance.find(item => item.model == query.simpleQuery.model)
        : undefined;

      if (!resource || !modelDescription) {
        return false;
      }

      if (resource.type == ResourceType.Airtable) {
        return resource.isSynced(modelDescription.model) || modelDescription.isSynced();
      } else {
        return modelDescriptionHasAutoParameters(resource, modelDescription);
      }
    } else if (query.queryType == QueryType.Http) {
      return query.frontendFiltering;
    } else if (query.queryType == QueryType.SQL) {
      return true;
    } else if (query.queryType == QueryType.Object) {
      return true;
    } else {
      return false;
    }
  }

  getMissing(): Observable<any[]> {
    if (!this.controls.group_field.value) {
      return of([]);
    }

    const existingValues = this.controls.groups.controls.map(item => item.controls.value.value);
    const resourceName = this.controls.data_source.controls.query_resource.value;
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    const modelQuery = this.controls.data_source.controls.query.value as ListModelDescriptionQuery;
    const modelDescription =
      modelQuery.queryType == QueryType.Simple && modelQuery.simpleQuery && this.modelDescriptionStore.instance
        ? this.modelDescriptionStore.instance.find(item => item.model == modelQuery.simpleQuery.model)
        : undefined;
    const modelField = modelDescription ? modelDescription.field(this.controls.group_field.value) : undefined;

    if (
      modelField &&
      [FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(modelField.item.field) &&
      modelField.item.params['options'] &&
      modelField.item.params['options'].length
    ) {
      const values = modelField.item.params['options'].map(item => item['value']);
      return of(values.filter(item => existingValues.find(i => i == item) == undefined));
    }

    if (!this.isGetMissingEnabled()) {
      return of([]);
    }

    const query = new ChartWidgetQuery();
    const parameters = this.controls.data_source.controls.query_parameters.value;
    const columns = this.controls.data_source.controls.columns.value;
    const xColumns = [{ xColumn: this.controls.group_field.value, xLookup: DatasetGroupLookup.Plain }];

    query.queryType = modelQuery.queryType;
    query.simpleQuery = modelQuery.simpleQuery;
    query.httpQuery = modelQuery.httpQuery;
    query.sqlQuery = modelQuery.sqlQuery;

    const params = applyParamInputs({}, this.controls.data_source.controls.query_inputs.value, {
      context: this.context,
      contextElement: this.contextElement,
      parameters: parameters
    });

    return this.widgetQueryService
      .groupQuery(resource, query, xColumns, AggregateFunc.Count, undefined, parameters, params, columns)
      .pipe(
        map(result => {
          if (!result) {
            return;
          }

          const values = result.map(item => item.group);
          return values.filter(item => existingValues.find(i => i == item) == undefined).slice(0, 10);
        }),
        catchError(() => of(undefined))
      );
  }

  onModelChange(modelDescription: ModelDescription) {
    super.onModelChange(modelDescription);
    this.controls.editing_enabled.patchValue(false);
    this.controls.group_field.patchValue('');
    this.controls.groups.removeControls();
  }

  isConfigured(instance: TableSettings): Observable<boolean> {
    return this.elementConfigurationService.isListTableConfigured(instance, { restrictDemo: true });
  }

  submit(): CustomizeListResult<TableSettings> {
    const instance: TableSettings = cloneDeep(this.settings);

    instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.searchEnabled = this.controls.search_enabled.value;
    instance.searchLive = this.controls.search_live.value;
    instance.groupField = this.controls.group_field.value;
    instance.groupCollapse = this.controls.group_collapse.value;
    instance.groupCounter = this.controls.group_counter.value;
    instance.groups = this.controls.groups.serialize();

    if (this.controls.update_enabled.value) {
      instance.updateResource = this.controls.update_resource.value;
      instance.updateQuery = this.controls.update_query.value;
    } else {
      instance.updateResource = undefined;
      instance.updateQuery = undefined;
    }

    if (this.controls.row_click_action.value) {
      instance.rowClickAction = this.controls.row_click_action.value;
    } else {
      instance.rowClickAction = undefined;
    }

    instance.actions = this.controls.actions.value;
    instance.rowActions = this.controls.row_actions.value;
    instance.modelActions = this.controls.model_actions.value;
    instance.columnActions = this.controls.columns_actions.value;

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;
    instance.editingEnabled = this.controls.editing_enabled.value;
    instance.editingFields = this.controls.editing_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.perPage = this.controls.per_page.value;
    instance.defaultSelection = this.controls.first_item_default_selection.value
      ? ListDefaultSelection.First
      : undefined;
    instance.filterFields = this.controls.filter_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
    instance.displayFooter = this.controls.display_footer.value;
    instance.header =
      (instance.titleInput && instance.titleInput.isSet()) ||
      !!instance.searchEnabled ||
      !!instance.displayFilters ||
      (instance.actions && instance.actions.length > 0);
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;

    return {
      settings: instance,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      name: this.controls.name.value,
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
