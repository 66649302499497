import { Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import uniqWith from 'lodash/uniqWith';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { Dataset, datasetGroupDateLookups, groupEquals } from '@modules/charts';
import { ChartWidgetStyles } from '@modules/customize';
import { AutoWidgetElementComponent } from '@modules/customize-components';
import { ElementConfigurationService } from '@modules/customize-configuration';
import {
  ChartType,
  ChartWidget,
  ChartWidgetDataset,
  ChartWidgetGroup,
  singleColorDatasetChartTypes
} from '@modules/dashboard';
import { Input } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { FieldInputControl } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { ChartWidgetDataSourceControl } from '../../model-description-data-source-edit/chart-widget-data-source';
import { ChartWidgetStylesControl } from '../../styles-chart-widget-edit/chart-widget-styles.control';
import {
  CustomizeBarBaseWidgetEditForm,
  CustomizeBarWidgetEditFormOptions,
  CustomizeBarWidgetEditFormResult
} from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.form';
import { ChartWidgetEditFormDatasetArray } from './chart-widget-edit-dataset.array';
import { ChartWidgetEditFormDatasetControl } from './chart-widget-edit-dataset.control';
import { ChartWidgetGroupArray } from './chart-widget-group.array';
import { ChartWidgetGroupControl } from './chart-widget-group.control';

export const validateAction: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarChartWidgetEditForm;

  if (!parent) {
    return of(null);
  }

  if (!control.value) {
    return of(null);
  }

  return parent.elementConfigurationService.isActionConfigured(control.value).pipe(
    map(configured => {
      if (!configured) {
        return { required: true };
      }
    })
  );
};

@Injectable()
export class CustomizeBarChartWidgetEditForm extends CustomizeBarBaseWidgetEditForm<ChartWidget, ChartWidgetStyles>
  implements OnDestroy {
  controls: {
    title: FieldInputControl;
    dataset_column: FormControl;
    datasets: ChartWidgetEditFormDatasetArray;
    groups: ChartWidgetGroupArray;
    chart_type: FormControl;
    y_format: FormControl;
    min: FormControl;
    max: FormControl;
    custom_query_x_column: FormControl;
    custom_date_range: FormControl;
    show_reload: FormControl;
    legend: FormControl;
    percentage: FormControl;
    smooth: FormControl;
    name: FormControl;
    click_action: FormControl;
    visible_input: FieldInputControl;
    tooltip: FormControl;

    element_styles: ChartWidgetStylesControl;
  };

  widget: ChartWidget;

  chartTypeOptions = [
    { value: ChartType.Line, name: 'Line', image: 'chart' },
    { value: ChartType.Bar, name: 'Bar', image: 'ranks' },
    { value: ChartType.StackedBar, name: 'Stacked Bar', image: 'ranks' },
    { value: ChartType.Pie, name: 'Pie', image: 'diagram' },
    { value: ChartType.Doughnut, name: 'Doughnut', image: 'diagram_2' },
    { value: ChartType.Radar, name: 'Radar', image: 'diagram' },
    { value: ChartType.PolarArea, name: 'Polar Area', image: 'diagram_2' },
    { value: ChartType.Scatter, name: 'Scatter', image: 'chart' },
    { value: ChartType.Bubble, name: 'Bubble', image: 'chart' }
  ];

  yFormatChartTypes = [
    ChartType.Bar,
    ChartType.StackedBar,
    ChartType.Line,
    ChartType.Radar,
    ChartType.PolarArea,
    ChartType.Scatter
  ];

  minMaxChartTypes = [ChartType.Bar, ChartType.StackedBar, ChartType.Line, ChartType.Scatter];

  percentageChartTypes = [ChartType.Bar, ChartType.StackedBar];

  smoothChartTypes = [ChartType.Line];

  constructor(private injector: Injector, public elementConfigurationService: ElementConfigurationService) {
    super({
      title: new FieldInputControl({ name: 'value' }),
      dataset_column: new FormControl(''),
      datasets: new ChartWidgetEditFormDatasetArray([], Validators.required),
      groups: new ChartWidgetGroupArray([]),
      chart_type: new FormControl(''),
      y_format: new FormControl(''),
      min: new FormControl(''),
      max: new FormControl(''),
      custom_query_x_column: new FormControl(true),
      custom_date_range: new FormControl(true),
      show_reload: new FormControl(true),
      legend: new FormControl(true),
      percentage: new FormControl(false),
      smooth: new FormControl(false),
      name: new FormControl(''),
      click_action: new FormControl(undefined, undefined, validateAction),
      visible_input: new FieldInputControl({ name: 'value' }),
      tooltip: new FormControl(''),

      element_styles: new ChartWidgetStylesControl(injector)
    });
  }

  ngOnDestroy(): void {}

  createChartWidgetDataSourceControl(): ChartWidgetDataSourceControl {
    return Injector.create({
      providers: [
        {
          provide: ChartWidgetDataSourceControl,
          deps: [
            ModelDescriptionStore,
            CurrentEnvironmentStore,
            ProjectSettingsService,
            ResourceControllerService,
            ResourceGeneratorResolver,
            ModelService,
            ActionService,
            QueryService,
            NotificationService,
            NgZone
          ]
        }
      ],
      parent: this.injector
    }).get<ChartWidgetDataSourceControl>(ChartWidgetDataSourceControl);
  }

  createChartWidgetDatasetControl(): ChartWidgetEditFormDatasetControl {
    const dataSourceControl = this.createChartWidgetDataSourceControl();

    return Injector.create({
      providers: [
        {
          provide: ChartWidgetEditFormDatasetControl,
          useFactory: () => {
            return new ChartWidgetEditFormDatasetControl(dataSourceControl);
          },
          deps: []
        }
      ],
      parent: this.injector
    }).get<ChartWidgetEditFormDatasetControl>(ChartWidgetEditFormDatasetControl);
  }

  init(widget?: ChartWidget, options: CustomizeBarWidgetEditFormOptions<ChartWidgetStyles> = {}) {
    this.widget = widget;
    this.options = options;

    if (widget) {
      this.controls.title.patchValue(widget.nameInput ? widget.nameInput.serialize() : {});
      this.controls.chart_type.patchValue(widget.chartType);
      this.controls.dataset_column.patchValue(widget.datasetColumnEnabled ? widget.datasetColumn : undefined);
      this.controls.y_format.patchValue(widget.yFormat);
      this.controls.min.patchValue(widget.min);
      this.controls.max.patchValue(widget.max);
      this.controls.custom_query_x_column.patchValue(widget.customQueryXColumn);
      this.controls.custom_date_range.patchValue(widget.customDateRange);
      this.controls.show_reload.patchValue(widget.showReload);
      this.controls.legend.patchValue(widget.legend);
      this.controls.percentage.patchValue(widget.percentage);
      this.controls.smooth.patchValue(widget.smooth);
      this.controls.click_action.patchValue(widget.clickAction);
      this.controls.visible_input.patchValue(options.visibleInput ? options.visibleInput.serialize() : {});
      this.controls.tooltip.patchValue(widget.tooltip);

      if (widget.datasets) {
        const controls = widget.datasets.map(item => {
          return this.createDataset(item);
        });

        this.controls.datasets.set(controls);
      }

      if (widget.groups) {
        const controls = widget.groups.map(item => {
          return this.createGroup(item);
        });

        this.controls.groups.set(controls);
      }
    }

    if (options.name) {
      this.controls.name.patchValue(options.name);
    }

    if (options.elementStylesEditable && options.elementStyles) {
      this.controls.element_styles.deserialize(options.elementStyles);
    }

    this.getComponentGroups$()
      .pipe(untilDestroyed(this))
      .subscribe(groups => {
        groups = groups || [];

        const addGroups = groups
          .filter(group => {
            return !this.controls.groups.controls.find(control => control.controls.value.value === group);
          })
          .map(group => {
            const item = this.createGroup();
            item.controls.value.patchValue(group);
            return item;
          });
        const checkGroups = [...groups];
        const removableGroups = this.controls.groups.controls.filter(item => {
          const checkGroupIndex = checkGroups.indexOf(item.controls.value.value);

          if (checkGroupIndex !== -1) {
            checkGroups.splice(checkGroupIndex, 1);
            return false;
          } else {
            return true;
          }
        });

        addGroups.forEach(group => this.controls.groups.append(group));

        this.controls.groups.controls.forEach(group => {
          const removable = removableGroups.includes(group);

          if (group.isSet()) {
            group.controls.removable.patchValue(removable);
          } else if (!group.isSet() && removable) {
            this.controls.groups.remove(group);
          }
        });
      });

    if (!options.firstInit) {
      this.markAsDirty();
    } else {
      this.markAsPristine();
    }
  }

  firstResource$(): Observable<Resource> {
    return controlValue(this.controls.datasets).pipe(
      delay(0),
      switchMap(() => {
        if (!this.controls.datasets.length) {
          return of(undefined);
        }

        return this.controls.datasets.controls[0].controls.data_source.getResource$();
      })
    );
  }

  firstModelDescription$(): Observable<ModelDescription> {
    return controlValue(this.controls.datasets).pipe(
      delay(0),
      switchMap(() => {
        if (!this.controls.datasets.length) {
          return of(undefined);
        }

        return this.controls.datasets.controls[0].controls.data_source.getModelDescription$();
      })
    );
  }

  xDateLookup$() {
    return controlValue(this.controls.datasets).pipe(
      delay(0),
      map(() => {
        if (!this.controls.datasets.length) {
          return true;
        }

        return this.controls.datasets.controls.every(item =>
          datasetGroupDateLookups.includes(item.controls.data_source.controls.x_column_lookup.value)
        );
      })
    );
  }

  isSingleColorDataset(): boolean {
    return singleColorDatasetChartTypes.includes(this.controls.chart_type.value);
  }

  isSingleColorDataset$(): Observable<boolean> {
    return controlValue<ChartType>(this.controls.chart_type).pipe(
      map(value => singleColorDatasetChartTypes.includes(value))
    );
  }

  createDataset(instance?: ChartWidgetDataset) {
    const form = this.createChartWidgetDatasetControl();

    form.deserialize(instance);
    form.markAsDirty();

    return form;
  }

  createGroup(instance?: ChartWidgetGroup) {
    const form = new ChartWidgetGroupControl();

    form.deserialize(instance);
    form.markAsDirty();

    return form;
  }

  getComponentGroups$(): Observable<any[]> {
    if (!this.options.component) {
      return of(undefined);
    }

    return combineLatest(
      (this.options.component as AutoWidgetElementComponent).getData$<Dataset[]>(),
      controlValue<string>(this.controls.dataset_column),
      this.isSingleColorDataset$()
    ).pipe(
      map(([datasets, datasetColumn, isSingleColorDataset]) => {
        if (!datasets) {
          return;
        }

        const groups = [];

        if (isSet(datasetColumn) && isSingleColorDataset) {
          groups.push(...datasets.map(dataset => dataset.name));
        } else {
          const allGroups = datasets.reduce((acc, dataset) => {
            const isDateLookup = datasetGroupDateLookups.includes(dataset.groupLookup);

            if (!isDateLookup) {
              acc.push(...dataset.dataset.map(item => item.group));
            }

            return acc;
          }, []);

          groups.push(...uniqWith(allGroups, (lhs, rhs) => groupEquals(lhs, rhs)));
        }

        return groups;
      })
    );
  }

  isConfigured(instance: ChartWidget): Observable<boolean> {
    return this.elementConfigurationService.isWidgetChartConfigured(instance, { restrictDemo: true });
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.click_action]);
  }

  submit(): CustomizeBarWidgetEditFormResult<ChartWidget, ChartWidgetStyles> {
    const instance: ChartWidget = this.widget ? cloneDeep(this.widget) : new ChartWidget();

    instance.nameInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
    instance.datasets = this.controls.datasets.controls.map(item => item.serialize());
    instance.groups = this.controls.groups.controls.map(item => item.serialize());
    instance.chartType = this.controls.chart_type.value;
    instance.datasetColumnEnabled = isSet(this.controls.dataset_column.value);
    instance.datasetColumn = this.controls.dataset_column.value;
    instance.yFormat = this.controls.y_format.value;
    instance.min = this.controls.min.value;
    instance.max = this.controls.max.value;
    instance.customQueryXColumn = this.controls.custom_query_x_column.value;
    instance.customDateRange = this.controls.custom_date_range.value;
    instance.showReload = this.controls.show_reload.value;
    instance.legend = this.controls.legend.value;
    instance.percentage = this.controls.percentage.value;
    instance.smooth = this.controls.smooth.value;
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;

    if (this.controls.click_action.value) {
      instance.clickAction = this.controls.click_action.value;
    } else {
      instance.clickAction = undefined;
    }

    return {
      widget: instance,
      name: this.controls.name.value,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
