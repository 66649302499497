import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, timer } from 'rxjs';
import { debounce, map, startWith, switchMap } from 'rxjs/operators';
import { ActionControllerService } from '@modules/action-queries';
import { ChangeForm } from '@modules/change';
import { CustomViewsStore } from '@modules/custom-views';
import { getModelAttributesByColumns, TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, createFormFieldFactory, CustomViewDisplayField, FieldType, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { EMPTY } from '@shared';
var TableItemColumnComponent = /** @class */ (function () {
    function TableItemColumnComponent(customViewsStore, actionControllerService, cd) {
        this.customViewsStore = customViewsStore;
        this.actionControllerService = actionControllerService;
        this.cd = cd;
        this.selected = false;
        this.theme = false;
        this.createField = createFormFieldFactory();
        this.column$ = new BehaviorSubject(undefined);
        this.fieldTypes = FieldType;
        this.viewParameters = [];
        this.viewParams$ = of({});
        this.edit = false;
        this.editLoading = false;
        this.actions = [];
        this.actionOpened$ = new BehaviorSubject(false);
        this.actionSubscriptions = [];
        this.overlayClasses = ['overlay'];
    }
    TableItemColumnComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.column$
            .pipe(switchMap(function (column) {
            if (column && column.column instanceof CustomViewDisplayField) {
                return _this.customViewsStore.getDetail(column.column.customView);
            }
            else {
                return of(undefined);
            }
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.view = customView ? customView.view : undefined;
            _this.cd.markForCheck();
            _this.updateViewParameters();
            _this.updateViewParams();
        });
    };
    TableItemColumnComponent.prototype.ngOnDestroy = function () { };
    TableItemColumnComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.column) {
            this.column$.next(this.column);
        }
        if (changes.column || changes.settings) {
            var columnActions = this.settings.columnActions.find(function (item) { return item.name == _this.column.column.name; });
            this.actions = columnActions ? columnActions.actions : [];
        }
    };
    TableItemColumnComponent.prototype.updateViewParameters = function () {
        if (!this.view) {
            this.viewParameters = [];
            this.cd.markForCheck();
            return;
        }
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        this.viewParameters = columns.map(function (item) {
            var parameter = new ParameterField();
            parameter.name = item.name;
            parameter.verboseName = item.verboseName;
            parameter.field = item.field;
            parameter.params = item.params;
            return parameter;
        });
        this.cd.markForCheck();
    };
    TableItemColumnComponent.prototype.updateViewParams = function () {
        var _this = this;
        if (!this.view) {
            this.viewParams$ = of({});
            this.cd.markForCheck();
            return;
        }
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        var modelParams = getModelAttributesByColumns(this.model, columns);
        var customViewField = this.column.column instanceof CustomViewDisplayField ? this.column.column : undefined;
        var mappings = customViewField ? customViewField.customViewMappings : [];
        this.viewParams$ = combineLatest(mappings.map(function (mapping) {
            var _a;
            if (mapping.sourceParameterInput) {
                return applyParamInput$(mapping.sourceParameterInput, {
                    context: _this.context,
                    contextElement: _this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = modelParams,
                        _a)
                }).pipe(map(function (value) {
                    return {
                        name: mapping.targetParameter,
                        value: value !== EMPTY ? value : undefined
                    };
                }));
            }
            else {
                var value = modelParams[mapping.sourceParameter];
                return of({
                    name: mapping.targetParameter,
                    value: value
                });
            }
        })).pipe(map(function (values) {
            return fromPairs(values.map(function (item) { return [item.name, item.value]; }));
        }));
        this.cd.markForCheck();
    };
    TableItemColumnComponent.prototype.initAction = function () {
        var _this = this;
        this.actionSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.actionSubscriptions = [];
        var overlayClasses = ['overlay'];
        if (!this.actions.length) {
            return;
        }
        this.actionSubscriptions.push(this.overlay.positionChange.pipe(untilDestroyed(this)).subscribe(function (positionChange) {
            if (positionChange.connectionPair.originY == 'top') {
                _this.overlayClasses = overlayClasses.concat(['overlay_position_top-center']);
            }
            else if (positionChange.connectionPair.originY == 'bottom') {
                _this.overlayClasses = overlayClasses.concat(['overlay_position_bottom-center']);
            }
            _this.cd.markForCheck();
        }));
        this.actionSubscriptions.push(combineLatest(merge(fromEvent(this.columnElement.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(this.columnElement.nativeElement, 'mouseleave').pipe(map(function () { return false; }))).pipe(debounce(function (value) { return timer(value ? 0 : 10); }), startWith(true)), merge(fromEvent(this.actionElement.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(this.actionElement.nativeElement, 'mouseleave').pipe(map(function () { return false; }))).pipe(startWith(false)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var columnHover = _a[0], actionHover = _a[1];
            if (!columnHover && !actionHover) {
                _this.actionOpened$.next(false);
                _this.actionSubscriptions.forEach(function (item) { return item.unsubscribe(); });
                _this.actionSubscriptions = [];
            }
        }));
    };
    TableItemColumnComponent.prototype.toggleEdit = function () {
        if (!this.edit) {
            this.startEdit();
        }
        else {
            this.saveEdit();
        }
    };
    TableItemColumnComponent.prototype.onColumnMouseEnter = function () {
        if (!this.actions.length) {
            return;
        }
        this.actionOpened$.next(true);
        this.cd.detectChanges();
        this.initAction();
    };
    // get editColumn() {
    //   return this.row.columns[this.visibleColumnIndexes[this.editColumnIndex]];
    // }
    TableItemColumnComponent.prototype.startEdit = function () {
        // this.form.initialize(this.modelDescription, this.row.model);
        // this.editColumnIndex = index;
        // this.cd.markForCheck();
        // this.listContextService.editingField = this.editColumn;
    };
    Object.defineProperty(TableItemColumnComponent.prototype, "editValid", {
        get: function () {
            return false;
            // if (!this.form.form || !this.editColumn) {
            //   return false;
            // }
            //
            // return this.form.form.controls[this.editColumn.column.name].valid;
        },
        enumerable: true,
        configurable: true
    });
    TableItemColumnComponent.prototype.saveEdit = function () {
        // if (!this.editValid) {
        //   return;
        // }
        //
        // const field = this.editColumn.column.name;
        // const obs = this.form.submit([field]);
        // const changes = this.form.getChanges();
        //
        // if (!obs) {
        //   this.cancelEdit();
        //   return;
        // }
        //
        // this.editLoading = true;
        // this.cd.markForCheck();
        //
        // obs
        //   .pipe(
        //     switchMap<Model, any>(
        //       model => this.columnsModelListStore.resolveItemColumns(model).pipe(map(columns => {
        //         return [model, columns];
        //       }))
        //     ),
        //     untilDestroyed(this)
        //   )
        //   .subscribe(
        //     ([model, columns]) => {
        //       this.row = clone(this.row);
        //       this.row.model = model;
        //       this.row.columns = columns;
        //
        //       this.cancelEdit();
        //
        //       this.editLoading = false;
        //       this.cd.markForCheck();
        //
        //       this.userActivityService.createForCurrent(UserActivityType.ModelUpdate, {
        //         resource: this.modelDescription.resource,
        //         model: this.modelDescription.model,
        //         id: model.primaryKey,
        //         changes: changes
        //       }).subscribe(() => {});
        //     },
        //     error => {
        //       this.editLoading = false;
        //       this.cd.markForCheck();
        //       this.notificationService.error(
        //         'Error',
        //         error.errors[0]
        //       );
        //     }
        //   );
    };
    TableItemColumnComponent.prototype.cancelEdit = function () {
        // this.form.deinit();
        // this.editColumnIndex = undefined;
        // this.cd.markForCheck();
        //
        // if (this.listContextService.editingField === this.editColumn) {
        //   this.listContextService.editingField = undefined;
        // }
    };
    return TableItemColumnComponent;
}());
export { TableItemColumnComponent };
