var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { bisectCenter, extent, least, max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import { curveLinear, curveMonotoneX, line } from 'd3-shape';
import { timeDay, timeHour, timeMinute, timeMonth, timeSecond, timeWeek, timeYear } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as numeral from 'numeral';
import { BehaviorSubject, combineLatest, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyDatasetsDefaultColors, CHART_COLORS, datasetGroupDateLookups, DatasetGroupLookup, getDatasetsGroupLookup, getDatasetsGroups, getDatasetsUniqueGroups, getDateFormatByLookup, prepareDataset, syncSortedDatasetsGroups } from '@modules/charts';
import { elementResize$, generateAlphanumeric, isSet } from '@shared';
// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
import { fitXAxisLabelWithVisibility, fitXAxisLabelWithWidth, getYAxisWidth } from '../../utils/d3';
var LineChart2Component = /** @class */ (function () {
    function LineChart2Component(el, overlay, dataTooltip, cd) {
        this.el = el;
        this.overlay = overlay;
        this.dataTooltip = dataTooltip;
        this.cd = cd;
        this.datasets = [];
        this.smooth = false;
        this.defaultColors = CHART_COLORS;
        this.animate = true;
        this.xAxisVisible = true;
        this.yAxisVisible = true;
        this.legend = true;
        this.interactive = true;
        this.tooltips = true;
        this.lineArea = true;
        this.dataClickEnabled = false;
        this.theme = false;
        this.groupHover = new EventEmitter();
        this.dataClick = new EventEmitter();
        this.data = [];
        this.dataGroups = [];
        this.dataTotal = [];
        this.margin = { top: 8, right: 8, bottom: 8, left: 8 };
        this.hoverDatasetIndex$ = new BehaviorSubject(undefined);
        this.hoverDatasetGroup$ = new BehaviorSubject(undefined);
        this.hoverLegendDatasetIndex$ = new BehaviorSubject(undefined);
        this.uid = generateAlphanumeric(8);
    }
    Object.defineProperty(LineChart2Component.prototype, "xScale", {
        get: function () {
            return this.xScaleTime || this.xScalePoint;
        },
        enumerable: true,
        configurable: true
    });
    LineChart2Component.prototype.getId = function (name) {
        return name + "-" + this.uid;
    };
    LineChart2Component.prototype.ngOnInit = function () { };
    LineChart2Component.prototype.ngOnDestroy = function () { };
    LineChart2Component.prototype.ngOnChanges = function (changes) {
        if (changes.datasets) {
            this.data = this.datasets.map(function (dataset) { return prepareDataset(dataset); });
            this.dataGroupLookup = getDatasetsGroupLookup(this.data);
            this.dataGroups = getDatasetsUniqueGroups(this.data);
            applyDatasetsDefaultColors(this.data, this.defaultColors);
            syncSortedDatasetsGroups(this.data, this.dataGroups);
            this.dataTotal = getDatasetsGroups(this.data);
        }
        if (this.svg) {
            this.rerender();
        }
    };
    LineChart2Component.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.init();
            elementResize$(_this.canvasElement.nativeElement, false)
                .pipe(untilDestroyed(_this))
                .subscribe(function () { return _this.onResize(); });
        }, 0);
    };
    LineChart2Component.prototype.init = function () {
        this.initBounds();
        this.initSvg();
        this.initYAxis();
        this.renderYAxis();
        this.fitYAxis();
        this.initXAxis();
        this.renderXAxis();
        this.renderLine();
        this.renderGradients();
        this.initDatasetHover();
        this.initInteractive();
        this.renderInteractive();
        this.initEvents();
    };
    LineChart2Component.prototype.initBounds = function () {
        var width = this.canvasElement.nativeElement.offsetWidth;
        var height = this.canvasElement.nativeElement.offsetHeight;
        this.margin = { top: 8, right: 8, bottom: this.xAxisVisible ? 22 : 8, left: this.yAxisVisible ? 40 : 8 };
        this.width = width - this.margin.left - this.margin.right;
        this.height = height - this.margin.top - this.margin.bottom;
    };
    LineChart2Component.prototype.initSvg = function () {
        this.svg = select(this.svgElement.nativeElement)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    LineChart2Component.prototype.initXAxis = function () {
        this.xAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_x');
    };
    LineChart2Component.prototype.renderXAxis = function () {
        if (datasetGroupDateLookups.includes(this.dataGroupLookup)) {
            this.xScaleTime = scaleTime()
                .domain(extent(this.dataGroups, function (d) { return d.valueOf(); }))
                .range([0, this.width]);
        }
        else {
            this.xScalePoint = scalePoint()
                .domain(this.dataGroups.map(function (d) { return d; }))
                .range([0, this.width]);
        }
        if (!this.xAxisVisible) {
            this.xAxis.selectChildren().remove();
            return;
        }
        var ticks = Math.floor(this.width / 80);
        var axisGenerator = axisBottom(this.xScale).ticks(ticks).tickSize(0).tickPadding(10);
        if (this.dataGroupLookup == DatasetGroupLookup.DateDay) {
            axisGenerator.ticks(timeDay.every(1)).tickFormat(timeFormat('%b %d'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateWeek) {
            axisGenerator.ticks(timeWeek.every(1)).tickFormat(timeFormat('%b %d'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateMonth) {
            axisGenerator.ticks(timeMonth.every(1)).tickFormat(timeFormat('%B'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateQuarter) {
            axisGenerator.ticks(timeMonth.every(3)).tickFormat(timeFormat('Q%q - %Y'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateYear) {
            axisGenerator.ticks(timeYear.every(1)).tickFormat(timeFormat('%Y'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateHour) {
            axisGenerator.ticks(timeHour.every(1)).tickFormat(timeFormat('%H:%M'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateMinute) {
            axisGenerator.ticks(timeMinute.every(15)).tickFormat(timeFormat('%H:%M'));
        }
        else if (this.dataGroupLookup == DatasetGroupLookup.DateSecond) {
            axisGenerator.ticks(timeSecond.every(15)).tickFormat(timeFormat(':%S'));
        }
        var axis = this.xAxis.attr('transform', "translate(0," + this.height + ")").call(axisGenerator);
        this.setAxisClasses(axis);
        if (this.xScaleTime) {
            fitXAxisLabelWithVisibility(axis, this.el.nativeElement);
        }
        else {
            fitXAxisLabelWithWidth(axis, this.xScale.domain(), this.xScalePoint.bandwidth());
        }
    };
    LineChart2Component.prototype.initYAxis = function () {
        this.yAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_y');
    };
    LineChart2Component.prototype.renderYAxis = function () {
        var _this = this;
        var domain = extent(this.dataTotal, function (d) { return d.item.value; });
        var domainExpand = 0.05 * Math.abs(domain[1] - domain[0]);
        if (domain[0] !== 0) {
            domain[0] -= domainExpand;
        }
        domain[1] += domainExpand;
        if (isSet(this.min)) {
            domain[0] = this.min;
        }
        if (isSet(this.max)) {
            domain[1] = this.max;
        }
        this.yScale = scaleLinear().domain(domain).range([this.height, 0]);
        if (!this.yAxisVisible) {
            this.yAxis.selectChildren().remove();
            return;
        }
        var ticks = Math.floor(this.height / 50);
        var axisGenerator = axisLeft(this.yScale)
            .ticks(ticks)
            .tickSize(-this.width)
            .tickPadding(10)
            .tickFormat(function (value) {
            if (!isSet(_this.yFormat)) {
                return value;
            }
            return numeral(value).format(_this.yFormat);
        });
        var axis = this.yAxis.call(axisGenerator);
        this.setAxisClasses(axis);
    };
    LineChart2Component.prototype.setAxisClasses = function (axis) {
        axis.selectAll('.domain').attr('class', 'chart-axis-domain domain');
        axis.selectAll('.tick').attr('class', 'chart-axis-tick-group tick');
        axis.selectAll('.chart-axis-tick-group line').attr('class', 'chart-axis-tick');
        axis.selectAll('.chart-axis-tick-group text').attr('class', 'chart-axis-label');
    };
    LineChart2Component.prototype.fitYAxis = function () {
        var width = this.yAxis ? getYAxisWidth(this.yAxis) : 0;
        this.margin.left = width + 10;
        this.width = this.canvasElement.nativeElement.offsetWidth - this.margin.left - this.margin.right;
        this.svg.attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    LineChart2Component.prototype.renderLine = function () {
        var _this = this;
        var lineGenerator = line()
            .curve(this.smooth ? curveMonotoneX : curveLinear)
            .x(function (d) {
            if (_this.xScaleTime) {
                return _this.xScaleTime(d.group);
            }
            else if (_this.xScalePoint) {
                return _this.xScalePoint(d.group);
            }
        })
            .y(function (d) { return _this.yScale(d.value); });
        this.svg
            .selectAll('.chart-line')
            .data(this.data)
            .join('path')
            .attr('class', function (d, i) { return "chart-line chart-line_index-" + i; })
            .attr('stroke', function (d, i) {
            var lineGradient = _this.getId("line-gradient-" + i);
            return "url(#" + lineGradient + ")";
        })
            .attr('stroke-width', isSet(this.lineWidth) ? this.lineWidth : null)
            .attr('d', function (d) { return lineGenerator(d.dataset); });
        // const areaGenerator = area<DataGroup<number, string | moment.Moment>>()
        //   .x(d => {
        //     if (this.xScaleTime) {
        //       return this.xScaleTime(d.group as moment.Moment);
        //     } else if (this.xScalePoint) {
        //       return this.xScalePoint(d.group as string);
        //     }
        //   })
        //   .y0(this.height)
        //   .y1(d => this.yScale(d.value));
        this.svg
            .selectAll('.chart-line-area')
            .data(this.data)
            .join('path')
            .attr('class', function (d, i) { return "chart-line-area chart-line-area_index-" + i; })
            .attr('fill', function (d, i) {
            var fillGradient = _this.getId("fill-gradient-" + i);
            return _this.lineArea ? "url(#" + fillGradient + ")" : 'transparent';
        })
            // .attr('d', d => areaGenerator(d.dataset));
            .attr('d', function (d) {
            if (d.dataset.length) {
                var lineValues = lineGenerator(d.dataset).slice(1);
                var splitValues = lineValues.split(',');
                return "M0," + _this.height + "," + lineValues + ",l0," + (_this.height - parseFloat(splitValues[splitValues.length - 1]));
            }
            else {
                return null;
            }
        });
    };
    LineChart2Component.prototype.renderGradients = function () {
        var _this = this;
        this.svg
            .selectAll('.chart-line-gradient')
            .data(this.data.map(function (item, i) { return ({ dataset: item, index: i }); }))
            .join('linearGradient')
            .attr('id', function (d) { return _this.getId("line-gradient-" + d.index); })
            .attr('class', 'chart-line-gradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', '0%')
            .attr('y1', function (d) {
            var minValue = min(d.dataset.dataset, function (item) { return item.value; });
            return _this.yScale(minValue);
        })
            .attr('x2', '0%')
            .attr('y2', function (d) {
            var maxValue = max(d.dataset.dataset, function (item) { return item.value; });
            return _this.yScale(maxValue);
        })
            .selectAll('stop')
            .data(function (d) {
            var colorHex = getColorHex(_this.data[d.index].color);
            var clr = parseColor(colorHex, '#000');
            return [
                { offset: '0%', color: clr.darken(0.2) },
                { offset: '100%', color: clr.lighten(0.2) }
            ];
        })
            .join('stop')
            .attr('offset', function (d) { return d.offset; })
            .attr('stop-color', function (d) { return d.color; });
        this.svg
            .selectAll('.chart-line-area-gradient')
            .data(this.data.map(function (item, i) { return ({ dataset: item, index: i }); }))
            .join('linearGradient')
            .attr('id', function (d) { return _this.getId("fill-gradient-" + d.index); })
            .attr('class', 'chart-line-area-gradient')
            .attr('gradientUnits', 'objectBoundingBox')
            .attr('x1', '0%')
            .attr('y1', '100%')
            .attr('x2', '0%')
            .attr('y2', '0%')
            .selectAll('stop')
            .data(function (d) {
            var colorHex = getColorHex(_this.data[d.index].color);
            var clr = parseColor(colorHex, '#000');
            return [
                { offset: '0%', color: clr.alpha(0) },
                { offset: '100%', color: clr.alpha(0.18) }
            ];
        })
            .join('stop')
            .attr('offset', function (d) { return d.offset; })
            .attr('stop-color', function (d) { return d.color; });
    };
    LineChart2Component.prototype.initDatasetHover = function () {
        var _this = this;
        combineLatest(this.hoverDatasetIndex$, this.hoverLegendDatasetIndex$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var hoverDatasetIndex = _a[0], hoverLegendDatasetIndex = _a[1];
            var hoverIndex = [hoverDatasetIndex, hoverLegendDatasetIndex].find(function (item) { return isSet(item); });
            _this.data.forEach(function (item, i) {
                var lineNodes = _this.svg.selectAll(".chart-line_index-" + i).nodes();
                var lineAreaNodes = _this.svg.selectAll(".chart-line-area_index-" + i).nodes();
                if (!isSet(hoverIndex) || i === hoverIndex) {
                    lineNodes.forEach(function (node) { return node.classList.remove('chart-line_disabled'); });
                    lineAreaNodes.forEach(function (node) { return node.classList.remove('chart-line-area_disabled'); });
                }
                else {
                    lineNodes.forEach(function (node) { return node.classList.add('chart-line_disabled'); });
                    lineAreaNodes.forEach(function (node) { return node.classList.add('chart-line-area_disabled'); });
                }
            });
        });
        this.hoverDatasetGroup$.pipe(untilDestroyed(this)).subscribe(function (hoverDatasetGroup) {
            _this.updateInteractiveClasses(!!hoverDatasetGroup);
        });
    };
    LineChart2Component.prototype.initInteractive = function () {
        this.interactiveRect = this.svg.append('rect').attr('class', 'chart-interactive');
    };
    LineChart2Component.prototype.renderInteractive = function () {
        this.interactiveRect.attr('width', this.width).attr('height', this.height);
    };
    LineChart2Component.prototype.updateInteractiveClasses = function (hoverGroup) {
        if (!this.interactiveRect) {
            return;
        }
        var nodes = this.interactiveRect.nodes();
        var activeClass = 'chart-interactive_clickable';
        if (this.dataClickEnabled && hoverGroup) {
            nodes.forEach(function (node) {
                if (!node.classList.contains(activeClass)) {
                    node.classList.add(activeClass);
                }
            });
        }
        else {
            nodes.forEach(function (node) {
                if (node.classList.contains(activeClass)) {
                    node.classList.remove(activeClass);
                }
            });
        }
    };
    LineChart2Component.prototype.initEvents = function () {
        var _this = this;
        this.lineCircle = this.svg
            .insert('circle', function () { return _this.interactiveRect.node(); })
            .attr('class', 'chart-line-circle')
            .attr('r', 5);
        // combineLatest(
        //   merge(
        //     fromEvent<MouseEvent>(rect.node(), 'mouseenter').pipe(map(e => ({ hover: true, e: e }))),
        //     fromEvent<MouseEvent>(rect.node(), 'mouseleave').pipe(map(() => ({ hover: false, e: undefined })))
        //   )
        //     .pipe(debounce(value => timer(value.hover ? 0 : 10)))
        //     .pipe(startWith({ hover: false, e: undefined })),
        //   merge(
        //     fromEvent<MouseEvent>(this.tooltipContainerElement.nativeElement, 'mouseenter').pipe(
        //       map(e => ({ hover: true, e: e }))
        //     ),
        //     fromEvent<MouseEvent>(this.tooltipContainerElement.nativeElement, 'mouseleave').pipe(
        //       map(() => ({ hover: false, e: undefined }))
        //     )
        //   )
        //     .pipe(debounce(value => timer(value.hover ? 0 : 10)))
        //     .pipe(startWith({ hover: false, e: undefined }))
        // )
        //   .pipe(
        //     distinctUntilChanged((lhs, rhs) => {
        //       const [lhsChart, lhsTooltip] = lhs;
        //       const [rhsChart, rhsTooltip] = rhs;
        //       return (lhsChart.hover || lhsTooltip.hover) === (rhsChart.hover || rhsTooltip.hover);
        //     }),
        //     untilDestroyed(this)
        //   )
        //   .subscribe(([chart, tooltip]) => {
        //     if (chart.hover || tooltip.hover) {
        //       this.mouseover(chart.e);
        //     } else {
        //       this.mouseout();
        //     }
        //   });
        merge(fromEvent(this.interactiveRect.node(), 'mouseenter').pipe(map(function (e) { return ({ hover: true, e: e }); })), fromEvent(this.interactiveRect.node(), 'mouseleave').pipe(map(function () { return ({ hover: false, e: undefined }); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (chart) {
            if (chart.hover) {
                _this.onMouseEnter(chart.e);
            }
            else {
                _this.onMouseLeave();
            }
        });
        fromEvent(this.interactiveRect.node(), 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) { return _this.onMouseMove(e); });
        fromEvent(this.interactiveRect.node(), 'click')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var hoverDatasetGroup = _this.hoverDatasetGroup$.value;
            if (hoverDatasetGroup) {
                var group = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group;
                var group2 = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group2;
                var group3 = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group3;
                var value = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].value;
                _this.onClick({
                    datasetIndex: hoverDatasetGroup.datasetIndex,
                    groupIndex: hoverDatasetGroup.groupIndex,
                    group: group,
                    group2: group2,
                    group3: group3,
                    value: value,
                    position: {
                        x: e.clientX,
                        y: e.clientY
                    }
                });
            }
        });
    };
    LineChart2Component.prototype.showDatasetTooltip = function (closestDataset, reuse) {
        var _this = this;
        if (reuse === void 0) { reuse = false; }
        var group;
        if (closestDataset.item.group instanceof moment) {
            var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            group = closestDataset.item.group.format(format);
        }
        else {
            group = closestDataset.item.group;
        }
        this.dataTooltip.show({
            group: group,
            datasets: this.data
                .filter(function (dataset) { return dataset.dataset[closestDataset.itemIndex]; })
                .map(function (dataset, i) {
                var defaultLabel = _this.data.length > 1 ? "Dataset " + (i + 1) : undefined;
                return {
                    value: dataset.dataset[closestDataset.itemIndex].value,
                    valueFormat: dataset.format,
                    label: isSet(dataset.name) ? dataset.name : defaultLabel,
                    color: dataset.color
                };
            }),
            datasetActiveIndex: closestDataset ? closestDataset.datasetIndex : undefined,
            valueFormat: this.yFormat,
            colorCircle: true,
            theme: this.theme,
            x: this.margin.left + closestDataset.x,
            y: this.margin.top + closestDataset.y,
            portalOutlet: this.portalOutlet,
            reuse: reuse
        });
    };
    LineChart2Component.prototype.onMouseEnter = function (e) {
        if (!this.interactive) {
            return;
        }
        this.lineCircle.node().classList.add('chart-line-circle_active');
        this.dataTooltip.close();
        var closestDataset = this.getClosestDataset(e);
        if (closestDataset) {
            var lineGradient = this.getId("line-gradient-" + closestDataset.datasetIndex);
            this.lineCircle.attr('cx', closestDataset.x).attr('cy', closestDataset.y).attr('stroke', "url(#" + lineGradient + ")");
            this.hoverDatasetIndex$.next(closestDataset.datasetIndex);
            this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });
            var group = void 0;
            var value = closestDataset.item.value;
            if (closestDataset.item.group instanceof moment) {
                var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
                group = closestDataset.item.group.format(format);
            }
            else {
                group = closestDataset.item.group;
            }
            this.groupHover.next({ group: group, value: value });
            if (this.tooltips) {
                this.showDatasetTooltip(closestDataset);
            }
        }
    };
    LineChart2Component.prototype.getClosestGroup = function (e) {
        var _a = pointer(e), pointerX = _a[0], pointerY = _a[1];
        if (this.xScaleTime) {
            var x0 = this.xScaleTime.invert(pointerX);
            return bisectCenter(this.dataGroups.map(function (item) { return item.toDate(); }), x0);
        }
        else if (this.xScalePoint) {
            var x0 = this.xScalePoint(pointerX);
            return bisectCenter(range(this.width), x0);
        }
    };
    LineChart2Component.prototype.getClosestDataset = function (e) {
        var _this = this;
        var _a = pointer(e), pointerX = _a[0], pointerY = _a[1];
        var dataTotal = this.dataTotal;
        if (this.selectedDatasetIndexes) {
            dataTotal = dataTotal.filter(function (item) { return _this.selectedDatasetIndexes[item.datasetIndex]; });
        }
        var dataClosest = least(dataTotal, function (item) {
            var itemY = _this.yScale(item.item.value);
            if (_this.xScaleTime) {
                var itemX = _this.xScaleTime(item.item.group);
                return Math.hypot(itemX - pointerX, itemY - pointerY);
            }
            else if (_this.xScalePoint) {
                var itemX = _this.xScalePoint(item.item.group);
                return Math.hypot(itemX - pointerX, itemY - pointerY);
            }
        });
        if (!dataClosest) {
            return;
        }
        var x;
        var y = this.yScale(dataClosest.item.value);
        if (this.xScaleTime) {
            x = this.xScaleTime(dataClosest.item.group);
        }
        else if (this.xScalePoint) {
            x = this.xScalePoint(dataClosest.item.group);
        }
        return __assign({}, dataClosest, { x: x, y: y });
    };
    LineChart2Component.prototype.onMouseMove = function (e) {
        if (!this.interactive) {
            return;
        }
        var closestDataset = this.getClosestDataset(e);
        if (closestDataset) {
            var lineGradient = this.getId("line-gradient-" + closestDataset.datasetIndex);
            this.lineCircle.attr('cx', closestDataset.x).attr('cy', closestDataset.y).attr('stroke', "url(#" + lineGradient + ")");
            this.hoverDatasetIndex$.next(closestDataset.datasetIndex);
            this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });
            var group = void 0;
            var value = closestDataset.item.value;
            if (closestDataset.item.group instanceof moment) {
                var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
                group = closestDataset.item.group.format(format);
            }
            else {
                group = closestDataset.item.group;
            }
            this.groupHover.next({ group: group, value: value });
            if (this.tooltips) {
                this.showDatasetTooltip(closestDataset, true);
            }
        }
        else {
            this.hoverDatasetGroup$.next(undefined);
        }
    };
    LineChart2Component.prototype.onMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.lineCircle.node().classList.remove('chart-line-circle_active');
        this.hoverDatasetIndex$.next(undefined);
        this.hoverDatasetGroup$.next(undefined);
        this.groupHover.next(undefined);
        this.dataTooltip.close();
    };
    LineChart2Component.prototype.onClick = function (options) {
        if (!this.dataClickEnabled) {
            return;
        }
        this.dataClick.emit(options);
    };
    Object.defineProperty(LineChart2Component.prototype, "selectedDatasetCount", {
        get: function () {
            return this.selectedDatasetIndexes ? keys(this.selectedDatasetIndexes).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    LineChart2Component.prototype.toggleSelectedDatasetIndex = function (index) {
        var _this = this;
        if (!this.interactive) {
            return;
        }
        if (!this.selectedDatasetIndexes) {
            this.selectedDatasetIndexes = fromPairs(this.data.map(function (item, i) { return [i, true]; }).filter(function (_a) {
                var i = _a[0];
                return i !== index;
            }));
        }
        else if (this.selectedDatasetIndexes[index]) {
            delete this.selectedDatasetIndexes[index];
        }
        else {
            this.selectedDatasetIndexes[index] = true;
            if (this.selectedDatasetCount === this.data.length) {
                this.selectedDatasetIndexes = undefined;
            }
        }
        this.cd.markForCheck();
        this.data.forEach(function (item, i) {
            var lineNodes = _this.svg.selectAll(".chart-line_index-" + i).nodes();
            var lineAreaNodes = _this.svg.selectAll(".chart-line-area_index-" + i).nodes();
            if (!_this.selectedDatasetIndexes || _this.selectedDatasetIndexes[i]) {
                lineNodes.forEach(function (node) { return node.classList.remove('chart-line_hidden'); });
                lineAreaNodes.forEach(function (node) { return node.classList.remove('chart-line-area_hidden'); });
            }
            else {
                lineNodes.forEach(function (node) { return node.classList.add('chart-line_hidden'); });
                lineAreaNodes.forEach(function (node) { return node.classList.add('chart-line-area_hidden'); });
            }
        });
    };
    LineChart2Component.prototype.onLegendDatasetMouseEnter = function (index) {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetIndex$.next(index);
    };
    LineChart2Component.prototype.onLegendDatasetMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetIndex$.next(undefined);
    };
    LineChart2Component.prototype.rerender = function () {
        this.initBounds();
        this.renderYAxis();
        this.fitYAxis();
        this.renderXAxis();
        this.renderLine();
        this.renderGradients();
        this.renderInteractive();
    };
    LineChart2Component.prototype.onResize = function () {
        this.rerender();
    };
    LineChart2Component.prototype.colorDisplay = function (value) {
        return getColorHexStr(value);
    };
    return LineChart2Component;
}());
export { LineChart2Component };
