var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { applyModelElementStyles, getModelElementStyles } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValid, isSet } from '@shared';
import { FieldActionsArray } from '../display-fields-edit/field-actions.array';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
import { ModelElementStylesControl } from '../styles-model-element-edit/model-element-styles.control';
var CustomizeBarModelEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarModelEditForm, _super);
    function CustomizeBarModelEditForm(currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, elementConfigurationService, injector, dataSourceControl) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            title: new FieldInputControl({ name: 'value' }),
            data_source: dataSourceControl,
            columns_actions: new FieldActionsArray([]),
            visible_input: new FieldInputControl({ name: 'value' }),
            tooltip: new FormControl(''),
            element_styles: new ModelElementStylesControl(injector)
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.resourceControllerService = resourceControllerService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.elementConfigurationService = elementConfigurationService;
        _this.injector = injector;
        dataSourceControl.setRequired(true);
        return _this;
    }
    CustomizeBarModelEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            name: element.name ? element.name : 'Detail',
            title: element.titleInput ? element.titleInput.serialize() : {},
            columns_actions: element.columnActions,
            visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
            tooltip: element.tooltip
        };
        this.patchValue(value, { emitEvent: false });
        this.controls.data_source.deserialize(element.dataSource);
        var elementStyles = getModelElementStyles(element);
        this.controls.element_styles.deserialize(elementStyles);
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarModelEditForm.prototype.controlsValid$ = function (controls) {
        var _this = this;
        return combineLatest(controls.map(function (item) { return controlValid(_this.controls[item]); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarModelEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isModelConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarModelEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.name.value;
        instance.titleInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        instance.columnActions = this.controls.columns_actions.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        var elementStyles = this.controls.element_styles.serialize();
        applyModelElementStyles(instance, elementStyles);
        return instance;
    };
    return CustomizeBarModelEditForm;
}(FormGroup));
export { CustomizeBarModelEditForm };
